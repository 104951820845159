import { EBT } from './types';
import IconSingleBeacon from '../../Style/icons/IconSingleBeacon';
import IconLocation from '../../Style/icons/IconLocation';
import IconSingleCalendar from '../../Style/icons/IconSingleCalendar';
import IconSingleOxygen from '../../Style/icons/IconSingleOxygen';
import IconSingleTemperature from '../../Style/icons/IconSingleTemperature';
import IconSingleGlobe from '../../Style/icons/IconSingleGlobe';
import IconSingleCustom from '../../Style/icons/IconSingleCustom';
import IconLeaf from '../../Style/icons/IconLeaf';
import IconSingleOxygenAbove from '../../Style/icons/IconSingleOxygenAbove';
import IconSingleString from '../../Style/icons/IconSingleString';
import IconSingleNumber from '../../Style/icons/IconSingleNumber';
import IconSingleWebhookPost from '../../Style/icons/IconSingleWebhookPost';
import IconSingleNotif from '../../Style/icons/IconSingleNotif';
import IconSingleEmail from '../../Style/icons/IconSingleEmail';
import IconExtensometer from '../../Style/icons/IconExtensometer';
import IconInactivity from '../../Style/icons/IconInactivity';
import IconManDown from '../../Style/icons/IconManDown';
import IconEntry from '../../Style/icons/IconEntry';
import IconExit from '../../Style/icons/IconExit';
import Crossing from '../../Style/icons/Crossing';
import IconSingleTemperatureBelow from '../../Style/icons/IconSingleTemperatureBelow';
import IconSingleTemperatureAbove from '../../Style/icons/IconSingleTemperatureAbove';
import IconSingleOxygenBelow from '../../Style/icons/IconSingleOxygenBelow';
import IconSingleWebhookGet from '../../Style/icons/IconSingleWebhookGet';
import {
  Battery20,
  Battery50,
  Battery80,
  CheckBoxOutlineBlank,
  DashboardOutlined,
  HistoryToggleOff,
  PhoneForwarded,
  Sensors,
  SensorsOff,
} from '@mui/icons-material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import {
  GeofenceEntityType,
  EBTTriggerTypeId,
  EBTTriggerTypeOptionsId,
  EBTActionTypeId,
  EBTTriggerSubTypeId,
  EBTListenerTypeId,
  CrossingTypeValue,
  EBTTriggerTypeOptionsValue,
  EnteredTypeValue,
  EBTTriggerTypeValue,
} from '../../util/enums';
import { ListenersProps } from './Props';
import { WHITE } from '../../Style/GeoMobyBaseTheme';

export const getActionIcon = (type: EBTActionTypeId) => {
  switch (type) {
    case EBTActionTypeId.Notification:
      return <IconSingleNotif />;
    case EBTActionTypeId.Email:
      return <IconSingleEmail />;
    case EBTActionTypeId.Webhook:
      return <IconSingleGlobe />;
    default:
      break;
  }
};

export const getListeners = (props: ListenersProps) => {
  const listenerId = props.ebt.listenerId;
  const listenerType = props.ebt.listenerType;

  const typeTitle = getTypeTitle(
    listenerType,
    props.boundariesObj[listenerId]?.type,
    !!props.geofencesObj[listenerId],
    !!props.microfencesObj[listenerId],
  );
  const eventTitle = getEventTitle(
    props.ebt,
    props.boundariesObj[listenerId]?.higherIsEnter,
    props.geofencesObj[listenerId]?.type,
  );

  return (
    <Grid
      style={{
        paddingBottom: '15px',
      }}
    >
      <Tooltip title={typeTitle}>
        <Typography>Event Type: {typeTitle}</Typography>
      </Tooltip>
      <Tooltip title={props.actionType}>
        <Typography>Action Type: {props.ebt.notifierType}</Typography>
      </Tooltip>
      <Tooltip title={eventTitle}>
        <Typography>Event: {eventTitle}</Typography>
      </Tooltip>
      {listenerType === EBTListenerTypeId.Sensor && (
        <Tooltip title={props.boundariesObj[listenerId]?.name}>
          <Typography>Label: {props.boundariesObj[listenerId]?.name}</Typography>
        </Tooltip>
      )}
      {listenerType === EBTListenerTypeId.Sensor && (
        <Tooltip title={props.boundariesObj[listenerId]?.boundary}>
          <Typography>Value: {props.boundariesObj[listenerId]?.boundary}</Typography>
        </Tooltip>
      )}
      {listenerType === EBTListenerTypeId.Geofence && props.ebt.dwellSeconds && (
        <Tooltip title={props.ebt.dwellSeconds}>
          <Typography>Seconds: {props.ebt.dwellSeconds}</Typography>
        </Tooltip>
      )}
      {listenerType === EBTListenerTypeId.Geofence && (
        <Tooltip title={props.geofencesObj[listenerId]?.name}>
          <Typography>Geofence: {props.geofencesObj[listenerId]?.name}</Typography>
        </Tooltip>
      )}
      {listenerType === EBTListenerTypeId.Microfence && (
        <Tooltip title={props.microfencesObj[listenerId]?.name}>
          <Typography>Microfence: {props.microfencesObj[listenerId]?.name}</Typography>
        </Tooltip>
      )}
    </Grid>
  );
};

export const getEventTileIcon = (eventTypeAndValue: string, isMobile: boolean) => {
  if (eventTypeAndValue.includes('UNKNOWN')) return;
  const val = eventTypeAndValue.replaceAll(/\s/g, '');
  return (
    <Box
      sx={{
        '& .MuiSvgIcon-root': {
          fontSize: isMobile ? '25px' : '35px',
        },
      }}
    >
      {(() => {
        switch (val) {
          case 'PROXIMITY_ENTRY':
            return <Sensors />;
          case 'PROXIMITY_EXIT':
            return <SensorsOff />;
          case 'LOCATION_ENTRY':
            return <IconEntry />;
          case 'LOCATION_EXIT':
            return <IconExit />;
          case 'LOCATION_LEFT-TO-RIGHT':
            return <Crossing />;
          case 'LOCATION_RIGHT-TO-LEFT':
            return (
              <Box sx={{ '& svg': { rotate: '180deg' } }}>
                <Crossing />
              </Box>
            );
          case 'LOCATION_DWELL':
            return <HistoryToggleOff />;
          case 'TEMPERATURE_BELOW':
            return <IconSingleTemperatureBelow />;
          case 'TEMPERATURE_ABOVE':
            return <IconSingleTemperatureAbove />;
          case 'SPO2_BELOW':
            return <IconSingleOxygenBelow />;
          case 'SPO2_ABOVE':
            return <IconSingleOxygenAbove />;
          case 'BATTERY_BELOW':
            return <Battery20 />;
          case 'BATTERY_ABOVE':
            return <Battery80 />;
          default:
            break;
        }
      })()}
    </Box>
  );
};

export const getEventTitle = (
  ebt: EBT,
  higherIsEnter: boolean,
  geofenceType: GeofenceEntityType,
): string => {
  switch (ebt.listenerType) {
    case EBTListenerTypeId.Sensor:
      return (higherIsEnter && String(ebt.entered) === 'true') ||
        (!higherIsEnter && String(ebt.entered) === 'false')
        ? EnteredTypeValue.Above
        : EnteredTypeValue.Below;
    case EBTListenerTypeId.Geofence:
      if (ebt.dwellSeconds && String(ebt.entered) === 'true') {
        return EnteredTypeValue.Dwell;
      }
      return String(ebt.entered) === 'true'
        ? geofenceType === GeofenceEntityType.Polygon ||
          geofenceType === GeofenceEntityType.Multipolygon
          ? EBTTriggerTypeOptionsValue.Entry
          : CrossingTypeValue.LeftToRight
        : geofenceType === GeofenceEntityType.Polygon ||
          geofenceType === GeofenceEntityType.Multipolygon
        ? EBTTriggerTypeOptionsValue.Exit
        : CrossingTypeValue.RightToLeft;
    case EBTListenerTypeId.Microfence:
      return String(ebt.entered) === 'true'
        ? EBTTriggerTypeOptionsValue.Entry
        : EBTTriggerTypeOptionsValue.Exit;
    default:
      return 'Unknown Event';
  }
};

export const getTriggerTypeIcon = (type: EBTTriggerTypeId) => {
  switch (type) {
    case EBTTriggerTypeId.Battery:
      return <Battery50 />;
    case EBTTriggerTypeId.Custom:
      return <IconSingleCustom />;
    case EBTTriggerTypeId.EnvironmentalMonitoring:
      return <IconLeaf />;
    case EBTTriggerTypeId.Extensometer:
      return <IconExtensometer />;
    case EBTTriggerTypeId.Inactivity:
      return <IconInactivity />;
    case EBTTriggerTypeId.Location:
      return <IconLocation />;
    case EBTTriggerTypeId.ManDown:
      return <IconManDown />;
    case EBTTriggerTypeId.Proximity:
      return <IconSingleBeacon />;
    case EBTTriggerTypeId.Scheduler:
      return <IconSingleCalendar />;
    case EBTTriggerTypeId.Spo2:
      return <IconSingleOxygen />;
    case EBTTriggerTypeId.Temperature:
      return <IconSingleTemperature />;
    case EBTTriggerTypeId.Webhook:
      return <IconSingleGlobe />;
    case EBTTriggerTypeId.WelfareCheckResponse:
      return <PhoneForwarded />;
    default:
      break;
  }
};

export const getTriggerOptionIcon = (type: EBTTriggerTypeId, option: EBTTriggerTypeOptionsId) => {
  switch (type) {
    case EBTTriggerTypeId.Battery:
      return option === EBTTriggerTypeOptionsId.Below ? (
        <Battery20 style={{ color: WHITE }} />
      ) : (
        <Battery80 style={{ color: WHITE }} />
      );
    case EBTTriggerTypeId.Custom:
      return option === 'STRING' ? <IconSingleString /> : <IconSingleNumber />;
    case EBTTriggerTypeId.Location:
      return option === EBTTriggerTypeOptionsId.Entry ? (
        <IconEntry />
      ) : option === EBTTriggerTypeOptionsId.Exit ? (
        <IconExit />
      ) : option === EBTTriggerTypeOptionsId.Crossing ? (
        <Crossing />
      ) : (
        <HistoryToggleOff />
      );
    case EBTTriggerTypeId.Proximity:
      return option === EBTTriggerTypeOptionsId.Entry ? (
        <Sensors style={{ color: WHITE }} />
      ) : (
        <SensorsOff style={{ color: WHITE }} />
      );
    case EBTTriggerTypeId.Scheduler:
      return option === EBTTriggerTypeOptionsId.Entry ? (
        <Sensors style={{ color: WHITE }} />
      ) : (
        <SensorsOff style={{ color: WHITE }} />
      );
    case EBTTriggerTypeId.Spo2:
      return option === EBTTriggerTypeOptionsId.Below ? (
        <IconSingleOxygenBelow />
      ) : (
        <IconSingleOxygenAbove />
      );
    case EBTTriggerTypeId.Temperature:
      return option === EBTTriggerTypeOptionsId.Below ? (
        <IconSingleTemperatureBelow />
      ) : (
        <IconSingleTemperatureAbove />
      );
    case EBTTriggerTypeId.Webhook:
      return option === EBTTriggerTypeOptionsId.Get ? (
        <IconSingleWebhookGet />
      ) : (
        <IconSingleWebhookPost />
      );
    default:
      break;
  }
};

export const getTriggerSubTypIcon = (subType: EBTTriggerSubTypeId) => {
  switch (subType) {
    case EBTTriggerSubTypeId.ClearedZone:
      return <DashboardOutlined />;
    case EBTTriggerSubTypeId.Geofence:
      return <CheckBoxOutlineBlank />;
    default:
      break;
  }
};

export const getTypeTitle = (
  listenerType: EBTListenerTypeId,
  boundaryType: number,
  isGeofence: boolean,
  isMicrofence: boolean,
): string => {
  switch (listenerType) {
    case EBTListenerTypeId.Sensor:
      return boundaryType === 0
        ? EBTTriggerTypeValue.Battery
        : boundaryType === 1
        ? EBTTriggerTypeValue.Temperature
        : boundaryType === 2
        ? EBTTriggerTypeValue.Spo2
        : 'Unknown';
    case EBTListenerTypeId.Geofence:
      return isGeofence ? EBTTriggerTypeValue.Location : 'Unknown';
    case EBTListenerTypeId.Microfence:
      return isMicrofence ? EBTTriggerTypeValue.Proximity : 'Unknown';
    default:
      return 'Unknown Event';
  }
};

export const validateEmailAddress = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
