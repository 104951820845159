import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { AssetEntity, SearchTypeIDs } from '../../../../../util/enums';
import { Asset, PortableAssetTool, SelectedAsset } from '../../../types';
import { AssetsListProps, SearchListProps } from '../../Props';
import {
  displayAssetIsActive,
  displayAssetLabel,
  displayAssetPrefixType,
  findKnownTool,
  getMicroFenceIcon,
  selectedElement,
} from '../../../Helpers';
import { MICROFENCE } from '../../../BeaconUtils';
import { ACTIVE_MICROFENCE } from '../../../../../util/constants';
import { TRACKED_DEVICE } from '../../../../../util/constants';
import { KNOWN_TOOLS } from '../../../../../store/tools';
import { transformExtent } from 'ol/proj';
import { StrongFeatureHolder } from '../../../../../hooks/geomoby/useLiveMapLoader';
import { MicrofenceData } from '../../../Messages';
import { Point } from 'ol/geom';
import { SECONDARY, truncationStyle, WHITE } from '../../../../../Style/GeoMobyBaseTheme';

export const useActiveList = (props: AssetsListProps & SearchListProps) => {
  const knownTools = useAtomValue(KNOWN_TOOLS);

  const findAvailableMicrofence = (
    asset: SelectedAsset,
  ): StrongFeatureHolder<Point, MicrofenceData> | undefined => {
    return props.microfences.find(microfence => {
      const assetId = microfence.feature.get('assetId');
      if (!assetId) return undefined;
      return (
        (assetId.gatewayId !== undefined && assetId.gatewayId === asset.id?.gatewayId) ||
        (assetId.uuid !== undefined &&
          assetId.major !== undefined &&
          assetId.minor !== undefined &&
          assetId.uuid === asset.id?.uuid &&
          assetId.major === asset.id?.major &&
          assetId.minor === asset.id?.minor) ||
        (assetId.deviceId !== undefined && assetId.deviceId === asset.id?.deviceId) ||
        (assetId.smartplugId !== undefined && assetId.smartplugId === asset.id?.smartplugId)
      );
    });
  };

  return (
    <>
      {!selectedElement(props) &&
        !props.isLoading &&
        props.searchType?.id === SearchTypeIDs.ActiveAssets &&
        [
          ...props.activeAssets
            .sort((a, b) => (a.label ?? 'NO LABEL').localeCompare(b.label ?? 'NO LABEL'))
            .filter(asset => {
              if (asset.id?.localised) return false;

              if (asset.prefix === MICROFENCE) {
                return !!props.availableMicrofences
                  .find(m => JSON.stringify(m.data.assetId) === JSON.stringify(asset.id))
                  ?.feature.get(ACTIVE_MICROFENCE);
              }
              return true;
            }),
        ].map((asset, index) => {
          return (
            <Accordion id={String(asset.id)} key={index} expanded={false} style={{ width: '98%' }}>
              <AccordionSummary
                onClick={() => {
                  if (asset.prefix === TRACKED_DEVICE) {
                    if (asset.id?.deviceId) {
                      props.setSelectedAsset(asset);
                      props.setSelectedDevice({
                        id: asset.id?.deviceId as string,
                        label:
                          asset.label ??
                          props.availableDevices.find(d => d.id === asset.id?.deviceId)?.label ??
                          '',
                        type: AssetEntity.Device,
                      });
                    } else if (asset.id?.gpsTrackerId) {
                      props.setSelectedAsset(asset);
                      props.setSelectedGPSTracker({
                        id: asset.id?.gpsTrackerId as string,
                        label:
                          asset.label ??
                          props.availableGpsTrackers.find(d => d.id === asset.id?.gpsTrackerId)
                            ?.label ??
                          '',
                        type: AssetEntity.GPSTracker,
                      });
                    } else if (asset.id?.beaconId) {
                      props.setSelectedAsset(asset);
                      const foundTool = findKnownTool(asset.id, knownTools);
                      if (foundTool) {
                        props.setSelectedTool(foundTool as PortableAssetTool);
                      } else {
                        props.setSelectedBeacon({
                          id: asset.id.beaconId as string,
                          label:
                            asset.label ??
                            props.availableBeacons.find(b => b.id === asset.id?.beaconId)?.label ??
                            '',
                          type: AssetEntity.Beacon,
                        });
                      }
                    }
                  } else if (asset.prefix === MICROFENCE) {
                    props.setSelectedAsset(asset);
                    const activeMicrofence = findAvailableMicrofence(asset);
                    if (activeMicrofence) {
                      activeMicrofence.feature.set('selected', true);
                      const coords = activeMicrofence.feature.get('point')?.coordinates;
                      const extent = transformExtent(
                        [...coords, ...coords],
                        'EPSG:4326',
                        'EPSG:3857',
                      );
                      props.setSelectedMicrofence(activeMicrofence.feature.getProperties());
                      if (coords) {
                        props.setExtent(extent);
                      }
                    }
                  }
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                {asset.prefix === MICROFENCE &&
                  getMicroFenceIcon(findAvailableMicrofence(asset)?.feature?.get('microfenceType'))}
                <Tooltip
                  title={
                    asset.label ||
                    asset.id?.beaconId ||
                    asset.id?.deviceId ||
                    asset.id?.gpsTrackerId
                  }
                >
                  <Typography
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {asset.prefix !== MICROFENCE && displayAssetPrefixType(asset, knownTools)}
                    <span
                      style={{
                        color: asset.label ? WHITE : SECONDARY,
                        ...truncationStyle,
                      }}
                    >
                      {asset.label}
                    </span>
                    {!asset.label && (
                      <span style={truncationStyle}>
                        <span
                          style={{
                            color: SECONDARY,
                          }}
                        >
                          NO LABEL
                        </span>
                        <span>{` (${
                          asset.id?.beaconId || asset.id?.deviceId || asset.id?.gpsTrackerId
                        })`}</span>
                      </span>
                    )}
                  </Typography>
                </Tooltip>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
