import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { TriggerTypes } from '../values';
import { EBTTriggerTypeValue } from '../../../util/enums';
import { EBTFormProps } from '../Props';
import { getTriggerTypeIcon } from '../Helpers';

const TriggerType = (props: EBTFormProps) => {
  return (
    <>
      {!props.hideForm && (
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: 'calc(100% - 10vw)',
            alignSelf: 'center',
          }}
        >
          <InputLabel id="trigger-type">Event Trigger Type</InputLabel>
          <Select
            fullWidth
            labelId="trigger-type"
            id="trigger-type-dropdown"
            value={props.selectedTriggerType?.value ?? ''}
            label="Event Trigger Type"
            onChange={e => {
              const triggerType = TriggerTypes.find(
                trigger => trigger.value === (e.target.value as EBTTriggerTypeValue),
              );
              props.setSelectedTriggerType(triggerType);
              props.geofencePageRef.current = 1;
              props.setSelectedTriggerOption(undefined);
              props.setSelectedTriggerSubType(undefined);
              props.setSelectedCrossingDirection(undefined);
              props.setTriggerValue(null);
              props.setSelectedEBT(undefined);
              props.setSelectedTableData(undefined);
              if (!props.eventIds) {
                props.setSelectedTriggerAction(undefined);
              }
              if (!props.eventIds?.emailId) {
                props.setEmailData(null);
              }
              if (!props.eventIds?.webhookId) {
                props.setWebhookData(null);
              }
              props.setEventName(null);
              props.setIsEditing(false);
            }}
          >
            {TriggerTypes.map(type => (
              <MenuItem key={type.id} value={type.value} disabled={type.disabled}>
                <Tooltip title={type.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {getTriggerTypeIcon(type.id)}
                    <span
                      style={{
                        padding: '10px',
                      }}
                    ></span>{' '}
                    {type.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default TriggerType;
