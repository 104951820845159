export enum AssetEntity {
  Beacon = 'beacon',
  Device = 'device',
  GPSTracker = 'gpstracker',
  Tool = 'tool',
}

export enum BufferShapeType {
  Circle = 'Circle',
  Scaled = 'Scaled',
}

export enum CrossingTypeId {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum CrossingTypeValue {
  LeftToRight = 'Left-to-right',
  RightToLeft = 'Right-to-left',
}

export enum DrawType {
  Circle = 'CIRCLE',
  Polygon = 'POLYGON',
  Tripwire = 'TRIPWIRE',
  Multipolygon = 'MULTIPOLYGON',
  MicrofenceBeacon = 'MICROFENCE_BEACON',
  MicrofenceDevice = 'MICROFENCE_DEVICE',
  MicrofenceGateway = 'MICROFENCE_GATEWAY',
  Measure = 'MEASURE',
}

export enum EBTActionTypeId {
  Email = 'EMAIL',
  Notification = 'NOTIFICATION',
  Webhook = 'WEBHOOK',
}

export enum EBTActionTypeValue {
  Email = 'Email',
  Notification = 'Notification',
  Webhook = 'Webhook',
}

export enum EBTOrderByTypeId {
  Entered = 'entered',
  GeofenceType = 'geofenceType',
  ListenerType = 'listenerType',
  Method = 'method',
  NotifierType = 'notifierType',
  Recipients = 'recipients',
  Subject = 'subject',
  Text = 'text',
  Url = 'url',
}

export enum EnteredTypeId {
  Above = 'ABOVE',
  Below = 'BELOW',
  EnterGeofence = 'ENTER_GEOFENCE',
  ExitGeofence = 'EXIT_GEOFENCE',
  DwellGeofence = 'DWELL_GEOFENCE',
  EnteredMicrofence = 'ENTER_MICROFENCE',
  ExitMicrofence = 'EXIT_MICROFENCE',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum EnteredTypeValue {
  Above = 'Above',
  Below = 'Below',
  Enter = 'Enter',
  Exit = 'Exit',
  Dwell = 'Dwell',
  LeftToRight = 'Left-to-right',
  RightToLeft = 'Right-to-left',
}

export enum EBTOrderByTypeValue {
  EventTriggerOption = 'Event Trigger Option',
  EventTriggerType = 'Event Trigger Type',
  GeofenceType = 'Geofence Type',
  Method = 'Method',
  NotificationType = 'Notification Type',
  Recipients = 'Recipients',
  Subject = 'Subject',
  Text = 'Text',
  Url = 'Url',
}

export enum EBTTriggerTypeId {
  Battery = 'BATTERY',
  Custom = 'CUSTOM',
  EnvironmentalMonitoring = 'ENVIRONMENTALMONITORING',
  Extensometer = 'EXTENSOMETER',
  Inactivity = 'INACTIVITY',
  Location = 'LOCATION',
  ManDown = 'MANDOWN',
  Proximity = 'PROXIMITY',
  Scheduler = 'SCHEDULER',
  Spo2 = 'SPO2',
  Temperature = 'TEMPERATURE',
  Webhook = 'WEBHOOK',
  WelfareCheckResponse = 'WELFARECHECKRESPONSE',
}

export enum EBTTriggerTypeValue {
  Battery = 'Battery',
  Custom = 'Custom',
  EnvironmentalMonitoring = 'Environmental Monitoring',
  Extensometer = 'Extensometer',
  Inactivity = 'Inactivity',
  Location = 'Location',
  ManDown = 'Man Down',
  Proximity = 'Proximity',
  Scheduler = 'Scheduler',
  Spo2 = 'Spo2',
  Temperature = 'Temperature',
  Webhook = 'Webhook',
  WelfareCheckResponse = 'Welfare Check Response',
}

export enum EBTTriggerTypeOptionsId {
  Entry = 'ENTRY',
  Exit = 'EXIT',
  Crossing = 'CROSSING',
  Dwell = 'DWELL',
  Below = 'BELOW',
  Above = 'ABOVE',
  NewRule = 'NEW_RULE',
  Get = 'GET',
  Post = 'POST',
  String = 'STRING',
  Number = 'NUMBER',
}

export enum EBTTriggerTypeOptionsValue {
  Entry = 'Entry',
  Exit = 'Exit',
  Crossing = 'Crossing',
  Dwell = 'Dwell',
  Below = 'Below',
  Above = 'Above',
  NewRule = 'New rule',
  Get = 'GET',
  Post = 'POST',
  String = 'String',
  Number = 'Number',
}

export enum EBTTriggerSubTypeId {
  ClearedZone = 'CLEARED_ZONE',
  Geofence = 'GEOFENCE',
}

export enum EBTTriggerSubTypeValue {
  ClearedZone = 'Cleared Zone',
  Geofence = 'Geofence',
}

export enum EBTListenerTypeId {
  Geofence = 'geofence',
  Microfence = 'microfence',
  Sensor = 'sensor',
}

export enum EBTListenerTypeValue {
  Location = 'Location',
  Proximity = 'Proximity',
  Sensor = 'Sensor (eg. Battery, Temperature, Spo2, etc)',
}

export enum EditType {
  ShapeChange = 'SHAPE_CHANGE',
  ScaleRot = 'SCALE_ROT',
}

export enum EntityType {
  Asset = 'ASSET',
  Beacon = 'BEACON',
  Device = 'DEVICE',
  Gateway = 'GATEWAY',
  Geofence = 'GEOFENCE',
  Layer = 'LAYER',
  Line = 'LINE',
  Microfence = 'MICROFENCE',
  Multipolygon = 'MULTIPOLYGON',
  Polygon = 'POLYGON',
  Tool = 'TOOL',
  Tracker = 'TRACKER',
}

export enum FenceGeometryType {
  Line = 'line',
  Microfence = 'microfence',
  Multipolygon = 'multipolygon',
  Polygon = 'polygon',
}

export enum FenceZone {
  none = 'none',
  breach = 'breach',
  buffer = 'buffer',
  cleared = 'cleared',
  loading = 'loading',
  dumping = 'dumping',
  maintenance = 'maintenance',
}

export enum GeofenceEntityType {
  Line = 'line',
  Multipolygon = 'multipolygon',
  Polygon = 'polygon',
}

export enum GeomobyPropertiesValues {
  alertStyle = 'alertStyle',
  alertText = 'alertText',
  alertTimeout = 'alertTimeout',
  dwellSeconds = 'dwellSeconds',
  showAlert = 'showAlert',
}

export enum Method {
  GET = 'GET',
  POST = 'POST',
}

export enum MicrofenceEntity {
  Beacon = 'beacon',
  Device = 'device',
  Gateway = 'gateway',
  Smartplug = 'smartplug',
}

export enum MeasurementType {
  M = 'M',
  M2 = 'M2',
  FT = 'FT',
  FT2 = 'FT2',
  KM = 'KM',
  KM2 = 'KM2',
  MI = 'MI',
  MI2 = 'MI2',
}

export enum MicrofenceZone {
  none = 'none',
  loading = 'loading',
  dumping = 'dumping',
  maintenance = 'maintenance',
}

export enum MapType {
  OUTDOOR_LIVE_MAP = 'OUTDOOR_LIVE_MAP',
  OUTDOOR_EDIT_MAP = 'OUTDOOR_EDIT_MAP',
  OUTDOOR_REPLAY_MAP = 'OUTDOOR_REPLAY_MAP',
  OUTDOOR_HEAT_MAP = 'OUTDOOR_HEAT_MAP',
}

export enum OrderTypeId {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum OrderTypeValue {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export enum RequestType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Edit = 'EDIT',
}

export enum SearchTypeIDs {
  ActiveAssets = 'ACTIVE_ASSETS',
  Beacons = 'BEACONS',
  Devices = 'DEVICES',
  GPSTrackers = 'GPSTRACKERS',
  Geofences = 'GEOFENCES',
  Microfences = 'MICROFENCES',
  Locations = 'LOCATIONS',
  Tools = 'TOOLS',
}

export enum SearchTypeValue {
  ActiveAssets = 'Active Assets',
  Beacons = 'Beacons',
  Devices = 'Devices',
  GPSTrackers = 'GPS Trackers',
  Geofences = 'Geofences',
  Microfences = 'Microfences',
  Locations = 'Locations',
  Tools = 'Tools',
}
