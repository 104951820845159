import { Clear, Search } from '@mui/icons-material';
import { Fab, Grid, Input, OutlinedInput, Toolbar } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { PRIMARY } from '../../Style/GeoMobyBaseTheme';

export type SearchType = 'Geofences' | 'Cleared Zones' | 'Microfences';

export const Highlighted = (text: string, highlight: string) => {
  if (!highlight) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight.replace(/([\\{}()|.?*+\-^$[\]])/g, '\\$1')})`, 'gi');
  const parts = text.split(regex);
  return (
    <span>
      {parts.map((part, i) =>
        regex.test(part) ? (
          <mark style={{ background: PRIMARY }} key={i}>
            {part}
          </mark>
        ) : (
          <span key={i}>{part}</span>
        ),
      )}
    </span>
  );
};

export const GeofenceSearchToolbar = ({
  layerId,
  searchType,
  searchString,
  setSearchString,
  onSearch,
  onFocus,
  background,
}: {
  layerId: string | undefined;
  searchType: SearchType;
  searchString: SetStateAction<string | undefined>;
  setSearchString: Dispatch<SetStateAction<string | undefined>>;
  onSearch: () => void;
  onFocus: () => void;
  background?: string;
}) => {
  const searchStringRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    clearSearchString();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerId]);

  useEffect(() => {
    onSearch();
  }, [searchString, onSearch]);

  const clearSearchString = (): void => {
    if (searchStringRef.current) {
      searchStringRef.current.value = '';
      setSearchString('');
    }
  };

  return (
    <>
      {layerId && (
        <Grid
          style={{
            background: background ?? undefined,
            margin: '0px 0px -8px -16px',
            width: 'calc(100% + 1.37rem)',
            borderRadius: '3px',
          }}
        >
          {
            <Toolbar>
              <Grid
                container
                direction="column"
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'calc(100% - 4.5rem) 75px',
                }}
              >
                <Grid
                  item
                  xs={8}
                  style={{
                    marginLeft: '-10px',
                  }}
                >
                  <OutlinedInput
                    inputRef={searchStringRef}
                    placeholder={`Search ${searchType}`}
                    style={{ width: '98%', fontSize: '13px' }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        setSearchString(searchStringRef.current?.value ?? '');
                      }
                    }}
                    onFocus={onFocus}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '45px 30px',
                    marginTop: '7px',
                  }}
                >
                  <Fab
                    color="default"
                    size="small"
                    onClick={() => {
                      setSearchString(searchStringRef.current?.value ?? '');
                    }}
                  >
                    <Search />
                  </Fab>
                  <Fab color="default" size="small" onClick={clearSearchString}>
                    <Clear />
                  </Fab>
                </Grid>
              </Grid>
            </Toolbar>
          }
        </Grid>
      )}
    </>
  );
};
