import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import {
  EBTTriggerSubTypeId,
  EBTTriggerTypeId,
  EBTTriggerTypeOptionsId,
  EBTTriggerTypeOptionsValue,
} from '../../../util/enums';
import { getTriggerOptionIcon } from '../Helpers';
import { EBTFormProps } from '../Props';

const TriggerOption = (props: EBTFormProps) => {
  return (
    <>
      {!props.hideForm && props.selectedTriggerType && (
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            width: 'calc(100% - 10vw)',
            alignSelf: 'center',
          }}
        >
          <InputLabel id="trigger-option">Event Trigger Option</InputLabel>
          <Select
            fullWidth
            labelId="trigger-option"
            id="trigger-option-dropdown"
            value={props.selectedTriggerOption?.value ?? ''}
            label="Event Trigger Option"
            onChange={e => {
              const triggerOption = props.selectedTriggerType?.options.find(
                option => option.value === (e.target.value as EBTTriggerTypeOptionsValue),
              );
              props.setSelectedTriggerOption(triggerOption);
              if (props.selectedTriggerType?.id === EBTTriggerTypeId.Location) {
                props.setAvailableFeatures([]);
                props.setSelectedLayer(null);
                props.setTriggerValue(null);
                props.setSelectedEBT(undefined);
                props.setSelectedTableData(undefined);
              }
              props.setIsEditing(false);
            }}
          >
            {[
              ...(props.selectedTriggerType.id === EBTTriggerTypeId.Location &&
              props.selectedTriggerSubType?.id === EBTTriggerSubTypeId.ClearedZone
                ? props.selectedTriggerType.options.filter(
                    t => t.id !== EBTTriggerTypeOptionsId.Crossing,
                  )
                : props.selectedTriggerType.options),
            ].map(option => (
              <MenuItem key={option.id} value={option.value} disabled={option.disabled}>
                <Tooltip title={option.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {props.selectedTriggerType
                      ? getTriggerOptionIcon(props.selectedTriggerType.id, option.id)
                      : undefined}
                    <span
                      style={{
                        padding: '10px',
                      }}
                    ></span>{' '}
                    {option.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default TriggerOption;
