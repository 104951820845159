import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { Dispatch, SetStateAction, FC } from 'react';
import { GeofenceFilter, IBeacon, SearchType } from '../../../types';
import { Geometry, Point } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import { Extent } from 'ol/extent';
import { transformExtent } from 'ol/proj';
import { SearchTypeIDs } from '../../../../../util/enums';
import { MicrofenceListProps, SearchListProps } from '../../Props';
import { getMicroFenceIcon } from '../../../Helpers';

export const microfenceList = (props: MicrofenceListProps & SearchListProps) => {
  return (
    <>
      {!props.isLoading &&
        props.searchType?.id === SearchTypeIDs.Microfences &&
        props.availableMicrofences.map(microfence => {
          return (
            <Accordion
              id={microfence.id}
              key={microfence.id}
              expanded={false}
              style={{ width: '98%' }}
            >
              <AccordionSummary
                onClick={async () => {
                  const coords = microfence.point?.coordinates ?? microfence.geometry?.getExtent();
                  const extent = transformExtent([...coords, ...coords], 'EPSG:4326', 'EPSG:3857');
                  microfence.selected = true;
                  props.setSelectedMicrofence(microfence);
                  if (extent) {
                    props.setExtent(extent as Extent);
                  }
                  props.setDisplayGeomobyProperties(
                    Object.entries(microfence.geomobyProperties).map(([property, value], index) => {
                      return {
                        index: index as number,
                        property: property as string,
                        value: value as string,
                      };
                    }),
                  );
                  props.setFenceNameInput(microfence.name);
                  const assetId = microfence.assetId;
                  if (assetId.uuid && assetId.major && assetId.minor) {
                    props.setMicrofenceIBeaconIdentifier({
                      uuid: String(assetId.uuid),
                      major: Number(assetId.major),
                      minor: Number(assetId.minor),
                    });
                  } else if (assetId.deviceId || assetId.gatewayId || assetId.smartplugId) {
                    props.setMicrofenceIdInput(
                      assetId.deviceId ?? assetId.gatewayId ?? assetId.smartplugId,
                    );
                  }
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                {getMicroFenceIcon(microfence.microfenceType)}
                <Tooltip title={microfence.name}>
                  <Typography
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {microfence.name}
                  </Typography>
                </Tooltip>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
