import {
  DeviceLocation,
  GeofenceEvent,
  SensedAssetsReport,
  SensedTemp,
  TempRangeEvent,
  WelfareCheckResponse,
  LocalBeacons,
  SensedTriggeredEvent,
  NotificationEvent,
} from '../../Components/Map/Messages';
import { LiveMapState } from './useGeomobyLiveStream';

export enum AssetUpdateType {
  Location = 'ASSET_LOCATION_UPDATE',
  GeofenceEvent = 'ASSET_FENCE_EVENT_UPDATE',
  SensedAssets = 'ASSET_SENSED_ASSETS_UPDATE',
  SensedTriggered = 'ASSET_SENSED_TRIGGERED_UPDATE',
  LocalBeacons = 'ASSET_LOCAL_BEACON_UPDATE',
  SensedTemp = 'ASSET_SENSED_TEMP_UPDATE',
  TempRangeEvent = 'ASSET_TEMP_RANGE_EVENT_UPDATE',
  FullStateUpdate = 'ASSET_FULL_STATE_UPDATE',
  WelfareCheckResponseUpdate = 'ASSET_WELFARE_CHECK_RESPONSE_UPDATE',
  NotificationUpdate = 'NOTIFICATION_UPDATED',
}

export interface LocationUpdate {
  type: AssetUpdateType.Location;
  payload: DeviceLocation;
}
export interface FenceEventUpdate {
  type: AssetUpdateType.GeofenceEvent;
  payload: GeofenceEvent;
}
export interface SensedBeaconUpdate {
  type: AssetUpdateType.SensedAssets;
  payload: SensedAssetsReport;
}
export interface SensedTriggeredUpdate {
  type: AssetUpdateType.SensedTriggered;
  payload: SensedTriggeredEvent;
}
export interface LocalBeaconUpdate {
  type: AssetUpdateType.LocalBeacons;
  payload: LocalBeacons;
}
export interface SensedTempUpdate {
  type: AssetUpdateType.SensedTemp;
  payload: SensedTemp;
}
export interface TempRangeEventUpdate {
  type: AssetUpdateType.TempRangeEvent;
  payload: TempRangeEvent;
}
export interface FullStateUpdate {
  type: AssetUpdateType.FullStateUpdate;
  payload: LiveMapState;
}
export interface WelfareCheckResponseUpdate {
  type: AssetUpdateType.WelfareCheckResponseUpdate;
  payload: WelfareCheckResponse;
}
export interface NotificationUpdate {
  type: AssetUpdateType.NotificationUpdate;
  payload: NotificationEvent;
}

export function updateSensedTemp(st: SensedTemp): SensedTempUpdate {
  return {
    type: AssetUpdateType.SensedTemp,
    payload: st,
  };
}
export function updateTempRangeEvent(tre: TempRangeEvent): TempRangeEventUpdate {
  return {
    type: AssetUpdateType.TempRangeEvent,
    payload: tre,
  };
}

export function updateLocation(dl: DeviceLocation): LocationUpdate {
  return {
    type: AssetUpdateType.Location,
    payload: dl,
  };
}
export function updateFenceEvent(fe: GeofenceEvent): FenceEventUpdate {
  return {
    type: AssetUpdateType.GeofenceEvent,
    payload: fe,
  };
}
export function updateSensedAssets(sb: SensedAssetsReport): SensedBeaconUpdate {
  return {
    type: AssetUpdateType.SensedAssets,
    payload: sb,
  };
}
export function updateSensedTriggered(st: SensedTriggeredEvent): SensedTriggeredUpdate {
  return {
    type: AssetUpdateType.SensedTriggered,
    payload: st,
  };
}
export function updateFullState(lms: LiveMapState): FullStateUpdate {
  return {
    type: AssetUpdateType.FullStateUpdate,
    payload: lms,
  };
}

export function updateWelfareCheckResponse(wfcr: WelfareCheckResponse): WelfareCheckResponseUpdate {
  return {
    type: AssetUpdateType.WelfareCheckResponseUpdate,
    payload: wfcr,
  };
}

export function updateNotification(ne: NotificationEvent): NotificationUpdate {
  return {
    type: AssetUpdateType.NotificationUpdate,
    payload: ne,
  };
}

export type LiveMapUpdate =
  | LocationUpdate
  | FenceEventUpdate
  | SensedBeaconUpdate
  | SensedTriggeredUpdate
  | LocalBeaconUpdate
  | TempRangeEventUpdate
  | SensedTempUpdate
  | FullStateUpdate
  | WelfareCheckResponseUpdate
  | NotificationUpdate;
