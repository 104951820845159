import { GridRowData } from '@material-ui/data-grid';
import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { MutableRefObject } from 'react';
import { ALL_GROUPS, ALL_LAYERS } from '../../../../../util/constants';
import { SearchTypeIDs } from '../../../../../util/enums';
import { selectedElement } from '../../../Helpers';
import { GeofenceFilter } from '../../../types';
import { SearchListProps } from '../../Props';

// This will be developed soon, as the one Edior has been https://geomoby.atlassian.net/browse/LTP-1186
export const useLayers = (
  props: SearchListProps & {
    isLoading: boolean;
    availableGeofences: GridRowData[];
    pageRef: MutableRefObject<number>;
    paginateGeofences: (filter: GeofenceFilter, refresh?: boolean) => Promise<void>;
  },
) => {
  return (
    <>
      {!selectedElement(props) && props.searchType?.id === SearchTypeIDs.Geofences && (
        <FormControl
          fullWidth
          style={{
            width: '98%',
          }}
        >
          <InputLabel id="search-group-option">Select a group</InputLabel>
          <Select
            fullWidth
            labelId="search-group-option"
            id="search-group-option"
            disabled={props.isLoading}
            value={
              (props.layerFilter?.layer === undefined && props.availableGeofences.length > 0
                ? ALL_GROUPS
                : props.layerFilter?.layer?.name) ?? ''
            }
            label="Select a group"
            onChange={e => {
              const layer = [{ name: ALL_GROUPS, id: ALL_LAYERS }, ...props.layers].find(
                l => l.name === e.target.value,
              );
              props.setLayerFilter({
                ...props.layerFilter,
                layer,
              });
              props.pageRef.current = 1;
              props.paginateGeofences({
                ...props.geofenceFilter,
                layerId: layer?.id,
              } as GeofenceFilter);
            }}
          >
            {[{ name: ALL_GROUPS, id: ALL_LAYERS }, ...props.layers].map(layer => (
              <MenuItem key={layer.id} value={layer.name}>
                <Tooltip title={layer.name}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 30px)',
                    }}
                  >
                    {layer.name}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
