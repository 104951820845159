import { Accordion, AccordionSummary, Tooltip, Typography } from '@mui/material';
import { FenceZone, SearchTypeIDs } from '../../../../../util/enums';
import { getFenceIcon, selectedElement } from '../../../Helpers';
import { GeofenceListProps, SearchListProps } from '../../Props';

export const useGeofenceList = (props: SearchListProps & GeofenceListProps) => {
  return (
    <>
      {!selectedElement(props) &&
        !props.isLoading &&
        props.searchType?.id === SearchTypeIDs.Geofences &&
        props.availableGeofences.map(fence => {
          return (
            <Accordion id={fence.id} key={fence.id} expanded={false} style={{ width: '98%' }}>
              <AccordionSummary
                onClick={() => {
                  fence.selected = true;
                  props.setSelectedFromMap(false);
                  props.setSelectedGeofence(fence);
                  props.getGeofence(fence);
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                {getFenceIcon(fence.zone)}
                <Tooltip
                  title={`${fence.name}${
                    !fence.zone || fence.zone === FenceZone.none
                      ? ''
                      : ' (' + fence.zone.toUpperCase() + ')'
                  }`}
                >
                  <Typography
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {fence.name}
                  </Typography>
                </Tooltip>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
