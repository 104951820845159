import { Accordion, AccordionSummary, Grid } from '@mui/material';
import { SearchTypeIDs } from '../../../../../util/enums';
import { displayAssetIsActive, displayAssetLabel, selectedElement } from '../../../Helpers';
import { Asset } from '../../../types';
import { SearchListProps } from '../../Props';

export const useDeviceList = (
  props: SearchListProps & { availableDevices: Asset[]; isLoading: boolean },
) => {
  return (
    <>
      {!selectedElement(props) &&
        !props.isLoading &&
        props.searchType?.id === SearchTypeIDs.Devices &&
        props.availableDevices.map((device, index) => {
          return (
            <Accordion id={String(device.id)} key={index} expanded={false} style={{ width: '98%' }}>
              <AccordionSummary
                onClick={() => {
                  props.setSelectedDevice(device);
                  const activeDevice = props.activeAssets.find(
                    active =>
                      active.id?.deviceId !== undefined && active.id?.deviceId === device.id,
                  );
                  if (activeDevice) {
                    props.setSelectedAsset(activeDevice);
                  }
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                <Grid container direction="row">
                  <Grid item>{displayAssetLabel(device.label, device.id)}</Grid>
                  <Grid
                    item
                    style={{
                      width: 'max-content',
                      marginLeft: 'auto',
                    }}
                  >
                    {props.activeAssets.find(
                      active =>
                        active.id?.deviceId !== undefined && active.id?.deviceId === device.id,
                    ) && displayAssetIsActive()}
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
