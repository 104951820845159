import { GridRowData } from '@material-ui/data-grid';
import {
  Clear,
  Delete,
  DirectionsRun,
  Edit,
  FilterList,
  MoreVert,
  Sensors,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Header } from '../../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../../store/notifications';
import { NOTIFIER_URL, TRIGGERS_URL } from '../../../store/url';
import { CID, PID } from '../../../store/user';
import Crossing from '../../../Style/icons/Crossing';
import IconEntry from '../../../Style/icons/IconEntry';
import IconExit from '../../../Style/icons/IconExit';
import IconSingleOxygenAbove from '../../../Style/icons/IconSingleOxygenAbove';
import IconSingleOxygenBelow from '../../../Style/icons/IconSingleOxygenBelow';
import IconSingleTemperatureAbove from '../../../Style/icons/IconSingleTemperatureAbove';
import IconSingleTemperatureBelow from '../../../Style/icons/IconSingleTemperatureBelow';
import {
  EBTActionTypeId,
  EBTListenerTypeId,
  EnteredTypeValue,
  GeofenceEntityType,
} from '../../../util/enums';
import { EBTFilterDialog } from '../EBTFilterDialog';
import { EBTListProps } from '../Props';
import { ActionType, EBT, EBTFilter, EventIds, Geofence } from '../types';
import { useAllBoundaries } from '../useAllBoundaries';
import { NameId } from '../../Map/types';
import EmailList from '../List/EmailList';
import WebhookList from '../List/WebhookList';

const EBTList = (props: EBTListProps) => {
  const notifierUrl = useAtomValue(NOTIFIER_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const { execute: refreshBoundaries, data: boundaries } = useAllBoundaries();

  const [ebtCount, setEbtCount] = useState<number>(0);
  const [dwellTimeMinimum, setDwellTimeMinimum] = useState<number | undefined>();
  const [ebtFilter, setEbtFilter] = useState<EBTFilter | undefined>();
  const [enteredState, setEnteredState] = useState<EnteredTypeValue>();
  const [deleting, setDeleting] = useState<{ id: string; type: EBTActionTypeId } | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [geofences, setGeofences] = useState<Geofence[]>([]);
  const [microfences, setMicrofences] = useState<NameId[]>([]);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [startScrolling, setStartScrolling] = useState<boolean>(false);

  const ebtPageRef = useRef<number>(1);
  const isPaginatingRef = useRef<boolean>(false);

  const paginateEBTs = useCallback(async () => {
    let filters = `?perPage=200`;
    Object.entries(ebtFilter ?? {}).map(([key, value]) => {
      if (key !== undefined && value !== undefined) {
        filters = filters.concat(
          `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
        );
      }
    });

    const { searches, count } = (
      await axios.get<{ searches: EBT[]; count: number }>(
        `${notifierUrl}/${cid}/${pid}/search/paginate/${ebtPageRef.current}${filters}`,
        authedConfig,
      )
    ).data;
    props.setAvailableEBTs(searches);
    setEbtCount(count);
    setDeleting(undefined);
    return searches;
  }, [notifierUrl, authedConfig, cid, pid, props, ebtFilter]);

  const getSpecifiedGeofences = useCallback(
    async (ebts: EBT[]) => {
      const geofenceIds = ebts
        .filter(e => e.listenerType === EBTListenerTypeId.Geofence)
        .map(e => e.listenerId)
        .toString();

      if (!geofenceIds) return;
      setIsLoading(true);
      const { data: fences } = await axios.get<Geofence[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/specified/${geofenceIds}`,
        authedConfig,
      );

      setGeofences(fences);
      setIsLoading(false);
    },
    [triggersUrl, cid, pid, authedConfig, setIsLoading],
  );

  const getSpecifiedMicrofences = useCallback(
    async (ebts: EBT[]) => {
      const microfenceIds = ebts
        .filter(e => e.listenerType === EBTListenerTypeId.Microfence)
        .map(e => e.listenerId)
        .toString();

      if (!microfenceIds) return;
      setIsLoading(true);
      const { data: fences } = await axios.get<{ id: string; name: string }[]>(
        `${triggersUrl}/${cid}/${pid}/microfences/specified/${microfenceIds}`,
        authedConfig,
      );

      setMicrofences(fences);
      setIsLoading(false);
    },
    [triggersUrl, cid, pid, authedConfig, setIsLoading],
  );

  useEffect(() => {
    if (!props.refresh) return;
    const fetchData = async () => {
      setIsLoading(true);
      ebtPageRef.current = 1;
      const ebts = await paginateEBTs();
      await refreshBoundaries();
      await getSpecifiedGeofences(ebts);
      await getSpecifiedMicrofences(ebts);
      setIsLoading(false);
    };
    try {
      fetchData();
      setIsLoading(false);
      props.setRefresh(false);
    } catch (error) {
      return;
    }
  }, [
    props,
    paginateEBTs,
    refreshBoundaries,
    getSpecifiedGeofences,
    getSpecifiedMicrofences,
    setIsLoading,
  ]);

  const removeDwellTimeFromFence = async (
    layerId: string,
    id: string,
    type: GeofenceEntityType,
  ) => {
    const fence = (
      await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
        authedConfig,
      )
    ).data;

    const geomobyProperties: Record<string, string> = fence.geomobyProperties;
    delete geomobyProperties['dwellSeconds'];

    await axios.patch(
      `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
      {
        geometry: fence.points,
        properties: {
          ...fence,
          geomobyProperties,
        },
        type: 'Feature',
      },
      authedConfig,
    );
  };

  const deleteAction = async (ebt: EBT, actionType: EBTActionTypeId, actionId: string) => {
    try {
      if (ebt.listenerType === EBTListenerTypeId.Sensor) {
        await axios.delete(`${triggersUrl}/${cid}/${pid}/boundary/${ebt.listenerId}`, authedConfig);
      }
      await axios.delete(
        `${notifierUrl}/${cid}/${pid}/${actionType.toLowerCase()}/${actionId}`,
        authedConfig,
      );
      props.setRefresh(true);
      if (ebt.dwellSeconds !== undefined) {
        const foundFence = geofences.find(f => f.id === ebt.listenerId);
        if (!foundFence) return;
        const { layerId, id, type } = foundFence;
        await removeDwellTimeFromFence(layerId, id, type);
      }
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      setSaveNotification({ id: SaveResult.FAIL, action: 'Delete' });
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: '20px',
          marginRight: '26px',
        }}
      >
        <Header icon={<DirectionsRun />}>Active events</Header>
      </div>

      <Grid
        container
        direction="column"
        style={{
          marginLeft: '40px',
          display: 'grid',
          gridTemplateColumns: '80px 80px',
          width: 'fit-content',
        }}
      >
        <Button onClick={() => setOpenFilterDialog(true)}>
          <span style={{ fontSize: '10px' }}>Filter</span>
          <FilterList />
        </Button>
        <Button
          onClick={() => {
            setEbtFilter(undefined);
            props.setAvailableEBTs([]);
            ebtPageRef.current = 1;
            props.setRefresh(true);
          }}
        >
          <span style={{ fontSize: '10px' }}>Clear</span>
          <Clear />
        </Button>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          marginBottom: '15px',
          marginTop: '-30px',
          paddingRight: '40px',
          alignContent: 'end',
        }}
      >
        <Typography>{`${ebtCount} ${Number(ebtCount) === 1 ? 'result' : 'results'}`}</Typography>
      </Grid>

      <Grid
        id="pagination-container"
        container
        justifyContent="left"
        onScroll={async e => {
          const target = e.target as HTMLTextAreaElement;
          if (target.scrollTop > 8000) {
            if (isPaginatingRef.current) {
              target.scrollTop = 7800;
              return;
            }
            ebtPageRef.current += 1;
            setStartScrolling(true);
            isPaginatingRef.current = true;
            await paginateEBTs();
            isPaginatingRef.current = false;
            target.scrollTop = 200;
          }
          if (startScrolling && target.scrollTop < 100) {
            if (isPaginatingRef.current) {
              target.scrollTop = 300;
              return;
            }
            if (ebtPageRef.current > 1) {
              ebtPageRef.current -= 1;
            } else {
              setStartScrolling(false);
              return;
            }
            isPaginatingRef.current = true;
            await paginateEBTs();
            isPaginatingRef.current = false;
            target.scrollTop = 7800;
          }
        }}
        style={{
          paddingTop: '5px',
          marginLeft: '2%',
          border: 'inset #2D3748',
          maxWidth: '96%',
          width: 'fit-content, 30%',
          height: `1000px`,
          overflowX: 'hidden',
          overflowY: 'auto',
          flexFlow: 'column',
        }}
      >
        {EmailList({
          ...props,
          isLoading,
          setDeleting,
          boundaries,
          geofences,
          microfences,
        })}

        {WebhookList({
          ...props,
          isLoading,
          setDeleting,
          boundaries,
          geofences,
          microfences,
        })}
      </Grid>

      <Dialog open={!!deleting} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Are you sure you want to delete this Event?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              if (deleting) {
                const deletable =
                  deleting.type === EBTActionTypeId.Email
                    ? props.availableEBTs
                        .filter(e => e.notifierType === EBTActionTypeId.Email)
                        ?.find(e => e.id === deleting.id)
                    : props.availableEBTs
                        .filter(e => e.notifierType === EBTActionTypeId.Webhook)
                        ?.find(w => w.id === deleting.id);
                if (deletable) {
                  deleteAction(deletable, deleting.type, deleting.id);
                } else {
                  props.setRefresh(true);
                }
              }
              setDeleting(undefined);
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '70%',
            maxHeight: '80%',
            width: '650px',
            height: '80%',
          },
        }}
      >
        {EBTFilterDialog({
          ...props,
          setOpenFilterDialog,
          ebtFilter,
          setEbtFilter,
          enteredState,
          setEnteredState,
          dwellTimeMinimum,
          setDwellTimeMinimum,
        })}
      </Dialog>
    </>
  );
};
export default EBTList;
