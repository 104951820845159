import { Button, Container, Grid, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Header } from '../../../../../Common/Sidebar';
import { AUTHED_REQUEST_CONFIG } from '../../../../../store/auth';
import { TRIGGERS_URL } from '../../../../../store/url';
import { CID, PID, SUPER_ADMIN } from '../../../../../store/user';
import { FRESH_LAYER } from '../../../../../util/constants';
import { FileInput } from '../../../../../util/FileInput/FileInput';
import { LayersProps, SearchListProps } from '../../Props';
import * as Buffer from 'buffer';
import { normaliseErrorMessage } from '../../../../../util/ErrorMessages';
import { EntityType, GeofenceEntityType } from '../../../../../util/enums';
import { SaveResult, SAVE_NOTIFICATION } from '../../../../../store/notifications';
import { GeofenceFilter } from '../../../types';
import { GridRowData } from '@material-ui/data-grid';
import { GeoJSONFeature } from 'ol/format/GeoJSON';

export const useUploadLayer = (
  props: LayersProps &
    SearchListProps & { uploading: boolean; setUploading: Dispatch<SetStateAction<boolean>> },
) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isSuperAdmin = useAtomValue(SUPER_ADMIN);

  const [jsonFile, setJsonFile] = useState<File>();

  const uploadFile = async (layerId: string) => {
    if (!jsonFile) return;
    const data: { type: 'FeatureCollection'; name: string; features: GeoJSONFeature[] } =
      JSON.parse(await jsonFile.text());

    const polygonsAndTripwires = data.features?.filter(
      f => f.geometry.type?.toLowerCase() !== GeofenceEntityType.Multipolygon,
    );
    const multipolygons = data.features?.filter(
      f => f.geometry.type?.toLowerCase() === GeofenceEntityType.Multipolygon,
    );

    if (polygonsAndTripwires.length > 0) {
      const polygonsAndTripwiresData = JSON.stringify({ ...data, features: polygonsAndTripwires });
      const polygonsAndTripwiresFile = new Blob([polygonsAndTripwiresData], {
        type: 'application/json',
      });
      const polygonsAndTripwiresFormData = new FormData();
      polygonsAndTripwiresFormData.append('file', polygonsAndTripwiresFile);

      await axios.post(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/collection/upload`,
        polygonsAndTripwiresFormData,
        authedConfig,
      );
    }
    if (multipolygons.length > 0) {
      const multipolygonsData = JSON.stringify({ ...data, features: multipolygons });
      const multipolygonsFile = new Blob([multipolygonsData], { type: 'application/json' });
      const multipolygonsFormData = new FormData();
      multipolygonsFormData.append('file', multipolygonsFile);
      await axios.post(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/collection/multipolygons/upload`,
        multipolygonsFormData,
        authedConfig,
      );
    }
  };

  return (
    <>
      {isSuperAdmin && (
        <Container
          style={{
            paddingBottom: '45px',
          }}
        >
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px 10px 10px 33px',
            }}
          >
            <Header icon={undefined}>Upload new geofences</Header>
            <Typography>Bulk upload via JSON file</Typography>
            <FileInput id="json" allowTypes={['.json']} onFileSet={f => setJsonFile(f)} />
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              width: 'calc(100% - 22px)',
              paddingTop: '15px',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={!jsonFile}
              style={{
                width: '25vw',
                maxWidth: '500px',
                minWidth: '255px',
              }}
              size="large"
              onClick={async () => {
                if (!jsonFile || !props.selectedLayer) return;
                let layerId: string | undefined = props.selectedLayer.id;
                const layerName = props.selectedLayer.name ?? `Group ${props.layerIds.length + 1}`;
                props.setUploading(true);
                try {
                  if (props.selectedLayer?.id === FRESH_LAYER) {
                    layerId = await props.saveLayerChanges();
                    if (!layerId || layerId === FRESH_LAYER)
                      throw new Error('Group attempting to upload geofences to, does not exist.');
                  }
                  await uploadFile(layerId);
                  props.setUploadLayerJson(false);
                  props.setClearFilter(true);
                  props.setUploading(false);
                } catch (error) {
                  props.setUploading(false);
                  const errorMessage = normaliseErrorMessage(
                    error as AxiosError,
                    EntityType.Geofence,
                  );
                  setSaveNotification({
                    id: SaveResult.FAIL,
                    action: 'Save',
                    message: errorMessage,
                  });
                }
              }}
            >
              Submit
            </Button>
          </Grid>
        </Container>
      )}
    </>
  );
};
