import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridInputRowSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { BACKGROUND } from '../../../Style/GeoMobyBaseTheme';
import { EBT_DISPLAY_LIMIT } from '../../../util/constants';
import {
  CrossingTypeId,
  EBTTriggerSubTypeId,
  EBTTriggerTypeId,
  EBTTriggerTypeOptionsId,
} from '../../../util/enums';
import InputContainer from '../../Global/InputContainer';
import { MICROFENCE_LAYER_ID } from '../../Map/BeaconUtils';
import { GeofenceSearchToolbar } from '../../Map/GeofenceSearchToolbar';
import { EBTFormProps } from '../Props';
import { Crossings } from '../values';

const TriggerValue = (props: EBTFormProps) => {
  const [maxDate, setMaxDate] = useState<Date | null>();
  const [minDate, setMinDate] = useState<Date | null>();

  return (
    <>
      {!props.hideForm && props.selectedTriggerType && props.selectedTriggerOption && (
        <Paper
          variant="outlined"
          style={{
            width: 'calc(100% - 10vw)',
            background: BACKGROUND,
          }}
        >
          <Box padding={2}>
            <form
              className={
                props.selectedTriggerType.id === EBTTriggerTypeId.Proximity
                  ? 'hidden'
                  : 'trigger-form'
              }
              noValidate
              autoComplete="off"
              onChange={e => {
                if (
                  props.selectedTriggerType?.id === EBTTriggerTypeId.Proximity ||
                  (props.selectedTriggerType?.id === EBTTriggerTypeId.Location &&
                    props.selectedTriggerOption?.id !== EBTTriggerTypeOptionsId.Dwell)
                )
                  return;
                props.setTriggerValue((e.target as HTMLTextAreaElement).value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              {(() => {
                switch (props.selectedTriggerType.id) {
                  case EBTTriggerTypeId.Proximity:
                    if (
                      props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Entry ||
                      props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Exit
                    ) {
                      return (
                        <Stack direction="column" spacing={1}>
                          <GeofenceSearchToolbar
                            layerId={MICROFENCE_LAYER_ID}
                            searchType={'Microfences'}
                            searchString={props.searchString}
                            setSearchString={props.setSearchString}
                            onSearch={() => {
                              if (props.searchString !== props.searchStringRef.current) {
                                props.searchStringRef.current = props.searchString;
                                props.paginateMicrofences();
                              }
                            }}
                            onFocus={() => {
                              props.setSelectedEBT(undefined);
                            }}
                          ></GeofenceSearchToolbar>

                          {props.availableFeatures?.length > 0 &&
                            props.microFencesRef.current?.length > 0 && (
                              <div style={{ height: 372, width: '100%' }}>
                                <DataGrid
                                  style={{
                                    fontFamily: `Open Sans, Montserrat, Arial, sans-serif`,
                                    fontWeight: 300,
                                    fontStyle: 'normal',
                                    fontSize: 16,
                                  }}
                                  rows={props.availableFeatures}
                                  columns={[
                                    {
                                      field: 'name',
                                      headerName: 'Select Microfence',
                                      width: 990,
                                    },
                                  ]}
                                  rowCount={props.availableFeaturesCount}
                                  paginationModel={{
                                    pageSize: 5,
                                    page: props.geofencePageRef.current - 1,
                                  }}
                                  pageSizeOptions={[5]}
                                  paginationMode="server"
                                  rowSelectionModel={props.selectedTabledData}
                                  onRowSelectionModelChange={(
                                    selectionModel: GridInputRowSelectionModel | undefined,
                                  ) => {
                                    props.setSelectedTableData(selectionModel);
                                  }}
                                  onPaginationModelChange={(e, value) => {
                                    if (props.availableFeatures.length === 0) {
                                      props.geofencePageRef.current = 1;
                                      props.paginateMicrofences();
                                    } else {
                                      props.geofencePageRef.current = e.page + 1;
                                      props.setAvailableFeatures(
                                        props.microFencesRef.current.slice(
                                          props.geofencePageRef.current * EBT_DISPLAY_LIMIT -
                                            EBT_DISPLAY_LIMIT,
                                          props.geofencePageRef.current * EBT_DISPLAY_LIMIT,
                                        ),
                                      );
                                    }
                                  }}
                                  onRowClick={e => {
                                    props.setSelectedEBT(e);
                                    props.setTriggerValue(null);
                                  }}
                                />
                              </div>
                            )}
                          {props.availableFeatures.length === 0 ? 'No microfences available' : ''}
                        </Stack>
                      );
                    }
                    break;
                  case EBTTriggerTypeId.Location:
                    if (
                      props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Entry ||
                      props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Exit ||
                      props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Crossing ||
                      props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Dwell
                    ) {
                      return (
                        <Stack direction="column" spacing={1}>
                          {
                            <div>
                              <Box mt={1}>
                                {props.selectedTriggerType.id === EBTTriggerTypeId.Location &&
                                  props.selectedTriggerOption.id ===
                                    EBTTriggerTypeOptionsId.Crossing && (
                                    <FormControl fullWidth style={{ marginBottom: 20 }}>
                                      <InputLabel id="select-crossing-direction">
                                        Select Crossing Direction
                                      </InputLabel>
                                      <Select
                                        fullWidth
                                        labelId="select-crossing-direction"
                                        id="select-crossing-direction-dropdown"
                                        value={props.selectedCrossingDirection ?? ''}
                                        label="Select Crossing Direction"
                                        onChange={e => {
                                          props.setSelectedCrossingDirection(
                                            e.target.value as CrossingTypeId,
                                          );
                                        }}
                                      >
                                        {Crossings.map(direction => (
                                          <MenuItem key={direction.key} value={direction.value}>
                                            <Tooltip title={direction.label}>
                                              <Typography
                                                style={{
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  width: 'calc(100% - 50px)',
                                                }}
                                              >
                                                {direction.value === 'LEFT' ? (
                                                  <ArrowForward />
                                                ) : (
                                                  <ArrowBack />
                                                )}
                                                <span
                                                  style={{
                                                    padding: '10px',
                                                  }}
                                                ></span>{' '}
                                                {direction.label}
                                              </Typography>
                                            </Tooltip>
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                <FormControl fullWidth>
                                  <InputLabel id="select-layer">Select layer</InputLabel>
                                  <Select
                                    fullWidth
                                    labelId="select-layer"
                                    id="select-layer-dropdown"
                                    value={
                                      props.availableLayers && props.availableLayers.length > 0
                                        ? props.selectedLayer ?? ''
                                        : ''
                                    }
                                    onChange={e => {
                                      props.paginateGeofences(e.target.value as string);
                                      props.setSelectedLayer(e.target.value as string);
                                    }}
                                    label="Select Layer"
                                  >
                                    {props.availableLayers &&
                                      props.availableLayers
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map(layer => {
                                          return (
                                            <MenuItem key={layer.id} value={layer.id}>
                                              {layer.name}
                                            </MenuItem>
                                          );
                                        })}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                          }

                          <br />
                          {props.selectedLayer && props.selectedTriggerSubType && (
                            <GeofenceSearchToolbar
                              layerId={props.selectedLayer}
                              searchType={
                                props.selectedTriggerSubType?.id === EBTTriggerSubTypeId.ClearedZone
                                  ? 'Cleared Zones'
                                  : 'Geofences'
                              }
                              searchString={props.searchString}
                              setSearchString={props.setSearchString}
                              onSearch={() => {
                                if (props.searchString !== props.searchStringRef.current) {
                                  props.searchStringRef.current = props.searchString;
                                  props.paginateGeofences(props.selectedLayer);
                                }
                              }}
                              onFocus={() => {
                                props.setSelectedEBT(undefined);
                              }}
                            ></GeofenceSearchToolbar>
                          )}

                          <br />
                          {props.availableFeatures?.length > 0 && (
                            <div style={{ height: 372, width: '100%' }}>
                              <DataGrid
                                style={{
                                  fontFamily: `Open Sans, Montserrat, Arial, sans-serif`,
                                  fontWeight: 300,
                                  fontStyle: 'normal',
                                  fontSize: 16,
                                }}
                                rows={props.availableFeatures}
                                columns={[
                                  {
                                    field: 'name',
                                    headerName: 'Select Geofence',
                                    width: 990,
                                  },
                                ]}
                                rowCount={props.availableFeaturesCount}
                                paginationModel={{
                                  pageSize: 5,
                                  page: props.geofencePageRef.current - 1,
                                }}
                                pageSizeOptions={[5]}
                                paginationMode="server"
                                rowSelectionModel={props.selectedTabledData}
                                onRowSelectionModelChange={(
                                  selectionModel: GridInputRowSelectionModel | undefined,
                                ) => {
                                  props.setSelectedTableData(selectionModel);
                                }}
                                onPaginationModelChange={(e, value) => {
                                  props.geofencePageRef.current = e.page + 1;
                                  props.paginateGeofences(props.selectedLayer);
                                }}
                                onRowClick={e => {
                                  props.setSelectedEBT(e);
                                  props.setTriggerValue(null);
                                }}
                              />
                            </div>
                          )}

                          {props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Dwell &&
                            props.selectedEBT && (
                              <InputContainer
                                id="dwell-above"
                                label="Above dwell threshold (seconds)"
                                key={
                                  props.selectedTriggerType.id +
                                  '_' +
                                  props.selectedTriggerOption.id
                                }
                                name={
                                  props.selectedTriggerType.id +
                                  '_' +
                                  props.selectedTriggerOption.id
                                }
                                value={props.triggerValue ?? ''}
                                placeholder="60"
                                required
                              />
                            )}

                          {props.availableFeatures.length === 0 && props.selectedLayer
                            ? 'No geofences available'
                            : ''}
                        </Stack>
                      );
                    }
                    break;
                  case EBTTriggerTypeId.Temperature:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Below)
                      return (
                        <InputContainer
                          id="temperature-below"
                          label="Below body temperature threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="36.6"
                          required
                        />
                      );
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Above)
                      return (
                        <InputContainer
                          id="temperature-above"
                          label="Above body temperature threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="37.0"
                          required
                        />
                      );
                    break;
                  case EBTTriggerTypeId.Spo2:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Below)
                      return (
                        <InputContainer
                          id="spo2-below"
                          label="Below oxygenation threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="95"
                          required
                        />
                      );
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Above)
                      return (
                        <InputContainer
                          id="spo2-above"
                          label="Above oxygenation threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="97"
                          required
                        />
                      );
                    break;
                  case EBTTriggerTypeId.Battery:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Below)
                      return (
                        <InputContainer
                          id="spo2-below"
                          label="Below battery level threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="95"
                          required
                        />
                      );
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Above)
                      return (
                        <InputContainer
                          id="spo2-above"
                          label="Above battery level threshold"
                          key={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          name={props.selectedTriggerType.id + '_' + props.selectedTriggerOption.id}
                          value={props.triggerValue ?? ''}
                          placeholder="97"
                          required
                        />
                      );
                    break;
                  case EBTTriggerTypeId.Scheduler:
                    if (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.NewRule)
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="start-date-scheduler"
                            label="Start Date"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_start'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_start'
                            }
                            required
                            dateRange
                            // valueDate={String(minDate)} // TODO: SCHEDULER. Use date.toIsoString() instead
                            valueDate={minDate ? String(minDate) : undefined}
                            onChangeDate={setMinDate}
                          />
                          <InputContainer
                            id="end-date-scheduler"
                            label="End Date"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_end'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_end'
                            }
                            required
                            dateRange
                            // valueDate={String(maxDate)} // TODO: SCHEDULER. Use date.toIsoString() instead
                            valueDate={maxDate ? String(maxDate) : undefined}
                            onChangeDate={setMaxDate}
                          />
                        </Stack>
                      );
                    break;
                  case EBTTriggerTypeId.Webhook:
                    if (props.selectedTriggerOption.id === 'GET')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="webhook-trigger-get-url"
                            label="URL"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            required
                          />
                          <InputContainer
                            id="webhook-trigger-get-response-field"
                            label="Response field"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              defaultValue={false}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="webhook-trigger-get-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    if (props.selectedTriggerOption.id === 'POST')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="webhook-trigger-post-url"
                            label="URL"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_url'
                            }
                            required
                          />
                          <InputContainer
                            id="webhook-trigger-post-response-field"
                            label="Response field"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_response_field'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              defaultValue={false}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="webhook-trigger-post-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    break;
                  case EBTTriggerTypeId.Custom:
                    if (props.selectedTriggerOption.id === 'STRING')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="custom-trigger-string-key"
                            label="Key"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              value={props.customIsEqual}
                              onChange={e => {
                                e.target.value === 'true'
                                  ? props.setCustomIsEqual(true)
                                  : props.setCustomIsEqual(false);
                              }}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="custom-trigger-string-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    if (props.selectedTriggerOption.id === 'NUMBER')
                      return (
                        <Stack direction="column" spacing={1}>
                          <InputContainer
                            id="custom-trigger-number-key"
                            label="Key"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_key'
                            }
                            required
                          />
                          <div>
                            <FormLabel component="legend">Condition</FormLabel>
                            <RadioGroup
                              aria-label="isEqual"
                              key={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              name={
                                props.selectedTriggerType.id +
                                '_' +
                                props.selectedTriggerOption.id +
                                '_isEqual'
                              }
                              value={props.customIsEqual}
                              onChange={e => {
                                e.target.value === 'true'
                                  ? props.setCustomIsEqual(true)
                                  : props.setCustomIsEqual(false);
                              }}
                            >
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Is not equal to"
                              />
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Is equal to"
                              />
                            </RadioGroup>
                          </div>
                          <InputContainer
                            id="custom-trigger-number-value"
                            label="Value"
                            key={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            name={
                              props.selectedTriggerType.id +
                              '_' +
                              props.selectedTriggerOption.id +
                              '_value'
                            }
                            required
                          />
                        </Stack>
                      );
                    break;
                  default:
                }
              })()}
            </form>
          </Box>
        </Paper>
      )}
    </>
  );
};
export default TriggerValue;
