/* eslint-disable @typescript-eslint/no-explicit-any */

import { array } from 'fp-ts/es6';
import { eqString } from 'fp-ts/es6/Eq';
import { pipe } from 'fp-ts/es6/pipeable';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
  AssetState,
  CMContainer,
  DeviceLocation,
  GeofenceEvent,
  GlobalProjectId,
  LocalBeacons,
  MessageType,
  SensedAssetsReport,
  SensedTemp,
  SensedTriggeredEvent,
  TempRangeEvent,
  WelfareCheckResponse,
} from '../../Components/Map/Messages';
import {
  LIVE_LATEST_EVENTS,
  LIVE_NEW_MESSAGES,
  LIVE_NEW_NOTIFICATIONS,
} from '../../store/liveEvents';
import { isDefined } from '../../util/isDefined';
import {
  AssetUpdateType,
  LiveMapUpdate,
  updateFenceEvent,
  updateFullState,
  updateLocation,
  updateNotification,
  updateSensedAssets,
  updateSensedTemp,
  updateSensedTriggered,
  updateTempRangeEvent,
} from './LiveMapActions';
import { LIVE_NOTIFICATIONS, RawNotification, makeNotificationId } from '../../store/notifications';
import { uniqBy } from 'lodash';
import { TRACKING_BOUNDS } from '../../util/constants';
import { localiseAssetId } from '../../Components/Map/LiveAndReplay/MapUpdates';

function emptyAssetState(
  label: string,
  lastLocation: DeviceLocation | undefined = undefined,
  lastFenceEvent: GeofenceEvent | undefined = undefined,
  lastBeacons: SensedAssetsReport | undefined = undefined,
  lastLocalBeacons: LocalBeacons | undefined = undefined,
  lastTemp: SensedTemp | undefined = undefined,
  lastTempRangeEvent: TempRangeEvent | undefined = undefined,
  lastWelfareCheckResponse: WelfareCheckResponse | undefined = undefined,
  recentSensedTriggered: SensedTriggeredEvent[] | undefined = undefined,
): AssetState {
  return {
    id: {},
    label,
    lastLocation,
    lastFenceEvent,
    lastSensed: lastBeacons,
    lastLocalBeacons,
    lastTemp,
    lastTempRangeEvent,
    lastWelfareCheckResponse,
    recentSensedTriggered,
  };
}

export interface LiveMapState {
  assets: { assetId: string; assetState: AssetState }[];
  selectedAsset: string | undefined;
  notifications: RawNotification[];
}

export const nonImmerReducer = (lms: LiveMapState, liveMapUpdate: LiveMapUpdate): LiveMapState => {
  if (liveMapUpdate.type === AssetUpdateType.FullStateUpdate) {
    return liveMapUpdate.payload;
  }
  const assetId =
    liveMapUpdate.payload.assetId === undefined &&
    liveMapUpdate.type === AssetUpdateType.LocalBeacons
      ? localiseAssetId(liveMapUpdate.payload.deviceLocation.assetId)
      : liveMapUpdate.payload.assetId;
  if (!assetId) {
    return lms;
  }
  const updatedAsset =
    lms.assets.find(asset => asset.assetId === assetId)?.assetState ?? emptyAssetState(assetId);
  switch (liveMapUpdate.type) {
    case AssetUpdateType.Location: {
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              source: liveMapUpdate.payload.source,
              lastLocation: liveMapUpdate.payload,
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.GeofenceEvent: {
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              lastFenceEvent: liveMapUpdate.payload,
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.SensedAssets: {
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              lastSensed: liveMapUpdate.payload,
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.SensedTriggered: {
      const previousSensedTriggered = updatedAsset.recentSensedTriggered ?? [];
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              recentSensedTriggered: [
                liveMapUpdate.payload,
                ...previousSensedTriggered.filter(
                  st =>
                    JSON.stringify(st.sensedId) !== JSON.stringify(liveMapUpdate.payload.sensedId),
                ),
              ].sort(
                ({ timestamp: a }, { timestamp: b }) =>
                  new Date(b).getTime() - new Date(a).getTime(),
              ),
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.LocalBeacons: {
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              lastLocalBeacons: liveMapUpdate.payload,
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.SensedTemp: {
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              lastTemp: liveMapUpdate.payload,
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.TempRangeEvent: {
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              lastTempRangeEvent: liveMapUpdate.payload,
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.WelfareCheckResponseUpdate: {
      return {
        ...lms,
        assets: [
          {
            assetId: assetId,
            assetState: {
              ...updatedAsset,
              id: liveMapUpdate.payload.id,
              lastWelfareCheckResponse: liveMapUpdate.payload,
            },
          },
          ...lms.assets.filter(asset => asset.assetId !== assetId),
        ],
      };
    }
    case AssetUpdateType.NotificationUpdate: {
      const nextNotification: RawNotification = {
        ...liveMapUpdate.payload,
        datetime: new Date(liveMapUpdate.payload.timestamp),
      };
      return {
        ...lms,
        notifications: uniqBy([...lms.notifications, nextNotification], 'notificationId'),
      };
    }
  }
};

export function toMapUpdateOrUndefined(
  o: CMContainer,
): LiveMapUpdate | LiveMapUpdate[] | undefined {
  const m = (o as any).data;
  switch (o.type) {
    case MessageType.Coordinates:
      return updateLocation({
        id: m.ids.id,
        assetId: m.ids.label,
        label: m.ids.label,
        beaconId: m.ids.id.beaconId,
        source: m.sourceIds,
        timestamp: m.iso8601,
        lon: m.coordinates.longitude,
        lat: m.coordinates.latitude,
        radius: m.coordinates.errorRadiusMetres,
      });
    case MessageType.CoordinatesDwell:
      return updateFenceEvent({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        fenceId: m.fenceIds.id,
        fenceName: m.fenceIds.label,
        type: m.fenceIds.type,
        layerId: m.layerIds.id,
        x: m.coordinates.longitude,
        y: m.coordinates.latitude,
        event: 'Dwell',
        dwellSeconds: m.dwellSeconds,
      });
    case MessageType.CoordinatesTriggered:
      if (m.layerIds.label === TRACKING_BOUNDS && !m.entered) {
        return [
          updateFenceEvent({
            id: m.ids.id,
            assetId: m.ids.label,
            timestamp: m.iso8601,
            fenceId: m.fenceIds.id,
            fenceName: m.fenceIds.label,
            type: m.fenceIds.type,
            layerId: 'n/a',
            x: m.coordinates.longitude,
            y: m.coordinates.latitude,
            event: m.entered ? 'Entered' : 'Exited',
          }),
          updateNotification({
            id: m.ids.id,
            notificationId: makeNotificationId({
              type: 'CoordinatesTriggered',
              iso8601: m.iso8601,
              primaryId: JSON.stringify(m.ids.id),
            }),
            assetId: m.ids.label,
            label: `Asset ${m.ids.label ?? m.ids.id?.beaconId} exited a tracking bound`,
            timestamp: m.iso8601,
          }),
        ];
      }

      return updateFenceEvent({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        fenceId: m.fenceIds.id,
        fenceName: m.fenceIds.label,
        type: m.fenceIds.type,
        layerId: 'n/a',
        x: m.coordinates.longitude,
        y: m.coordinates.latitude,
        event: m.entered ? 'Entered' : 'Exited',
      });
    case MessageType.Sensed:
      return updateSensedAssets({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        assets: (m.sensed ?? []).map((sensed: any) => ({
          label: sensed.ids.label,
          beaconId: sensed.ids.id.beaconId,
          rssi: sensed.rssi,
          txpower: sensed.txPower,
          distance: sensed.distanceMetres,
          battery_level: NaN,
        })),
      });
    case MessageType.SensedTriggered:
      return [
        updateSensedTriggered({
          id: m.ids.id,
          assetId: m.ids.label,
          sensedId: m.sensedIds.id,
          sensedLabel: m.sensedIds.label,
          timestamp: m.iso8601,
          entered: m.entered,
        }),
        updateNotification({
          id: m.ids.id,
          notificationId: makeNotificationId({
            type: 'SensedTriggered',
            iso8601: m.iso8601,
            primaryId: JSON.stringify(m.ids.id),
            otherIds: [JSON.stringify(m.sensedIds.id)],
          }),
          assetId: m.ids.label,
          label: `Asset  ${m.sensedIds.label ?? m.sensedIds.id?.beaconId} ${
            m.entered ? 'entered' : 'exited'
          } MicroFence ${m.ids.label}`,
          timestamp: m.iso8601,
          interaction: {
            microfenceId: m.ids.id,
            microfenceName: m.ids.label,
            type: 'find-microfence',
          },
        }),
      ];
    case MessageType.Temperature:
      return updateSensedTemp({
        id: m.ids.id,
        assetId: m.ids.label,
        timestamp: m.iso8601,
        temp: m.celsius,
      });
    case MessageType.SensorTriggeredBoundary:
      if (m.boundaryIds.type === 'TEMPERATURE_CELSIUS')
        return updateTempRangeEvent({
          id: m.ids.id,
          temp: m.value,
          event: m.entered ? 'Entered' : 'Exited',
          limit: NaN,
          assetId: m.ids.label,
          timestamp: m.iso8601,
          tempRangeId: m.boundaryIds.id,
          tempRangeLabel: m.boundaryIds.label,
        });
      else return;
    case MessageType.Battery:
    case MessageType.Spo2:
      return;
    default:
      console.warn('Unknown message from websocket', o);
  }
}

export interface AuthPacket {
  gpid: GlobalProjectId;
  login: string;
  key: string;
}

export interface LiveStreamData {
  state: LiveMapState;
  lastUpdates: LiveMapUpdate[] | undefined;
  startDate: Date;
}

const INITIAL_STATE: LiveMapState = {
  assets: [],
  selectedAsset: undefined,
  notifications: [],
};

export function useGeomobyLiveStream({
  useFor,
}: {
  useFor: 'notifications' | 'livemap';
}): LiveStreamData {
  const startDate = useMemo(() => new Date(), []);
  const [lmState, lmDispatch] = useReducer(nonImmerReducer, INITIAL_STATE);
  const [lastLMU, setLMU] = useState<LiveMapUpdate[] | undefined>();
  const [queue, setQueue] = useAtom(
    useFor === 'notifications' ? LIVE_NEW_NOTIFICATIONS : LIVE_NEW_MESSAGES,
  );
  const lastEvents = useAtomValue(LIVE_LATEST_EVENTS);
  const setNotifications = useSetAtom(LIVE_NOTIFICATIONS);

  const isWantedUpdate = useCallback(
    (liveMapUpdate: LiveMapUpdate) =>
      useFor === 'notifications'
        ? liveMapUpdate.type === AssetUpdateType.NotificationUpdate
        : liveMapUpdate.type !== AssetUpdateType.NotificationUpdate,
    [useFor],
  );

  useEffect(() => {
    if (lastEvents) {
      const msgs = lastEvents.flatMap(e => toMapUpdateOrUndefined(e)).filter(isDefined);
      const actualInitialState = array.reduce<LiveMapUpdate, LiveMapState>(
        INITIAL_STATE,
        (b: LiveMapState, a: LiveMapUpdate) => (isWantedUpdate(a) ? nonImmerReducer(b, a) : b),
      )(msgs);
      const updateEvent = updateFullState(actualInitialState);
      lmDispatch(updateEvent);
      setLMU([updateEvent]);
    }
  }, [lastEvents, isWantedUpdate]);

  useEffect(() => {
    let messages: CMContainer[] = [];
    if (queue.length > 0)
      setQueue(queue => {
        messages = queue;
        return [];
      });
    const updates = messages
      .flatMap(message => toMapUpdateOrUndefined(message))
      .filter((update): update is LiveMapUpdate => !!update && isWantedUpdate(update));

    updates.forEach(update => {
      lmDispatch(update);
    });
    setLMU(updates);
  }, [queue, setQueue, isWantedUpdate]);

  useEffect(() => {
    if (useFor === 'notifications') {
      setNotifications(notifications =>
        uniqBy([...notifications, ...lmState.notifications], 'notificationId'),
      );
    }
  }, [useFor, lmState.notifications, setNotifications]);

  return {
    state: lmState,
    lastUpdates: lastLMU,
    startDate,
  };
}
