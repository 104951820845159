import { GridRowData } from '@material-ui/data-grid';
import { Delete } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { Dispatch, SetStateAction, useState } from 'react';
import { UNKNOWN_LAYER } from '../../../../../util/constants';
import { GeomobyPropertiesValues } from '../../../../../util/enums';
import InputContainer from '../../../../Global/InputContainer';
import { lineLimitStyle, PRIMARY } from '../../../../../Style/GeoMobyBaseTheme';
import { GeomobyOverride } from '../../../types';
import { AlertStylePropertyValues } from '../../../values';

export const findValueWithMoreThan256Chars = (
  features: Feature<Geometry>[],
  property: GeomobyPropertiesValues,
) =>
  features.find(
    f => f.get('geomobyProperties')[property] && f.get('geomobyProperties')[property]?.length > 256,
  ) ??
  features.find(f =>
    f
      .get('geomobyOverrides')
      ?.find(
        (override: GeomobyOverride) =>
          override.property === property && override.value?.length > 256,
      ),
  );

export const findNonBooleanValue = (
  features: Feature<Geometry>[],
  property: GeomobyPropertiesValues,
) =>
  features.find(
    f =>
      f.get('geomobyProperties')[property] &&
      !(
        f.get('geomobyProperties')[property] === String(true) ||
        f.get('geomobyProperties')[property] === String(false)
      ),
  ) ??
  features.find(f =>
    f
      .get('geomobyOverrides')
      ?.find(
        (override: GeomobyOverride) =>
          override.property === property &&
          !(override.value === String(true) || override.value === String(false)),
      ),
  );

export const findValueWithNonWholeNumber = (
  features: Feature<Geometry>[],
  property: GeomobyPropertiesValues,
) =>
  features.find(
    f =>
      f.get('geomobyProperties')[property] &&
      (!(Number(f.get('geomobyProperties')[property]) > 0) ||
        !(Number(f.get('geomobyProperties')[property]) % 1 === 0)),
  ) ??
  features.find(f =>
    f
      .get('geomobyOverrides')
      ?.find(
        (override: GeomobyOverride) =>
          override.property === property &&
          (!(Number(override.value) > 0) || !(Number(override.value) % 1 === 0)),
      ),
  );

export const findInvalidAlertStyleValue = (features: Feature<Geometry>[]) =>
  features.find(
    f =>
      f.get('geomobyProperties')[GeomobyPropertiesValues.alertStyle] &&
      !AlertStylePropertyValues.find(
        value => value === f.get('geomobyProperties')[GeomobyPropertiesValues.alertStyle],
      ),
  ) ??
  features.find(f =>
    f
      .get('geomobyOverrides')
      ?.find(
        (override: GeomobyOverride) =>
          override.property === GeomobyPropertiesValues.alertStyle &&
          !AlertStylePropertyValues.find(value => value === override.value),
      ),
  );

export const PropertiesTable = ({
  rows,
}: {
  rows: { index: number; property: string; value: string }[];
}) => (
  <TableContainer component={Paper}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell style={{ fontSize: '15px' }}>Property</TableCell>
          <TableCell style={{ fontSize: '15px' }} align="right">
            Value
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length === 0 && (
          <TableRow>
            <TableCell>No properties found</TableCell>
          </TableRow>
        )}
        {rows
          .sort((a, b) => a.property.localeCompare(b.property))
          .map(row => (
            <TableRow
              key={row.property}
              sx={{ fontSize: '15px', '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <Tooltip title={row.property}>
                <TableCell component="th" scope="row">
                  {row.property}
                </TableCell>
              </Tooltip>
              <Tooltip title={row.value}>
                <TableCell align="right" style={{ ...lineLimitStyle, overflowWrap: 'anywhere' }}>
                  {row.value}
                </TableCell>
              </Tooltip>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export const GeomobyProperties = ({
  selectedGeofence,
  displayGeomobyProperties,
  setDisplayGeomobyProperties,
  properties,
  saveAllChanges,
  updateGeomobyProperties,
}: {
  selectedGeofence: GridRowData | undefined;
  displayGeomobyProperties: { index: number; property: string; value: string }[];
  setDisplayGeomobyProperties: Dispatch<
    SetStateAction<{ index: number; property: string; value: string }[]>
  >;
  properties: { label: string; id: string; isCustom: boolean }[];
  saveAllChanges: () => Promise<void>;
  updateGeomobyProperties: (
    geomobyProperties: Record<string, string>,
  ) => Promise<Record<string, string> | undefined>;
}) => {
  const onChangeFields = (updatedProperty: { index: number; property: string; value: string }) => {
    return [
      ...displayGeomobyProperties.filter(displayed => displayed.index !== updatedProperty.index),
      updatedProperty,
    ];
  };

  return (
    <>
      <Grid
        style={{
          marginTop: '10px',
        }}
      >
        <Tooltip title={'Geofence Properties'}>
          <Typography style={{ color: PRIMARY }}>Geofence Properties</Typography>
        </Tooltip>

        <Grid
          container
          style={{
            display: 'grid',
            gap: '2%',
            gridTemplateColumns: '49% 49%',
          }}
        >
          <Tooltip title={'Property'}>
            <Typography>Property</Typography>
          </Tooltip>
          <Tooltip title={'Value'}>
            <Typography>Value</Typography>
          </Tooltip>
        </Grid>

        {displayGeomobyProperties
          .sort((a, b) => {
            return String(a.index).localeCompare(String(b.index));
          })
          .map(prop => {
            return (
              <Grid
                container
                key={prop.property}
                sx={{
                  display: 'grid',
                  gap: '2%',
                  gridTemplateColumns: '45% 45% 5%',
                  '& .MuiInputBase-root': {
                    height: '35px',
                  },
                }}
              >
                {/* Property */}
                <FormControl
                  sx={{
                    marginTop: '8px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor:
                        !properties.find(p => p.label === prop.property) && prop.property
                          ? 'red !important'
                          : 'inherit',
                    },
                  }}
                >
                  <Select
                    fullWidth
                    id={'prop-property-' + prop.index}
                    name={'prop' + prop.index}
                    value={prop.property}
                    onChange={async (e: { target: { value: string } }) => {
                      const updatedProperties: Record<string, string> = {};
                      const updatedDisplayGeomobyProperties = onChangeFields({
                        index: prop.index,
                        property: e.target.value,
                        value: prop.value,
                      });
                      updatedDisplayGeomobyProperties.map(prop => {
                        updatedProperties[prop.property] = prop.value;
                      });
                      await updateGeomobyProperties(updatedProperties);
                      setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
                    }}
                  >
                    {(properties.find(p => p.label === prop.property)
                      ? properties.filter(
                          p =>
                            !displayGeomobyProperties.find(
                              d => d.property === p.label && d.property !== prop.property,
                            ),
                        )
                      : [{ id: -1, label: prop.property }, ...properties]
                          .filter(p => !displayGeomobyProperties.find(d => d.property === p.label))
                          .sort((a, b) => a.label.localeCompare(b.label))
                    ).map(propName => (
                      <MenuItem
                        key={propName.id}
                        value={propName.label}
                        disabled={!properties.find(p => p.label === propName.label)}
                      >
                        <Tooltip title={propName.label}>
                          <Typography
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: 'calc(100% - 50px)',
                            }}
                          >
                            {propName.label}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Value */}
                <FormControl>
                  <InputContainer
                    id={'prop-value-' + prop.index}
                    label=""
                    key={'prop-value-' + prop.index}
                    name={'prop-value-' + prop.index}
                    value={prop.value}
                    onChange={async (e: { target: { value: string } }) => {
                      const updatedProperties: Record<string, string> = {};
                      const updatedDisplayGeomobyProperties = onChangeFields({
                        index: prop.index,
                        property: prop.property,
                        value: e.target.value,
                      });
                      setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
                    }}
                    onBlur={async (e: { target: { value: string } }) => {
                      const updatedProperties: Record<string, string> = {};
                      const updatedDisplayGeomobyProperties = onChangeFields({
                        index: prop.index,
                        property: prop.property,
                        value: e.target.value,
                      });
                      updatedDisplayGeomobyProperties.map(prop => {
                        updatedProperties[prop.property] = prop.value;
                      });
                      await updateGeomobyProperties(updatedProperties);
                      setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
                    }}
                    onKeyPress={(event: { key: string }) => {
                      if (event.key === 'Enter') {
                        saveAllChanges();
                      }
                    }}
                    placeholder=""
                  />
                </FormControl>

                {/* Delete */}
                <IconButton
                  color="primary"
                  onClick={async () => {
                    const updatedProperties: Record<string, string> = {};
                    const updatedDisplayGeomobyProperties = displayGeomobyProperties.filter(
                      displayed => displayed.index !== prop.index,
                    );
                    updatedDisplayGeomobyProperties.map(prop => {
                      updatedProperties[prop.property] = prop.value;
                    });
                    await updateGeomobyProperties(updatedProperties);
                    setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            );
          })}

        {displayGeomobyProperties.find(
          prop => !properties.find(p => p.label === prop.property) && !!prop.property,
        ) && (
          <Typography
            style={{
              marginTop: '10px',
              color: 'red',
            }}
          >
            {'Properties outlined red are INVALID'}
          </Typography>
        )}

        <Button
          variant="outlined"
          style={{
            marginTop: '10px',
            width: '92%',
          }}
          disabled={
            selectedGeofence?.layerId === UNKNOWN_LAYER ||
            selectedGeofence?.previousLayer === UNKNOWN_LAYER
          }
          onClick={() => {
            if (displayGeomobyProperties.find(p => p.property === '')) return;
            setDisplayGeomobyProperties(
              onChangeFields({
                index: displayGeomobyProperties.length,
                property: '',
                value: '',
              }),
            );
          }}
        >
          Add
        </Button>
      </Grid>
    </>
  );
};
