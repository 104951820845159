import { GeofenceFilterComponent } from '../../../Filter/GeofenceFilterComponent';
import { MicrofenceFilterComponent } from '../../../Filter/MicrofenceFilterComponent';
import { FilterComponentProps } from '../../Props';

export const FilterComponent = (props: FilterComponentProps) => {
  return (
    <div
      style={{
        margin: '20px',
      }}
    >
      {GeofenceFilterComponent(props)}
      {MicrofenceFilterComponent(props)}
    </div>
  );
};
