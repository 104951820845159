import { Accordion, AccordionSummary, Grid } from '@mui/material';
import { SearchTypeIDs } from '../../../../../util/enums';
import { Asset } from '../../../types';
import { SearchListProps } from '../../Props';
import { displayAssetIsActive, displayAssetLabel, selectedElement } from '../../../Helpers';

export const useTrackerList = (
  props: SearchListProps & { availableGpsTrackers: Asset[]; isLoading: boolean },
) => {
  return (
    <>
      {!selectedElement(props) &&
        !props.isLoading &&
        props.searchType?.id === SearchTypeIDs.GPSTrackers &&
        props.availableGpsTrackers.map((gpsTracker, index) => {
          return (
            <Accordion
              id={String(gpsTracker.id)}
              key={index}
              expanded={false}
              style={{ width: '98%' }}
            >
              <AccordionSummary
                onClick={() => {
                  props.setSelectedGPSTracker(gpsTracker);
                  const activeGPSTracker = props.activeAssets.find(
                    active =>
                      active.id?.gpsTrackerId !== undefined &&
                      active.id?.gpsTrackerId === gpsTracker.id,
                  );
                  if (activeGPSTracker) {
                    props.setSelectedAsset(activeGPSTracker);
                  }
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                <Grid container direction="row">
                  <Grid item>{displayAssetLabel(gpsTracker.label, gpsTracker.id)}</Grid>
                  <Grid
                    item
                    style={{
                      width: 'max-content',
                      marginLeft: 'auto',
                    }}
                  >
                    {props.activeAssets.find(
                      active =>
                        active.id?.gpsTrackerId !== undefined &&
                        active.id?.gpsTrackerId === gpsTracker.id,
                    ) && displayAssetIsActive()}
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
