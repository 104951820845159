import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { EBTActionTypeValue, EBTTriggerTypeOptionsId } from '../../../util/enums';
import { getActionIcon } from '../Helpers';
import { EBTFormProps } from '../Props';
import { ActionTypes } from '../values';

const EventAction = (props: EBTFormProps) => {
  return (
    <>
      {!props.hideForm &&
        ((props.selectedTriggerType &&
          props.selectedTriggerOption &&
          (props.triggerValue ||
            (props.selectedEBT &&
              props.selectedTriggerOption.id !== EBTTriggerTypeOptionsId.Dwell) ||
            (props.selectedEBT &&
              props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Dwell &&
              props.triggerValue)) &&
          (props.selectedTriggerOption.id !== EBTTriggerTypeOptionsId.Crossing ||
            (props.selectedTriggerOption.id === EBTTriggerTypeOptionsId.Crossing &&
              props.selectedCrossingDirection))) ||
          props.eventIds) && (
          <FormControl
            fullWidth
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: 'calc(100% - 10vw)',
              alignSelf: 'center',
            }}
          >
            <InputLabel id="trigger-action">Event Action</InputLabel>
            <Select
              fullWidth
              labelId="trigger-action"
              id="trigger-action-dropdown"
              value={props.selectedTriggerAction?.value ?? ''}
              label="Event Action"
              disabled={!!props.eventIds}
              onChange={e => {
                const action = ActionTypes.find(
                  trigger => trigger.value === (e.target.value as EBTActionTypeValue),
                );
                props.setSelectedTriggerAction(action);
              }}
            >
              {ActionTypes.map(action => (
                <MenuItem key={action.id} value={action.value} disabled={action.disabled}>
                  <Tooltip title={action.value}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {getActionIcon(action.id)}
                      <span
                        style={{
                          padding: '10px',
                        }}
                      ></span>{' '}
                      {action.value}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
    </>
  );
};
export default EventAction;
