import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { NOTIFIER_URL, TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { MICROFENCE_LAYER_ID } from '../Map/BeaconUtils';
import { GridRowData } from '@material-ui/data-grid';
import {
  ActionType,
  EBT,
  EmailType,
  EventIds,
  TriggerOption,
  TriggerSubType,
  TriggerType,
  WebhookType,
} from './types';
import { GridInputRowSelectionModel } from '@mui/x-data-grid';
import EBTList from '../Events/List/EBTList';
import { getVectorLayers } from '../Map/Editor/EditorFunctions';
import { initialExtentInDegrees, EBT_DISPLAY_LIMIT } from '../../util/constants';
import {
  CrossingTypeId,
  EBTActionTypeId,
  EBTListenerTypeId,
  EBTTriggerSubTypeId,
  EBTTriggerTypeId,
  EBTTriggerTypeOptionsId,
  EntityType,
  GeofenceEntityType,
} from '../../util/enums';
import EBTForm from '../Events/Form/EBTForm';
import { validateEmailAddress } from './Helpers';
import { NameId } from '../Map/types';
import { geometryTypeOfEntity } from '../Map/commons';

const Events = () => {
  const notifierUrl = useAtomValue(NOTIFIER_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);

  const [availableEBTs, setAvailableEBTs] = useState<EBT[]>([]);
  const [availableFeaturesCount, setAvailableFeaturesCount] = useState<number>(0);
  const [availableFeatures, setAvailableFeatures] = useState<GridRowData[]>([]);
  const [availableLayers, setAvailableLayers] = useState<NameId[] | undefined>();
  const [customIsEqual, setCustomIsEqual] = useState<boolean | null>(null);
  const [emailData, setEmailData] = useState<EmailType | null>(null);
  const [eventIds, setEventIds] = useState<EventIds | null>(null);
  const [eventName, setEventName] = useState<string | null>(null);
  const [hideForm, setHideForm] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [maxDate, setMaxDate] = useState<Date | null>();
  const [minDate, setMinDate] = useState<Date | null>();
  const [microfences, setMicrofences] = useState<{ id: string; name: string }[]>([]);
  const [searchString, setSearchString] = useState<string | undefined>();
  const [selectedTriggerAction, setSelectedTriggerAction] = useState<ActionType | undefined>();
  const [selectedEBT, setSelectedEBT] = useState<GridRowData | undefined>();
  const [selectedTriggerType, setSelectedTriggerType] = useState<TriggerType | undefined>();
  const [selectedTriggerOption, setSelectedTriggerOption] = useState<TriggerOption | undefined>();
  const [selectedTriggerSubType, setSelectedTriggerSubType] =
    useState<TriggerSubType | undefined>();
  const [selectedCrossingDirection, setSelectedCrossingDirection] =
    useState<CrossingTypeId | undefined>();
  const [selectedLayer, setSelectedLayer] = useState<string | null>(null);
  const [selectedTabledData, setSelectedTableData] =
    useState<GridInputRowSelectionModel | undefined>();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [triggerValue, setTriggerValue] = useState<string | null>(null);
  const [webhookData, setWebhookData] = useState<WebhookType | null>(null);

  const geofencePageRef = useRef<number>(1);
  const microFencesRef = useRef<GridRowData[]>([]);
  const searchStringRef = useRef<string | undefined>();

  const paginateMicrofences = useCallback(async () => {
    const microfences = (
      await axios.get<GridRowData[]>(`${triggersUrl}/${cid}/${pid}/microfences`, authedConfig)
    ).data.filter(m => {
      return searchString ? m.name.includes(searchString) : m.name;
    });

    microFencesRef.current = microfences.sort((a, b) => a.name.localeCompare(b.name));
    setAvailableFeatures(microfences.slice(0, EBT_DISPLAY_LIMIT));
    setAvailableFeaturesCount(microfences.length);
  }, [triggersUrl, authedConfig, cid, pid, searchString]);

  const getLayers = useCallback(async () => {
    const layers: NameId[] | undefined = await getVectorLayers(
      triggersUrl,
      authedConfig,
      { cid, pid },
      { latitude: 0, longitude: 0, extentInDegrees: initialExtentInDegrees },
    );

    setAvailableLayers(layers);
  }, [triggersUrl, authedConfig, cid, pid]);

  const paginateGeofences = useCallback(
    async layerId => {
      if (!layerId || layerId === MICROFENCE_LAYER_ID) return;
      const data = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${
            geofencePageRef.current
          }?layerId=${layerId}&perPage=${EBT_DISPLAY_LIMIT}&fenceType=${
            selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Crossing
              ? EntityType.Line
              : selectedTriggerSubType?.id === EBTTriggerSubTypeId.ClearedZone
              ? EntityType.Multipolygon
              : EntityType.Polygon
          }${searchString ? `&searchName=` + encodeURIComponent(searchString) : ''}`,
          authedConfig,
        )
      ).data;
      setAvailableFeatures(data.geofences);
      setAvailableFeaturesCount(Number(data.count));
    },
    [
      triggersUrl,
      authedConfig,
      cid,
      pid,
      selectedTriggerOption,
      selectedTriggerSubType,
      searchString,
    ],
  );

  const clearFormData = useCallback(() => {
    geofencePageRef.current = 1;
    setIsEditing(false);
    setSelectedTriggerType(undefined);
    setSelectedTriggerOption(undefined);
    setSelectedTriggerSubType(undefined);
    setSelectedLayer(null);
    setSelectedCrossingDirection(undefined);
    setTriggerValue(null);
    setSelectedEBT(undefined);
    setSelectedTableData(undefined);
    setSelectedTriggerAction(undefined);
    setEmailData(null);
    setWebhookData(null);
    setEventName(null);
    setEventIds(null);
    setSearchString(undefined);
    searchStringRef.current = undefined;
  }, []);

  const createListeners = useCallback(
    (id?: string) => {
      const isSensor =
        selectedTriggerType?.id === EBTTriggerTypeId.Battery ||
        selectedTriggerType?.id === EBTTriggerTypeId.Temperature ||
        selectedTriggerType?.id === EBTTriggerTypeId.Spo2;
      const geofenceType =
        selectedTriggerType?.id === EBTTriggerTypeId.Location && selectedEBT
          ? selectedTriggerSubType?.id === EBTTriggerSubTypeId.ClearedZone
            ? GeofenceEntityType.Multipolygon
            : geometryTypeOfEntity(selectedEBT?.row)
          : undefined;

      return [
        {
          type:
            selectedTriggerType?.id === EBTTriggerTypeId.Location
              ? EBTListenerTypeId.Geofence
              : isSensor
              ? EBTListenerTypeId.Sensor
              : EBTListenerTypeId.Microfence,
          sensorType: isSensor ? 'boundary' : undefined,
          geofenceType,
          entered: isSensor
            ? true
            : selectedTriggerOption &&
              (selectedTriggerOption.id === EBTTriggerTypeOptionsId.Entry ||
                selectedTriggerOption.id === EBTTriggerTypeOptionsId.Dwell ||
                selectedCrossingDirection === CrossingTypeId.Left)
            ? true
            : false,
          dwellSeconds:
            selectedTriggerOption?.id !== EBTTriggerTypeOptionsId.Dwell
              ? undefined
              : isNaN(Number(triggerValue))
              ? 0
              : Number(triggerValue),
          id,
        },
      ];
    },
    [
      selectedCrossingDirection,
      selectedEBT,
      selectedTriggerOption,
      selectedTriggerSubType,
      selectedTriggerType,
      triggerValue,
    ],
  );

  const createEmail = useCallback(
    async (id: string) => {
      await axios.post(
        `${notifierUrl}/${cid}/${pid}/email`,
        {
          text: emailData?.message,
          subject: emailData?.subject,
          recipients: [emailData?.address],
          listeners: createListeners(id),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, emailData, notifierUrl, pid],
  );

  const updateEmail = useCallback(
    async (emailId: string, triggerId: string) => {
      await axios.patch(
        `${notifierUrl}/${cid}/${pid}/email/${emailId}`,
        {
          text: emailData?.message,
          subject: emailData?.subject,
          recipients: [emailData?.address],
          listeners: createListeners(triggerId),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, emailData, notifierUrl, pid],
  );

  const createWebhook = useCallback(
    async (id: string) => {
      await axios.post(
        `${notifierUrl}/${cid}/${pid}/webhook`,
        {
          url: webhookData?.url,
          method: webhookData?.method,
          listeners: createListeners(id),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, webhookData, notifierUrl, pid],
  );

  const updateWebhook = useCallback(
    async (webhookId: string, triggerId: string) => {
      await axios.patch(
        `${notifierUrl}/${cid}/${pid}/webhook/${webhookId}`,
        {
          url: webhookData?.url,
          method: webhookData?.method,
          listeners: createListeners(triggerId),
        },
        authedConfig,
      );
    },
    [authedConfig, cid, createListeners, webhookData, notifierUrl, pid],
  );

  const addDwellTimeToFence = useCallback(
    async (layerId: string | null, id: string, type: GeofenceEntityType) => {
      if (!layerId || !id || !type) return;
      const fence = (
        await axios.get<GridRowData>(
          `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
          authedConfig,
        )
      ).data;

      const geomobyProperties: Record<string, string> = fence.geomobyProperties;
      geomobyProperties['dwellSeconds'] = String(triggerValue);

      await axios.patch(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
        {
          geometry: fence.points,
          properties: {
            ...fence,
            geomobyProperties,
          },
          type: 'Feature',
        },
        authedConfig,
      );
    },
    [authedConfig, cid, triggersUrl, triggerValue, pid],
  );

  const canSave = useCallback(() => {
    const crossingHasDirection =
      selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Crossing
        ? !!selectedCrossingDirection
        : true;
    return (
      selectedTriggerType &&
      selectedTriggerOption &&
      crossingHasDirection &&
      (triggerValue || selectedEBT) &&
      ((emailData?.address && emailData?.subject && emailData?.message) || webhookData?.url) &&
      (eventName ||
        selectedTriggerType?.id === EBTTriggerTypeId.Location ||
        selectedTriggerType?.id === EBTTriggerTypeId.Proximity)
    );
  }, [
    emailData,
    eventName,
    selectedCrossingDirection,
    selectedEBT,
    selectedTriggerOption,
    selectedTriggerType,
    triggerValue,
    webhookData,
  ]);

  const handleSubmit = useCallback(async () => {
    const invalidEmailAddress = emailData ? !validateEmailAddress(emailData?.address ?? '') : false;

    if (!canSave() || invalidEmailAddress) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: invalidEmailAddress
          ? 'Invalid email address.'
          : 'Please complete all the required fields.',
      });
      return;
    }

    try {
      if (
        selectedTriggerType?.id === EBTTriggerTypeId.Battery ||
        selectedTriggerType?.id === EBTTriggerTypeId.Temperature ||
        selectedTriggerType?.id === EBTTriggerTypeId.Spo2
      ) {
        const sensor = {
          sensorType:
            selectedTriggerType?.id === EBTTriggerTypeId.Temperature
              ? 'TEMPERATURE_CELSIUS'
              : selectedTriggerType?.id === EBTTriggerTypeId.Spo2
              ? 'SPO2_PERCENT'
              : selectedTriggerType?.id === EBTTriggerTypeId.Battery
              ? 'BATTERY_PERCENT'
              : undefined,
          name: eventName,
          boundary: Number(triggerValue),
          higherIsEnter: selectedTriggerOption?.id === 'ABOVE',
        };

        if (eventIds) {
          const { id } = (
            await axios.patch(
              `${triggersUrl}/${cid}/${pid}/boundary/${eventIds.listenerId}`,
              sensor,
              authedConfig,
            )
          ).data;
          if (eventIds.emailId) {
            await updateEmail(eventIds.emailId, id);
          } else if (eventIds.webhookId) {
            await updateWebhook(eventIds.webhookId, id);
          }
        } else {
          const { id } = (
            await axios.post(`${triggersUrl}/${cid}/${pid}/boundary`, sensor, authedConfig)
          ).data;
          if (selectedTriggerAction?.id === EBTActionTypeId.Email) {
            await createEmail(id);
          } else if (selectedTriggerAction?.id === EBTActionTypeId.Webhook) {
            await createWebhook(id);
          }
        }
      } else if (selectedEBT) {
        const fenceId = selectedEBT.id;
        if (selectedTriggerAction?.id === EBTActionTypeId.Email && emailData) {
          eventIds?.emailId
            ? await updateEmail(eventIds.emailId, fenceId)
            : await createEmail(fenceId);
        } else if (selectedTriggerAction?.id === EBTActionTypeId.Webhook && webhookData) {
          eventIds?.webhookId
            ? await updateWebhook(eventIds.webhookId, fenceId)
            : await createWebhook(fenceId);
        }

        if (selectedTriggerOption?.id === EBTTriggerTypeOptionsId.Dwell) {
          await addDwellTimeToFence(selectedLayer, selectedEBT.row.id, selectedEBT.row.points.type);
        }
      }

      setRefresh(true);
      setEventIds(null);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (e) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message:
          (e as Error).message ??
          `'Failed ${
            eventIds ? 'updating' : 'creating'
          } ${selectedTriggerAction?.id.toLowerCase()}.`,
      });
    }
  }, [
    addDwellTimeToFence,
    authedConfig,
    canSave,
    cid,
    createEmail,
    createWebhook,
    emailData,
    eventIds,
    eventName,
    pid,
    selectedEBT,
    selectedTriggerAction,
    selectedTriggerOption,
    selectedTriggerType,
    selectedLayer,
    setSaveNotification,
    triggerValue,
    triggersUrl,
    updateEmail,
    updateWebhook,
    webhookData,
  ]);

  useEffect(() => {
    if (!selectedTriggerOption) return;
    getLayers();
  }, [selectedTriggerOption, getLayers]);

  useEffect(() => {
    geofencePageRef.current = 1;
    if (refresh) {
      setSelectedTriggerType(undefined);
    }
    setSelectedTriggerOption(undefined);
    setSelectedTriggerSubType(undefined);
    setSelectedCrossingDirection(undefined);
    setTriggerValue(null);
    setSelectedEBT(undefined);
    setSelectedTableData(undefined);
    setSelectedTriggerAction(undefined);
    setEmailData(null);
    setWebhookData(null);
    setEventName(null);
    setSearchString(undefined);
    searchStringRef.current = undefined;
  }, [refresh]);

  useEffect(() => {
    if (selectedTriggerType?.id === EBTTriggerTypeId.Proximity) {
      paginateMicrofences();
    }
  }, [selectedTriggerType, paginateMicrofences]);

  return (
    <>
      <EBTForm
        availableEBTs={availableEBTs}
        setAvailableEBTs={setAvailableEBTs}
        availableFeatures={availableFeatures}
        setAvailableFeatures={setAvailableFeatures}
        availableFeaturesCount={availableFeaturesCount}
        setAvailableFeaturesCount={setAvailableFeaturesCount}
        availableLayers={availableLayers}
        setAvailableLayers={setAvailableLayers}
        customIsEqual={customIsEqual}
        setCustomIsEqual={setCustomIsEqual}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        emailData={emailData}
        setEmailData={setEmailData}
        eventIds={eventIds}
        setEventIds={setEventIds}
        eventName={eventName}
        setEventName={setEventName}
        hideForm={hideForm}
        setHideForm={setHideForm}
        selectedTriggerOption={selectedTriggerOption}
        setSelectedTriggerOption={setSelectedTriggerOption}
        selectedTriggerSubType={selectedTriggerSubType}
        setSelectedTriggerSubType={setSelectedTriggerSubType}
        selectedCrossingDirection={selectedCrossingDirection}
        setSelectedCrossingDirection={setSelectedCrossingDirection}
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
        triggerValue={triggerValue}
        setTriggerValue={setTriggerValue}
        selectedEBT={selectedEBT}
        setSelectedEBT={setSelectedEBT}
        selectedTriggerAction={selectedTriggerAction}
        setSelectedTriggerAction={setSelectedTriggerAction}
        refresh={refresh}
        searchString={searchString}
        setSearchString={setSearchString}
        setRefresh={setRefresh}
        selectedTriggerType={selectedTriggerType}
        setSelectedTriggerType={setSelectedTriggerType}
        selectedTabledData={selectedTabledData}
        setSelectedTableData={setSelectedTableData}
        webhookData={webhookData}
        setWebhookData={setWebhookData}
        geofencePageRef={geofencePageRef}
        microFencesRef={microFencesRef}
        searchStringRef={searchStringRef}
        canSave={canSave}
        clearFormData={clearFormData}
        handleSubmit={handleSubmit}
        getLayers={getLayers}
        paginateGeofences={paginateGeofences}
        paginateMicrofences={paginateMicrofences}
      />

      <EBTList
        refresh={refresh}
        setRefresh={setRefresh}
        availableEBTs={availableEBTs}
        setAvailableEBTs={setAvailableEBTs}
        geofencePageRef={geofencePageRef}
        setSelectedTriggerType={setSelectedTriggerType}
        setSelectedTriggerOption={setSelectedTriggerOption}
        setSelectedTriggerSubType={setSelectedTriggerSubType}
        setSelectedCrossingDirection={setSelectedCrossingDirection}
        setSelectedLayer={setSelectedLayer}
        setTriggerValue={setTriggerValue}
        setSelectedEBT={setSelectedEBT}
        setSelectedTableData={setSelectedTableData}
        setSelectedTriggerAction={setSelectedTriggerAction}
        setEmailData={setEmailData}
        setWebhookData={setWebhookData}
        setEventName={setEventName}
        setIsEditing={setIsEditing}
        setHideForm={setHideForm}
        eventIds={eventIds}
        setEventIds={setEventIds}
        clearFormData={clearFormData}
      />
    </>
  );
};
export default Events;
