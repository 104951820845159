import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { SearchTypeIDs } from '../../../../../util/enums';
import {
  displayAssetIsActive,
  displayAssetLabel,
  findActiveAsset,
  selectedElement,
} from '../../../Helpers';
import { SearchListProps } from '../../Props';
import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS } from '../../../../../store/tools';
import { Asset } from '../../../types';

export const useBeaconList = (
  props: SearchListProps & { availableBeacons: Asset[]; isLoading: boolean },
) => {
  const knownTools = useAtomValue(KNOWN_TOOLS);

  return (
    <>
      {!selectedElement(props) &&
        !props.isLoading &&
        props.searchType?.id === SearchTypeIDs.Beacons &&
        props.availableBeacons.map((beacon, index) => {
          return (
            <Accordion id={String(beacon.id)} key={index} expanded={false} style={{ width: '98%' }}>
              <AccordionSummary
                onClick={() => {
                  const activeBeacon = findActiveAsset(beacon.id, props.activeAssets);
                  const foundTool = knownTools.find(t => t.id === beacon.id);
                  if (activeBeacon) {
                    if (foundTool) {
                      props.setSelectedAsset({
                        id: activeBeacon.id,
                        label: beacon.label,
                      });
                    } else {
                      props.setSelectedAsset(activeBeacon);
                    }
                  }
                  props.setSelectedBeacon(beacon);
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                <Grid container direction="row">
                  <Grid item>{displayAssetLabel(beacon.label, beacon.id)}</Grid>
                  <Grid
                    item
                    style={{
                      width: 'max-content',
                      marginLeft: 'auto',
                    }}
                  >
                    {findActiveAsset(beacon.id, props.activeAssets) && displayAssetIsActive()}
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
