import { DirectionsRun } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { Header } from '../../../Common/Sidebar';
import { EBTFormProps } from '../Props';
import TriggerType from './TriggerType';
import TriggerOption from './TriggerOption';
import TriggerValue from './TriggerValue';
import EventAction from './EventAction';
import EventActionValue from './EventActionValue';
import TriggerLocationType from './TriggerLocationType';
import { EBTTriggerTypeId } from '../../../util/enums';
import { BACKGROUND } from '../../../Style/GeoMobyBaseTheme';

const EBTForm = (props: EBTFormProps) => {
  return (
    <>
      <Container>
        <Grid
          spacing={3}
          paddingY={2}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: '60px',
            paddingBottom: '10px',
          }}
        >
          {!props.hideForm && (
            <Header icon={<DirectionsRun />}>
              {props.eventIds ? 'Update' : 'Create new'} event
            </Header>
          )}

          {TriggerType(props)}
          {TriggerLocationType(props)}
          {TriggerOption(props)}
          {TriggerValue(props)}
          {EventAction(props)}
          {EventActionValue(props)}

          {!props.hideForm &&
            props.selectedTriggerAction &&
            !(
              props.selectedTriggerType?.id === EBTTriggerTypeId.Location ||
              props.selectedTriggerType?.id === EBTTriggerTypeId.Proximity
            ) && (
              <Paper
                style={{
                  width: 'calc(100% - 10vw)',
                  background: BACKGROUND,
                }}
              >
                <Box p={2} overflow="auto">
                  <Stack direction="column" spacing={2}>
                    <Typography variant="body1">
                      When the
                      <Typography color="primary" component="span" fontWeight="bold">
                        {' ' + props.selectedTriggerType?.id} - {props.selectedTriggerOption?.id}
                      </Typography>{' '}
                      event occurs,
                    </Typography>
                    <Typography>
                      a corresponding
                      <Typography color="primary" component="span" fontWeight="bold">
                        {' '}
                        {' ' + props.selectedTriggerAction?.id}
                      </Typography>{' '}
                      will be fired.
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title={props.eventName}>
                        <TextField
                          onChange={e => props.setEventName(e.target.value)}
                          name="event-name"
                          value={props.eventName ?? ''}
                          placeholder="Name your new event trigger"
                          sx={{
                            '& input': {
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          autoComplete="title"
                          fullWidth
                          autoFocus
                        ></TextField>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Box>
              </Paper>
            )}

          {!props.hideForm && (
            <Grid
              container
              direction="column"
              style={{
                width: 'calc(100% - 10vw)',
                marginTop: '20px',
                display: 'grid',
                gap: '2%',
                gridTemplateColumns: '49% 49%',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={props.handleSubmit}
                disabled={!props.canSave()}
              >
                {props.eventIds ? 'Update' : 'Create'}
              </Button>
              <Button variant="outlined" size="large" onClick={() => props.clearFormData()}>
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>

      <Grid
        container
        direction="column"
        style={{
          alignContent: 'end',
          paddingRight: 'calc(100% - 72vw)',
          marginBottom: '40px',
        }}
      >
        {!props.hideForm && (props.triggerValue || props.selectedEBT) && (
          <Tooltip title={'Hide form'}>
            <Button
              onClick={() => {
                props.setHideForm(true);
                window.document.body.scrollTop = document.documentElement.scrollTop = 0;
              }}
              startIcon={<ExpandLessIcon />}
              sx={{
                '& span': {
                  margin: '0px',
                },
              }}
            ></Button>
          </Tooltip>
        )}

        {props.hideForm && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => props.setHideForm(false)}
            style={{
              width: '50%',
              right: '5%',
              marginTop: '3%',
              marginBottom: '3%',
            }}
          >
            Finish {props.eventIds ? 'updating' : 'creating'} event
          </Button>
        )}
      </Grid>
    </>
  );
};
export default EBTForm;
