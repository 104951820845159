import { Button, Grid, Paper } from '@mui/material';
import { WHITE } from '../../../../../Style/GeoMobyBaseTheme';
import { EntityType, SearchTypeIDs, SearchTypeValue } from '../../../../../util/enums';
import { displayAssetActivityStatus, displayAssetHeading } from '../../../Helpers';
import { SearchListProps } from '../../Props';

export const useSelectedDevice = (props: SearchListProps) => {
  return (
    <>
      {props.selectedDevice && !props.selectedMicrofence && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          {displayAssetHeading(
            props.selectedDevice.label,
            props.selectedDevice.id,
            EntityType.Device,
          )}
          <Grid
            container
            direction="column"
            style={{
              marginBottom: '10px',
              width: 'fit-content',
            }}
          >
            <Button
              style={{
                marginLeft: '-7px',
                color: WHITE,
              }}
              onClick={() => {
                props.setSearchType({ id: SearchTypeIDs.Devices, value: SearchTypeValue.Devices });
                props.setSelectedDevice(undefined);
                props.setSelectedAsset(undefined);
                props.setRefreshSearch(true);
              }}
            >
              Devices
            </Button>
          </Grid>

          <Grid container direction={'row'} justifyContent={'center'}>
            {
              <>
                {displayAssetActivityStatus(
                  props.selectedAsset?.id?.deviceId === props.selectedDevice.id,
                )}
              </>
            }
          </Grid>
        </Paper>
      )}
    </>
  );
};
