import { GridRowData } from '@material-ui/data-grid';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { keyBy } from 'lodash';
import { useMemo, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { CID, PID } from '../../../store/user';
import { BACKGROUND_OFFSET } from '../../../Style/GeoMobyBaseTheme';
import {
  CrossingTypeId,
  EBTActionTypeId,
  EBTActionTypeValue,
  EBTListenerTypeId,
  EBTTriggerSubTypeId,
  EBTTriggerSubTypeValue,
  EBTTriggerTypeOptionsId,
  EBTTriggerTypeOptionsValue,
  FenceZone,
  GeofenceEntityType,
  Method,
} from '../../../util/enums';
import { useMobile } from '../../../util/useMobile';
import { MICROFENCE_LAYER_ID } from '../../Map/BeaconUtils';
import { getEventTileIcon, getEventTitle, getListeners, getTypeTitle } from '../Helpers';
import { EBTListProps, EmailOrWebhookListProps, ListenersProps } from '../Props';
import { EBT } from '../types';
import { TriggerTypes } from '../values';

const WebhookList = (props: EBTListProps & EmailOrWebhookListProps) => {
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const isMobile = useMobile();

  const [expandRow, setExpandRow] = useState<string | undefined>();
  const boundariesObj = useMemo(() => keyBy(props.boundaries, 'id'), [props.boundaries]);
  const geofencesObj = useMemo(() => keyBy(props.geofences, 'id'), [props.geofences]);
  const microfencesObj = useMemo(() => keyBy(props.microfences, 'id'), [props.microfences]);

  const editWebhook = async (webhook: EBT) => {
    window.document.body.scrollTop = document.documentElement.scrollTop = 0;
    props.setHideForm(false);
    props.setIsEditing(true);

    const foundBoundary = boundariesObj[webhook.listenerId];
    const foundGeofence = geofencesObj[webhook.listenerId];
    const foundMicrofence = microfencesObj[webhook.listenerId];

    // Currently know way of singling out the correct page that the fence is associated with. I've created a ticket LTP-725.
    props.geofencePageRef.current = 1;
    props.setEventIds({
      webhookId: webhook.id,
      listenerId: webhook.listenerId,
    });
    const selectedTriggerValue = getTypeTitle(
      webhook.listenerType,
      boundariesObj[webhook.listenerId]?.type,
      !!geofencesObj[webhook.listenerId],
      !!microfencesObj[webhook.listenerId],
    );
    const selectedTriggerType = TriggerTypes.find(t => selectedTriggerValue === t.value);
    props.setSelectedTriggerType(selectedTriggerType);

    if (foundGeofence?.type === GeofenceEntityType.Line) {
      props.setSelectedTriggerOption({
        id: EBTTriggerTypeOptionsId.Crossing,
        value: EBTTriggerTypeOptionsValue.Crossing,
      });
      props.setSelectedCrossingDirection(
        webhook.entered ? CrossingTypeId.Left : CrossingTypeId.Right,
      );
    } else {
      const selectedTriggerOptionValue = getEventTitle(
        webhook,
        boundariesObj[webhook.listenerId]?.higherIsEnter,
        geofencesObj[webhook.listenerId]?.type,
      );
      props.setSelectedTriggerOption(
        selectedTriggerType?.options.find(o => selectedTriggerOptionValue === o.value),
      );
      props.setSelectedCrossingDirection(undefined);

      if (webhook.listenerType === EBTListenerTypeId.Geofence && webhook.dwellSeconds) {
        props.setTriggerValue(String(webhook.dwellSeconds));
      }
      if (
        webhook.listenerType !== EBTListenerTypeId.Geofence &&
        webhook.listenerType !== EBTListenerTypeId.Microfence
      ) {
        props.setEventName(foundBoundary?.name);
        props.setTriggerValue(String(foundBoundary?.boundary));
      }
    }

    const layerId = foundGeofence?.layerId ?? (foundMicrofence ? MICROFENCE_LAYER_ID : '');
    props.setSelectedLayer(layerId);

    if (foundGeofence) {
      const { data: geofence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${foundGeofence?.type}/${foundGeofence.id}`,
        authedConfig,
      );

      geofence.zone === FenceZone.cleared || foundGeofence?.type === GeofenceEntityType.Multipolygon
        ? props.setSelectedTriggerSubType({
            id: EBTTriggerSubTypeId.ClearedZone,
            value: EBTTriggerSubTypeValue.ClearedZone,
          })
        : props.setSelectedTriggerSubType({
            id: EBTTriggerSubTypeId.Geofence,
            value: EBTTriggerSubTypeValue.Geofence,
          });
      props.setSelectedEBT({ id: geofence.id, row: geofence });
    } else if (foundMicrofence) {
      const { data: microfence } = await axios.get<GridRowData>(
        `${triggersUrl}/${cid}/${pid}/microfences/${foundMicrofence.id}`,
        authedConfig,
      );
      props.setSelectedEBT({ id: microfence.id, row: microfence });
    }

    props.setSelectedTableData([webhook.listenerId]);
    props.setSelectedTriggerAction({
      id: EBTActionTypeId.Webhook,
      value: EBTActionTypeValue.Webhook,
    });
    props.setWebhookData({
      method: (webhook.method as Method) ?? 'GET',
      url: webhook.url,
    });
  };

  return (
    <>
      {props.availableEBTs
        .filter(e => e.notifierType === EBTActionTypeId.Webhook)
        ?.map(webhook => (
          <Box
            p={2}
            key={webhook.id}
            style={{
              width: '100%',
              height: expandRow === webhook.id ? 'auto' : '200px',
              paddingBottom: '1px',
            }}
          >
            <Paper
              elevation={4}
              style={{
                height: 'auto',
                overflow: 'hidden',
                background:
                  webhook.id === props.eventIds?.webhookId ? '#1E3748' : BACKGROUND_OFFSET,
              }}
            >
              <Stack
                p={4}
                direction="column"
                spacing={1}
                style={{
                  overflowX: 'hidden',
                  paddingRight: '50px',
                  paddingBottom: isMobile ? '4px' : '20px',
                }}
              >
                <Grid
                  container
                  direction="column"
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: isMobile ? '50% 60%' : '9% 31% 53% 1%',
                    width: '100%',
                  }}
                >
                  {!isMobile && (
                    <Grid item>
                      {getEventTileIcon(
                        `${getTypeTitle(
                          webhook.listenerType,
                          boundariesObj[webhook.listenerId]?.type,
                          !!geofencesObj[webhook.listenerId],
                          !!microfencesObj[webhook.listenerId],
                        ).toUpperCase()}_
                        ${getEventTitle(
                          webhook,
                          boundariesObj[webhook.listenerId]?.higherIsEnter,
                          geofencesObj[webhook.listenerId]?.type,
                        ).toUpperCase()}`,
                        !!isMobile,
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    style={{
                      overflowX: 'auto',
                      maxWidth: isMobile ? '90%' : 'auto',
                      height: expandRow === webhook.id ? 'auto' : isMobile ? '90px' : '135px',
                    }}
                  >
                    <Box>
                      <Tooltip title={webhook.method}>
                        <Typography variant="h6">{webhook.method}</Typography>
                      </Tooltip>
                    </Box>
                    <Box>
                      <Tooltip title={webhook.url}>
                        <Typography>{webhook.url}</Typography>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    style={{
                      overflowY: expandRow === webhook.id ? 'hidden' : 'auto',
                      height: expandRow === webhook.id ? 'auto' : isMobile ? '90px' : '135px',
                    }}
                  >
                    {getListeners({
                      ...props,
                      ebt: webhook,
                      boundariesObj,
                      geofencesObj,
                      microfencesObj,
                    } as unknown as ListenersProps)}
                  </Grid>

                  {!isMobile && (
                    <Grid
                      item
                      container
                      direction="row"
                      spacing={2}
                      style={{
                        height: '100px',
                        marginTop: 'auto',
                      }}
                    >
                      <Grid item>
                        <Tooltip title={props.isLoading ? '' : 'Edit'}>
                          <Button
                            size="small"
                            disabled={props.isLoading}
                            onClick={() => {
                              props.clearFormData();
                              editWebhook(webhook);
                            }}
                          >
                            <Edit />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip title={props.isLoading ? '' : 'Delete'}>
                          <Button
                            size="small"
                            disabled={props.isLoading}
                            onClick={() => {
                              props.setDeleting({ id: webhook?.id, type: EBTActionTypeId.Webhook });
                            }}
                          >
                            <Delete />
                          </Button>
                        </Tooltip>
                      </Grid>

                      {/*At the moment we don't have multiple listeners and the screen is big enough so no need to have this feature on a desktop*/}
                      {/*  <Grid item>*/}
                      {/*    {*/}
                      {/*      <Tooltip title={expandRow !== webhook.id ? 'Show more' : 'Show less'}>*/}
                      {/*        <Button*/}
                      {/*          size="small"*/}
                      {/*          onClick={() =>*/}
                      {/*            setExpandRow(expandRow !== webhook.id ? webhook.id : undefined)*/}
                      {/*          }*/}
                      {/*        >*/}
                      {/*          <MoreVert />*/}
                      {/*        </Button>*/}
                      {/*      </Tooltip>*/}
                      {/*    }*/}
                      {/*  </Grid>*/}
                    </Grid>
                  )}
                </Grid>

                {isMobile && (
                  <Grid
                    item
                    container
                    direction="column"
                    style={{
                      height: '40px',
                      display: 'grid',
                      gridTemplateColumns: '68% 13% 13% 13%',
                      width: '100%',
                    }}
                  >
                    <Grid item>
                      {getEventTileIcon(
                        `${getTypeTitle(
                          webhook.listenerType,
                          boundariesObj[webhook.listenerId]?.type,
                          !!geofencesObj[webhook.listenerId],
                          !!microfencesObj[webhook.listenerId],
                        ).toUpperCase()}_
                        ${getEventTitle(
                          webhook,
                          boundariesObj[webhook.listenerId]?.higherIsEnter,
                          geofencesObj[webhook.listenerId]?.type,
                        ).toUpperCase()}`,
                        !!isMobile,
                      )}
                    </Grid>

                    <Grid item>
                      <Tooltip title={props.isLoading ? '' : 'Edit'}>
                        <Button
                          size="small"
                          disabled={props.isLoading}
                          onClick={() => {
                            props.clearFormData();
                            editWebhook(webhook);
                          }}
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title={props.isLoading ? '' : 'Delete'}>
                        <Button
                          size="small"
                          disabled={props.isLoading}
                          onClick={() => {
                            props.setDeleting({ id: webhook?.id, type: EBTActionTypeId.Webhook });
                          }}
                        >
                          <Delete />
                        </Button>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      {
                        <Tooltip title={expandRow !== webhook.id ? 'Show more' : 'Show less'}>
                          <Button
                            size="small"
                            onClick={() =>
                              setExpandRow(expandRow !== webhook.id ? webhook.id : undefined)
                            }
                          >
                            <MoreVert />
                          </Button>
                        </Tooltip>
                      }
                    </Grid>
                  </Grid>
                )}
              </Stack>
            </Paper>
          </Box>
        ))}
    </>
  );
};
export default WebhookList;
