export const M_TO_FT = 3.28084;
export const M_TO_KM = 0.001;
export const M_TO_MI = 0.000621371;

export const initialLatitude = -31.953512;
export const initialLongitude = 115.857048;
export const initialExtentInDegrees = 0.512;
export const initialZoomHeight = 11;

export const ACTIVE_MICROFENCE = 'ACTIVE_MICROFENCE';
export const ALL_LAYERS = 'all-layers';
export const ALL_GROUPS = 'All Groups';
export const ASSET_LABEL = 'ASSET_LABEL';
export const ASSET_ID = 'ASSET_ID';
export const DEFAULT_BUFFER_METERS = 250;
export const EBT_DISPLAY_LIMIT = 5;
export const FRESH = 'fresh';
export const FRESH_LAYER = 'fresh-layer';
export const LAST_SENSED_ISO8601 = 'LAST_SENSED_ISO8601';
export const LOCALISED_SUFFIX = '[localised]';
export const LOCAL_BEACONS = 'LOCAL_BEACONS';
export const LOCATION_HISTORY = 'LOCATION_HISTORY';
export const SENSED_ASSETS = 'SENSED_ASSETS';
export const SENSED_EXITED_ASSETS_IDS = 'SENSED_EXITED_ASSETS';
export const TRACKED_DEVICE = 'Tracked device';
export const TRACKING_BOUNDS = 'tracking_bounds';
export const UNKNOWN_LAYER = 'unknown-layer';
