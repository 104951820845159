import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useRef } from 'react';
import {
  GeofenceFilter,
  SearchType,
  GeomobyOverride,
  FenceZoneTypeValue,
  FenceZoneType,
  IBeacon,
  MicrofenceZoneTypeValue,
  MicrofenceAssetId,
} from '../../../types';
import { Geometry } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import { GeomobyProperties, PropertiesTable } from '../Geofence/GeomobyProperties';
import { GeomobyOverrides } from '../Geofence/GeomobyOverrides';
import {
  EntityType,
  FenceGeometryType,
  MicrofenceZone,
  RequestType,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import { Delete, Edit, NearMe } from '@mui/icons-material';
import { FenceZoneTypes, MicrofenceZoneTypes } from '../../../values';
import { MICROFENCE_LAYER_ID, MICROFENCE_LAYER_LABEL } from '../../../BeaconUtils';
import { debounce } from 'lodash';
import InputContainer from '../../../../Global/InputContainer';
import { SearchListProps, SelectedMicrofenceProps } from '../../Props';
import { displayAssetId, getMicroFenceIcon } from '../../../Helpers';
import { lineLimitStyle, PRIMARY, WHITE } from '../../../../../Style/GeoMobyBaseTheme';

export const useDisplayMicrofence = (props: SelectedMicrofenceProps & SearchListProps) => {
  return (
    <>
      {!props.createEditLayer && !props.createEditFence && props.selectedMicrofence && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          <Box
            style={{
              marginTop: '5px',
            }}
            color="primary.main"
          >
            {getMicroFenceIcon(props.selectedMicrofence.microfenceType)}
          </Box>

          <Grid
            container
            direction="row"
            style={{
              width: '100%',
              marginTop: '-44px',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip title={'NAVIGATE TO'}>
              <IconButton
                color="primary"
                onClick={() => props.setNavigateTo(props.selectedMicrofence?.id)}
              >
                <NearMe />
              </IconButton>
            </Tooltip>

            <Tooltip title={'EDIT'}>
              <IconButton
                color="primary"
                onClick={() => {
                  if (!props.selectedMicrofence) return;
                  if (!props.selectedLayer) {
                    props.resetlayer();
                  }
                  props.microfenceSort();
                  props.setFenceNameInput(props.selectedMicrofence.name);
                  const assetId = props.selectedMicrofence.assetId;
                  if (props.selectedMicrofence.microfenceType === 'beacon') {
                    props.setMicrofenceIBeaconIdentifier({
                      uuid: String(assetId?.uuid),
                      major: Number(assetId?.major),
                      minor: Number(assetId?.minor),
                    });
                  } else {
                    props.setMicrofenceIdInput(
                      assetId?.deviceId ?? assetId.gatewayId ?? assetId?.smartplugId,
                    );
                  }
                  props.setCreateEditFence(RequestType.Edit);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>

            <Tooltip title={'DELETE'}>
              <IconButton
                color="primary"
                onClick={() => {
                  if (!props.selectedLayer) {
                    props.resetlayer();
                  }
                  props.setDeleting(EntityType.Microfence);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid
            container
            direction="row"
            spacing={1}
            marginLeft={0}
            justifyContent="left"
            alignItems="center"
          >
            <Tooltip
              title={props.selectedMicrofence.name}
              style={{
                ...lineLimitStyle,
                marginTop: '5px',
              }}
            >
              <Typography variant="h5">{props.selectedMicrofence.name}</Typography>
            </Tooltip>
          </Grid>

          <Grid
            container
            direction="column"
            style={{
              marginBottom: '10px',
              width: '70px',
            }}
          >
            <Button
              style={{
                marginLeft: '-7px',
                color: WHITE,
              }}
              onClick={() => {
                props.setSearchType({
                  id: SearchTypeIDs.Microfences,
                  value: SearchTypeValue.Microfences,
                });
                props.setSelectedMicrofence(undefined);
                props.setGeofenceFilter(undefined);
                props.pageRef.current = 1;
                props.resetlayer();
                props.deselectAllFences();
              }}
            >
              {MICROFENCE_LAYER_LABEL}
            </Button>
          </Grid>

          <Grid container direction={'row'} justifyContent={'center'}>
            {props.selectedMicrofence.microfenceType === 'beacon' && (
              <>
                <Grid container direction={'row'}>
                  <Tooltip title={'UUID'}>
                    <Typography
                      style={{
                        color: PRIMARY,
                        paddingRight: '5px',
                        width: 'fit-content',
                      }}
                    >
                      {`UUID: `}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (
                        props.selectedMicrofence.assetId as {
                          uuid: string;
                          major: string;
                          minor: string;
                        }
                      )?.uuid
                    }
                  >
                    <Typography>
                      {
                        (
                          props.selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        )?.uuid
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              </>
            )}
            {(props.selectedMicrofence.microfenceType === 'gateway' ||
              props.selectedMicrofence.microfenceType === 'smartplug') && (
              <>
                {displayAssetId(
                  (props.selectedMicrofence.assetId as { gatewayId: string }).gatewayId ||
                    (props.selectedMicrofence.assetId as { smartplugId: string }).smartplugId,
                  EntityType.Gateway,
                )}
              </>
            )}
            {props.selectedMicrofence.microfenceType === 'device' && (
              <>
                {displayAssetId(
                  (props.selectedMicrofence.assetId as { deviceId: string }).deviceId,
                  EntityType.Device,
                )}
              </>
            )}

            {props.selectedMicrofence.microfenceType === 'beacon' && (
              <Grid item container direction={'row'} xs={12} fontSize="90%">
                <Tooltip title={'Major'}>
                  <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>Major:</Typography>
                </Tooltip>
                <Tooltip
                  title={
                    (
                      props.selectedMicrofence.assetId as {
                        uuid: string;
                        major: string;
                        minor: string;
                      }
                    )?.major
                  }
                >
                  <Typography>
                    {
                      (
                        props.selectedMicrofence.assetId as {
                          uuid: string;
                          major: string;
                          minor: string;
                        }
                      )?.major
                    }
                  </Typography>
                </Tooltip>
              </Grid>
            )}

            {props.selectedMicrofence.microfenceType === 'beacon' && (
              <Grid item container direction={'row'} xs={12} fontSize="90%">
                <Tooltip title={'Minor'}>
                  <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>Minor:</Typography>
                </Tooltip>
                <Tooltip
                  title={
                    (
                      props.selectedMicrofence.assetId as {
                        uuid: string;
                        major: string;
                        minor: string;
                      }
                    )?.minor
                  }
                >
                  <Typography>
                    {
                      (
                        props.selectedMicrofence.assetId as {
                          uuid: string;
                          major: string;
                          minor: string;
                        }
                      )?.minor
                    }
                  </Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Grid item container direction={'row'} xs={12} fontSize="90%">
            <Tooltip title={'Zone'}>
              <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>Zone:</Typography>
            </Tooltip>
            <Tooltip
              title={
                MicrofenceZoneTypes.find(
                  z => z.id === (props.selectedMicrofence?.zone as MicrofenceZone),
                )?.value ?? 'No Zone'
              }
            >
              <Typography>
                {MicrofenceZoneTypes.find(
                  z => z.id === (props.selectedMicrofence?.zone as MicrofenceZone),
                )?.value ?? 'No Zone'}
              </Typography>
            </Tooltip>
          </Grid>

          {props.displayGeomobyProperties && (
            <>
              <Tooltip title={'Microfence Properties'}>
                <Typography style={{ color: PRIMARY }}>Microfence Properties</Typography>
              </Tooltip>
              <PropertiesTable rows={props.displayGeomobyProperties}></PropertiesTable>
            </>
          )}
        </Paper>
      )}
    </>
  );
};

export const useEditMicrofence = (props: SelectedMicrofenceProps & SearchListProps) => {
  const fenceNameRef = useRef<HTMLInputElement>(null);
  const microfenceIdRef = useRef<HTMLInputElement>(null);
  const microfenceUUIDRef = useRef<HTMLInputElement>(null);
  const microfenceMajorRef = useRef<HTMLInputElement>(null);
  const microfenceMinorRef = useRef<HTMLInputElement>(null);

  const debounceUpdateMicrofence = useRef(
    debounce(
      async (
        id: string,
        name: string,
        microfenceZone: MicrofenceZone | undefined,
        assetId: MicrofenceAssetId | undefined,
      ) => {
        await props.updateFenceIdentifiers(
          id,
          name,
          FenceGeometryType.Microfence,
          MICROFENCE_LAYER_ID,
          undefined,
          assetId,
          microfenceZone,
        );
      },
      500,
      { leading: true },
    ),
  ).current;

  const updateAssetId = async (value: string) => {
    props.setMicrofenceIdInput(value);
    let assetId;
    if (props.selectedMicrofence?.assetId?.deviceId !== undefined) {
      assetId = {
        deviceId: value,
      };
    } else if (
      props.selectedMicrofence?.assetId?.gatewayId !== undefined ||
      props.selectedMicrofence?.assetId?.smartplugId !== undefined
    ) {
      assetId = {
        gatewayId: value,
      };
    }

    props.setSelectedMicrofence({
      ...props.selectedMicrofence,
      assetId,
    });
    debounceUpdateMicrofence(
      props.selectedMicrofence?.id,
      props.selectedMicrofence?.name,
      props.selectedMicrofence?.zone,
      assetId,
    );
  };
  return (
    <>
      {/*Create/Edit Microfence*/}
      {props.createEditFence && props.selectedMicrofence && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          <Grid>
            {/* Name */}
            <FormControl
              style={{
                width: '100%',
                marginBottom: '15px',
                marginTop: '10px',
              }}
            >
              <InputLabel id="rename">Name</InputLabel>
              <OutlinedInput
                id="rename"
                label="Name"
                key={'rename'}
                name={'rename'}
                inputRef={fenceNameRef}
                placeholder="Geofence 1"
                value={props.fenceNameInput}
                onChange={async ({ target: { value } }) => {
                  if (!props.selectedMicrofence) return;
                  props.setFenceNameInput(value);
                  props.setSelectedMicrofence({
                    ...props.selectedMicrofence,
                    name: value,
                  });
                  debounceUpdateMicrofence(
                    props.selectedMicrofence.id,
                    value,
                    props.selectedMicrofence.zone,
                    props.selectedMicrofence.assetId,
                  );
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    props.saveAllChanges();
                  }
                }}
              />
            </FormControl>

            {/* New Microfence gateway ID, Device ID */}
            {props.selectedMicrofence.microfenceType !== 'beacon' && (
              <FormControl
                style={{
                  width: '75.5%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="microfence-label">
                  {props.selectedMicrofence.microfenceType === 'gateway'
                    ? 'Gateway ID'
                    : 'Device ID'}
                </InputLabel>
                <OutlinedInput
                  id="microfence-id"
                  label={
                    props.selectedMicrofence.microfenceType === 'gateway'
                      ? 'Gateway ID'
                      : 'Device ID'
                  }
                  key={'microfence'}
                  name={'microfence'}
                  inputRef={microfenceIdRef}
                  placeholder={
                    props.selectedMicrofence.microfenceType === 'gateway'
                      ? 'Gateway ID'
                      : 'Device ID'
                  }
                  value={props.microfenceIdInput}
                  onChange={async (e: { target: { value: string } }) => {
                    await updateAssetId(e.target.value);
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      props.saveAllChanges();
                    }
                  }}
                />
              </FormControl>
            )}

            {/* New Microfence Beacon UUID */}
            {props.selectedMicrofence.microfenceType === 'beacon' && (
              <FormControl
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="microfence-uuid">UUID</InputLabel>
                <OutlinedInput
                  id="microfence-uuid-id"
                  label={'UUID'}
                  key={'microfence-uuid'}
                  name={'microfence-uuid'}
                  inputRef={microfenceUUIDRef}
                  placeholder=""
                  value={props.microfenceIBeaconIdentifier.uuid}
                  onChange={async ({ target: { value } }) => {
                    props.setMicrofenceIBeaconIdentifier({
                      ...props.microfenceIBeaconIdentifier,
                      uuid: value,
                    });
                    const assetId = {
                      uuid: value,
                      major: String(props.microfenceIBeaconIdentifier.major),
                      minor: String(props.microfenceIBeaconIdentifier.minor),
                    };

                    props.setSelectedMicrofence({
                      ...props.selectedMicrofence,
                      assetId,
                    });
                    debounceUpdateMicrofence(
                      props.selectedMicrofence?.id,
                      props.selectedMicrofence?.name,
                      props.selectedMicrofence?.zone,
                      assetId,
                    );
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      props.saveAllChanges();
                    }
                  }}
                />
              </FormControl>
            )}

            {props.selectedMicrofence.microfenceType === 'beacon' && (
              <Grid
                container
                direction="row"
                style={{
                  marginTop: '10px',
                  width: '100%',
                  display: 'grid',
                  gap: '2%',
                  gridTemplateColumns: '49% 49%',
                }}
              >
                {/* Major */}
                <FormControl
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  <InputLabel id="major-label">Major</InputLabel>
                  <OutlinedInput
                    id="major-id"
                    label={'Major'}
                    key={'major'}
                    name={'major'}
                    type={'number'}
                    inputRef={microfenceMajorRef}
                    value={props.microfenceIBeaconIdentifier.major}
                    onChange={async ({ target: { value } }) => {
                      const updatedMajor = Number(value) < 0 ? 0 : Number(value);
                      props.setMicrofenceIBeaconIdentifier({
                        ...props.microfenceIBeaconIdentifier,
                        major: updatedMajor,
                      });
                      const assetId = {
                        uuid: props.microfenceIBeaconIdentifier.uuid,
                        major: String(updatedMajor),
                        minor: String(props.microfenceIBeaconIdentifier.minor),
                      };

                      props.setSelectedMicrofence({
                        ...props.selectedMicrofence,
                        assetId,
                      });
                      debounceUpdateMicrofence(
                        props.selectedMicrofence?.id,
                        props.selectedMicrofence?.name,
                        props.selectedMicrofence?.zone,
                        assetId,
                      );
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        props.saveAllChanges();
                      }
                    }}
                  />
                </FormControl>

                {/* Minor */}
                <FormControl
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  <InputLabel id="minor-label">Minor</InputLabel>
                  <OutlinedInput
                    id="minor-id"
                    label={'Minor'}
                    key={'minor'}
                    name={'minor'}
                    type={'number'}
                    inputRef={microfenceMinorRef}
                    value={props.microfenceIBeaconIdentifier.minor}
                    onChange={async ({ target: { value } }) => {
                      const updatedMinor = Number(value) < 0 ? 0 : Number(value);
                      props.setMicrofenceIBeaconIdentifier({
                        ...props.microfenceIBeaconIdentifier,
                        minor: updatedMinor,
                      });
                      const assetId = {
                        uuid: props.microfenceIBeaconIdentifier.uuid,
                        major: String(props.microfenceIBeaconIdentifier.major),
                        minor: String(updatedMinor),
                      };

                      props.setSelectedMicrofence({
                        ...props.selectedMicrofence,
                        assetId,
                      });
                      debounceUpdateMicrofence(
                        props.selectedMicrofence?.id,
                        props.selectedMicrofence?.name,
                        props.selectedMicrofence?.zone,
                        assetId,
                      );
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        props.saveAllChanges();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          {/* Microfence Zone */}
          <FormControl
            style={{
              marginTop: '10px',
              width: '100%',
              marginBottom: '15px',
            }}
          >
            <InputLabel id="zone-type-option">Zone</InputLabel>
            <Select
              fullWidth
              labelId="zone-type-action"
              id="zone-type-dropdown"
              value={
                MicrofenceZoneTypes.find(z => String(z.id) === props.selectedMicrofence?.zone)
                  ?.value ?? 'No Zone'
              }
              label="Zone"
              style={{
                height: '60px',
              }}
              onChange={async e => {
                if (!props.selectedMicrofence) return;
                const zoneType = MicrofenceZoneTypes.find(
                  z => z.value === (e.target.value as MicrofenceZoneTypeValue),
                );
                if (!zoneType) return;
                const zone = zoneType.id as MicrofenceZone;
                props.setMicrofenceZoneInput(zone);
                props.setSelectedMicrofence({
                  ...props.selectedMicrofence,
                  zone,
                });
                debounceUpdateMicrofence(
                  props.selectedMicrofence.id,
                  props.selectedMicrofence.name,
                  zone,
                  props.selectedMicrofence.assetId,
                );
              }}
            >
              {MicrofenceZoneTypes.map(zone => (
                <MenuItem key={zone.id} value={zone.value}>
                  <Tooltip title={zone.value}>
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 50px)',
                        '& .MuiSvgIcon-root': {
                          marginBottom: '-7px',
                        },
                      }}
                    >
                      {zone.value}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Microfence Properties */}
          {props.displayGeomobyProperties && (
            <Grid
              style={{
                marginTop: '10px',
              }}
            >
              <Tooltip title={'Microfence Properties'}>
                <Typography style={{ color: PRIMARY }}>Microfence Properties</Typography>
              </Tooltip>

              <Grid
                container
                style={{
                  display: 'grid',
                  gap: '2%',
                  gridTemplateColumns: '49% 49%',
                }}
              >
                <Tooltip title={'Property'}>
                  <Typography>Property</Typography>
                </Tooltip>
                <Tooltip title={'Value'}>
                  <Typography>Value</Typography>
                </Tooltip>
              </Grid>

              {props.displayGeomobyProperties
                .sort((a, b) => {
                  return String(a.index).localeCompare(String(b.index));
                })
                .map(prop => {
                  return (
                    <Grid
                      container
                      key={prop.index}
                      sx={{
                        display: 'grid',
                        gap: '2%',
                        gridTemplateColumns: '45% 45% 5%',
                        '& .MuiInputBase-root': {
                          height: '35px',
                        },
                      }}
                    >
                      <FormControl>
                        <InputContainer
                          id={'prop-property-' + prop.index}
                          label=""
                          key={'prop-property-' + prop.index}
                          name={'prop-property' + prop.index}
                          value={prop.property}
                          disableInput={true}
                          onChange={(e: { target: { value: string } }) => {
                            props.setDisplayGeomobyProperties([
                              ...props.displayGeomobyProperties.filter(
                                displayed => displayed.index !== prop.index,
                              ),
                              {
                                index: prop.index,
                                property: e.target.value,
                                value: prop.value,
                              },
                            ]);
                          }}
                          placeholder=""
                        />
                      </FormControl>

                      <FormControl>
                        <InputContainer
                          id={'prop-value-' + prop.index}
                          label=""
                          key={'prop-value-' + prop.index}
                          name={'prop-value-' + prop.index}
                          type={'number'}
                          value={prop.value}
                          onChange={async (e: { target: { value: string } }) => {
                            const updatedProperties: Record<string, string> = {};
                            const updatedDisplayGeomobyProperties = [
                              ...props.displayGeomobyProperties.filter(
                                displayed => displayed.index !== prop.index,
                              ),
                              {
                                index: prop.index,
                                property: prop.property,
                                value: e.target.value,
                              },
                            ];
                            updatedDisplayGeomobyProperties.map(prop => {
                              updatedProperties[prop.property] = prop.value;
                            });
                            await props.updateGeomobyProperties(updatedProperties);
                            props.setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
                          }}
                          onKeyPress={(event: { key: string }) => {
                            if (event.key === 'Enter') {
                              props.saveAllChanges();
                            }
                          }}
                          placeholder=""
                        />
                      </FormControl>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Paper>
      )}
    </>
  );
};

export const useSelectedMicrofence = (props: SelectedMicrofenceProps & SearchListProps) => {
  return (
    <>
      {/* Selected Microfence  */}
      {useDisplayMicrofence(props)}
      {/*/!* Create/Edit Microfence *!/*/}
      {useEditMicrofence(props)}
    </>
  );
};
