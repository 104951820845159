import { FilterList, Search, Sort } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Header } from '../../Common/Sidebar';
import {
  EBTActionTypeId,
  EBTActionTypeValue,
  EBTListenerTypeId,
  EBTListenerTypeValue,
  EBTOrderByTypeValue,
  EnteredTypeValue,
  GeofenceEntityType,
  Method,
  OrderTypeValue,
} from '../../util/enums';
import InputContainer from '../Global/InputContainer';
import { EBTDialogProps, EBTListProps } from './Props';
import { EBT, EBTFilter } from './types';
import {
  ActionTypes,
  EnteredTypes,
  GeofenceTypes,
  ListenerTypes,
  OrderByTypes,
  OrderTypes,
} from './values';

export const EBTFilterDialog = (props: EBTListProps & EBTDialogProps) => {
  return (
    <>
      {/*Filter*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 0px 5px 25px',
        }}
      >
        <Header icon={<FilterList />}>Filters</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Notification Type*/}
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="notifier-type-option">Notification Type</InputLabel>
          <Select
            fullWidth
            labelId="notifier-type-action"
            id="notifier-type-dropdown"
            value={props.ebtFilter?.notifierType?.value ?? ''}
            label="Notification Type"
            onChange={e => {
              const notifierType = ActionTypes.find(
                l => l.value === (e.target.value as EBTActionTypeValue),
              );
              props.setEbtFilter({
                ...props.ebtFilter,
                notifierType,
              });
            }}
          >
            {ActionTypes.map(action => (
              <MenuItem key={action.id} value={action.value} disabled={action.disabled}>
                <Tooltip title={action.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {action.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Event Trigger Type*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="listener-type-option">Event Trigger Type</InputLabel>
          <Select
            fullWidth
            labelId="listener-type-action"
            id="listener-type-dropdown"
            value={props.ebtFilter?.listenerType?.value ?? ''}
            label="Event Trigger Type"
            onChange={e => {
              const listenerType = ListenerTypes.find(
                l => l.value === (e.target.value as EBTListenerTypeValue),
              );
              props.setEbtFilter({
                ...props.ebtFilter,
                listenerType,
                entered: undefined,
              });
            }}
          >
            {ListenerTypes.map(listener => (
              <MenuItem key={listener.id} value={listener.value}>
                <Tooltip title={listener.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {listener.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Geofence Type*/}
        {props.ebtFilter?.listenerType?.id === EBTListenerTypeId.Geofence && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputLabel id="geofence-type-option">Geofence Type</InputLabel>
            <Select
              fullWidth
              labelId="geofence-type-action"
              id="geofence-type-dropdown"
              value={props.ebtFilter?.geofenceType?.value ?? ''}
              label="Geofence Type"
              onChange={e => {
                const geofenceType = GeofenceTypes.find(
                  l => l.value === (e.target.value as GeofenceEntityType),
                );
                props.setEbtFilter({
                  ...props.ebtFilter,
                  geofenceType,
                  entered: undefined,
                });
              }}
            >
              {GeofenceTypes.map(geofenceType => (
                <MenuItem key={geofenceType.id} value={geofenceType.value}>
                  <Tooltip title={geofenceType.value}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {geofenceType.value}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/*Event Trigger Option*/}
        {!!props.ebtFilter?.listenerType &&
          (props.ebtFilter?.listenerType?.id !== EBTListenerTypeId.Geofence ||
            (props.ebtFilter?.listenerType?.id === EBTListenerTypeId.Geofence &&
              !!props.ebtFilter?.geofenceType)) && (
            <FormControl
              fullWidth
              style={{
                marginBottom: 20,
                alignSelf: 'center',
              }}
            >
              <InputLabel id="entered-option">Event Trigger Option</InputLabel>
              <Select
                fullWidth
                labelId="entered-action"
                id="entered-dropdown"
                value={props.enteredState ?? ''}
                label="Event Trigger Option"
                onChange={e => {
                  const enteredType = EnteredTypes.find(
                    enter => enter.value === (e.target.value as EnteredTypeValue),
                  );
                  props.setEnteredState(enteredType?.value);
                  if (enteredType?.value === EnteredTypeValue.Dwell) {
                    props.setDwellTimeMinimum(0);
                    return;
                  }

                  props.setEbtFilter({
                    ...props.ebtFilter,
                    entered: enteredType?.booleanValue,
                  });
                }}
              >
                {props.ebtFilter?.geofenceType?.id === GeofenceEntityType.Line &&
                  EnteredTypes.filter(
                    e =>
                      e.listenerTypeId === props.ebtFilter?.listenerType?.id &&
                      e.geofenceTypeId === props.ebtFilter?.geofenceType?.id,
                  ).map(entered => (
                    <MenuItem key={entered.id} value={entered.value}>
                      <Tooltip title={entered.value}>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                          }}
                        >
                          {entered.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                {props.ebtFilter?.geofenceType?.id !== GeofenceEntityType.Line &&
                  EnteredTypes.filter(
                    e =>
                      e.listenerTypeId === props.ebtFilter?.listenerType?.id && !e.geofenceTypeId,
                  ).map(entered => (
                    <MenuItem key={entered.id} value={entered.value}>
                      <Tooltip title={entered.value}>
                        <Typography
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                          }}
                        >
                          {entered.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

        {/*Minimum dwellSeconds threshold */}
        {!!props.ebtFilter?.listenerType &&
          (props.ebtFilter?.listenerType?.id !== EBTListenerTypeId.Geofence ||
            (props.ebtFilter?.listenerType?.id === EBTListenerTypeId.Geofence &&
              !!props.ebtFilter?.geofenceType)) &&
          props.enteredState === 'Dwell' && (
            <FormControl
              style={{
                marginTop: '-8px',
              }}
            >
              <InputContainer
                id="dwellTimeMinimum"
                label="Minimum dwell time (Seconds)"
                key={'dwellTimeMinimum'}
                name={'dwellTimeMinimum'}
                type={'number'}
                value={String(props.dwellTimeMinimum ? props.dwellTimeMinimum : 0)}
                onChange={(e: { target: { value: string } }) => {
                  const dwellSeconds = Number(e.target.value);
                  props.setDwellTimeMinimum(dwellSeconds < 0 ? 0 : dwellSeconds);
                  props.setEbtFilter({
                    ...props.ebtFilter,
                    dwellSeconds,
                  });
                }}
                placeholder="60"
              />
            </FormControl>
          )}

        {/*Method Type*/}
        {(props.ebtFilter?.notifierType === undefined ||
          props.ebtFilter?.notifierType?.id === EBTActionTypeId.Webhook) && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputLabel id="method-type-option">Method Type</InputLabel>
            <Select
              fullWidth
              labelId="method-type-action"
              id="method-type-dropdown"
              value={props.ebtFilter?.method ?? ''}
              label="Method Type"
              onChange={e => {
                props.setEbtFilter({
                  ...props.ebtFilter,
                  method: e.target.value as Method,
                });
              }}
            >
              {['GET', 'POST'].map(method => (
                <MenuItem key={method} value={method}>
                  <Tooltip title={method}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {method}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>

      {/*Search*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 25px 0px 25px',
        }}
      >
        <Header icon={<Search />}>Search</Header>
      </div>
      <div
        style={{
          width: '300px',
          alignSelf: 'start',
          margin: '0px 0px 25px 25px',
        }}
      >
        Search Keywords:
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Search By*/}
        {(props.ebtFilter?.notifierType === undefined ||
          props.ebtFilter?.notifierType?.id === EBTActionTypeId.Email) && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="recipient"
              label="Recipient"
              key={'recipient'}
              name={'recipient'}
              value={props.ebtFilter?.recipient ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setEbtFilter({
                  ...props.ebtFilter,
                  recipient: e.target.value,
                });
              }}
              placeholder="abc@abc.com"
            />
          </FormControl>
        )}

        {(props.ebtFilter?.notifierType === undefined ||
          props.ebtFilter?.notifierType?.id === EBTActionTypeId.Email) && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="subject"
              label="Subject"
              key={'subject'}
              name={'subject'}
              value={props.ebtFilter?.subject ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setEbtFilter({
                  ...props.ebtFilter,
                  subject: e.target.value,
                });
              }}
            />
          </FormControl>
        )}

        {(props.ebtFilter?.notifierType === undefined ||
          props.ebtFilter?.notifierType?.id === EBTActionTypeId.Email) && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="text"
              label="Text"
              key={'text'}
              name={'text'}
              value={props.ebtFilter?.text ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setEbtFilter({
                  ...props.ebtFilter,
                  text: e.target.value,
                });
              }}
            />
          </FormControl>
        )}

        {(props.ebtFilter?.notifierType === undefined ||
          props.ebtFilter?.notifierType?.id === EBTActionTypeId.Email) && (
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="url"
              label="URL"
              key={'url'}
              name={'url'}
              value={props.ebtFilter?.url ?? ''}
              onChange={(e: { target: { value: string } }) => {
                props.setEbtFilter({
                  ...props.ebtFilter,
                  url: e.target.value,
                });
              }}
              placeholder={'https://www.example.com.au/'}
            />
          </FormControl>
        )}
      </Grid>

      {/*Sort*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '0px 0px 5px 10px',
        }}
      >
        <Header icon={<Sort />}>Sort</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Sort By*/}
        <FormControl
          fullWidth
          style={{
            marginTop: 20,
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="sort-option">Sort By</InputLabel>
          <Select
            fullWidth
            labelId="sort-action"
            id="sort-dropdown"
            value={props.ebtFilter?.orderBy?.value ?? ''}
            label="Sort By"
            onChange={e => {
              const orderBy = OrderByTypes.find(
                l => l.value === (e.target.value as EBTOrderByTypeValue),
              );
              props.setEbtFilter({
                ...props.ebtFilter,
                orderBy,
              });
            }}
          >
            {(props.ebtFilter?.notifierType
              ? OrderByTypes.filter(
                  o => o.parentTypeId === props.ebtFilter?.notifierType?.id || !o.parentTypeId,
                )
              : OrderByTypes
            ).map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/*Order*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="order-option">Order</InputLabel>
          <Select
            fullWidth
            labelId="order-action"
            id="order-dropdown"
            value={props.ebtFilter?.order?.value ?? ''}
            label="Order"
            onChange={e => {
              const order = OrderTypes.find(l => l.value === (e.target.value as OrderTypeValue));
              props.setEbtFilter({
                ...props.ebtFilter,
                order,
              });
            }}
          >
            {OrderTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          alignSelf: 'end',
          margin: '25px',
          width: 'fit-content',
          display: 'grid',
          gap: '6%',
          gridTemplateColumns: '30% 30% 30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            props.setEbtFilter(undefined);
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => props.setOpenFilterDialog(false)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            props.setAvailableEBTs([]);
            props.setRefresh(true);
            props.setOpenFilterDialog(false);
          }}
        >
          Search
        </Button>
      </Grid>
    </>
  );
};
