/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormLabel, OutlinedInput, Stack, SxProps, TextField, Theme } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTimePicker } from '@mui/lab';
import { Style } from 'ol/style';

interface IProps {
  label: string;
  id: string;
  placeholder?: string;
  name: string;
  value?: string;
  multiline?: boolean;
  type?: 'string' | 'number';
  error?: boolean;
  required?: boolean;
  dateRange?: boolean;
  valueDate?: string;
  disableInput?: boolean;
  disableFuture?: boolean;
  onChangeDate?: any;
  onChange?: any;
  onKeyPress?: any;
  onBlur?: any;
}
const InputContainer: React.FC<IProps> = ({
  label,
  id,
  placeholder,
  name,
  value,
  multiline,
  type,
  error,
  required,
  dateRange,
  valueDate,
  disableInput,
  disableFuture,
  onChangeDate,
  onChange,
  onKeyPress,
  onBlur,
}) => {
  return (
    <Stack direction="column" spacing={1}>
      <FormLabel>{label}</FormLabel>
      {dateRange ? (
        <>
          <DateTimePicker
            disableFuture={disableFuture}
            value={valueDate}
            onChange={onChangeDate}
            renderInput={params => <TextField {...params} />}
          />
        </>
      ) : (
        <OutlinedInput
          className="input"
          type={type ?? 'string'}
          id={id}
          placeholder={placeholder}
          name={name}
          multiline={multiline}
          error={error}
          required={required}
          value={value}
          disabled={disableInput}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
        />
      )}
    </Stack>
  );
};

export default InputContainer;
