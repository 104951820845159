import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { BACKGROUND } from '../../../Style/GeoMobyBaseTheme';
import { EBTActionTypeId, Method } from '../../../util/enums';
import InputContainer from '../../Global/InputContainer';
import { EBTFormProps } from '../Props';
import { NotificationType } from '../types';

const EventActionValue = (props: EBTFormProps) => {
  const [notificationData, setNotificationData] = useState<NotificationType | null>();
  return (
    <>
      {!props.hideForm && props.selectedTriggerAction && (
        <Paper
          variant="outlined"
          style={{
            width: 'calc(100% - 10vw)',
            background: BACKGROUND,
          }}
        >
          <Box padding={2}>
            <form className={'trigger-action-form'} noValidate autoComplete="off">
              {(() => {
                switch (props.selectedTriggerAction.id) {
                  case EBTActionTypeId.Email:
                    if (props.webhookData) {
                      props.setWebhookData(null);
                    }
                    return (
                      <Stack spacing={1}>
                        <InputContainer
                          id="email-address"
                          label="Email address"
                          placeholder="abc@abc.com"
                          key={props.selectedTriggerAction.id + '_address'}
                          name={props.selectedTriggerAction.id + '_address'}
                          value={props.emailData?.address ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setEmailData({
                              address: e.target.value,
                              subject: props.emailData?.subject ?? '',
                              message: props.emailData?.message ?? '',
                            });
                          }}
                          required
                        />
                        <InputContainer
                          id="subject"
                          label="Subject"
                          key={props.selectedTriggerAction.id + '_subject'}
                          name={props.selectedTriggerAction.id + '_subject'}
                          value={props.emailData?.subject ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setEmailData({
                              address: props.emailData?.address ?? '',
                              subject: e.target.value,
                              message: props.emailData?.message ?? '',
                            });
                          }}
                          required
                        />
                        <InputContainer
                          id="body"
                          label="Message"
                          key={props.selectedTriggerAction.id + '_body'}
                          name={props.selectedTriggerAction.id + '_body'}
                          value={props.emailData?.message ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setEmailData({
                              address: props.emailData?.address ?? '',
                              subject: props.emailData?.subject ?? '',
                              message: e.target.value,
                            });
                          }}
                          multiline
                          required
                        />
                      </Stack>
                    );
                  case EBTActionTypeId.Notification:
                    return (
                      <Stack spacing={1}>
                        <InputContainer
                          id="notification-title"
                          label="Notification title"
                          key={props.selectedTriggerAction.id + '_title'}
                          name={props.selectedTriggerAction.id + '_title'}
                          value={notificationData?.title ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            setNotificationData({
                              title: e.target.value,
                              body: notificationData?.body ?? '',
                            });
                          }}
                          required
                        />
                        <InputContainer
                          id="body"
                          label="Message"
                          key={props.selectedTriggerAction.id + '_body'}
                          name={props.selectedTriggerAction.id + '_body'}
                          value={notificationData?.body ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            setNotificationData({
                              title: notificationData?.title ?? '',
                              body: e.target.value,
                            });
                          }}
                          multiline
                          required
                        />
                      </Stack>
                    );
                  case EBTActionTypeId.Webhook:
                    if (props.emailData) {
                      props.setEmailData(null);
                    }
                    return (
                      <Stack spacing={1}>
                        <ToggleButtonGroup
                          color="primary"
                          key={props.selectedTriggerAction.id + '_method'}
                          value={props.webhookData?.method ?? Method.GET}
                          exclusive
                          onChange={e => {
                            props.setWebhookData({
                              method: (e.target as EventTarget & HTMLInputElement).value as Method,
                              url: props.webhookData?.url ?? '',
                            });
                          }}
                          aria-label="Platform"
                        >
                          <ToggleButton value={Method.GET}>{Method.GET}</ToggleButton>
                          <ToggleButton value={Method.POST}>{Method.POST}</ToggleButton>
                        </ToggleButtonGroup>
                        <InputContainer
                          id="webhook-action-url"
                          label="URL"
                          key={props.selectedTriggerAction.id + '_url'}
                          name={props.selectedTriggerAction.id + '_url'}
                          value={props.webhookData?.url ?? ''}
                          onChange={(e: { target: { value: string } }) => {
                            props.setWebhookData({
                              method: props.webhookData?.method ?? Method.GET,
                              url: e.target.value,
                            });
                          }}
                          placeholder={'https://www.example.com.au/'}
                          required
                        />
                      </Stack>
                    );
                  default:
                    break;
                }
              })()}
            </form>
          </Box>
        </Paper>
      )}
    </>
  );
};
export default EventActionValue;
