import { BeaconFilterComponent } from '../../../Filter/BeaconFilterComponent';
import { DeviceFilterComponent } from '../../../Filter/DeviceFilterComponent';
import { GeofenceFilterComponent } from '../../../Filter/GeofenceFilterComponent';
import { MicrofenceFilterComponent } from '../../../Filter/MicrofenceFilterComponent';
import { ToolFilterComponent } from '../../../Filter/ToolFilterComponent';
import { TrackerFilterComponent } from '../../../Filter/TrackerFilterComponent';
import { SearchListProps } from '../../Props';

export const filterComponent = (props: SearchListProps) => {
  return (
    <>
      {BeaconFilterComponent(props)}
      {TrackerFilterComponent(props)}
      {DeviceFilterComponent(props)}
      {GeofenceFilterComponent(props)}
      {MicrofenceFilterComponent(props)}
      {ToolFilterComponent(props)}
    </>
  );
};
