import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import RenderFeature from 'ol/render/Feature';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { StyleFunction } from 'ol/style/Style';
import { Extent, getCenter } from 'ol/extent';
import olMap from 'ol/Map';
import { Cluster } from 'ol/source';
import { option } from 'fp-ts';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { cacheKeyForFeature, innerCircleMicrofence, outerCircleMicrofence } from './MapDefaults';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { WHITE } from '../../Style/GeoMobyBaseTheme';

export const CLUSTER_MAX_ZOOM = 13;
export const CLUSTER_DISTANCE = 200;
export const CLUSTER_FONT_FAMILY = '"Open Sans","Montserrat","Arial",sans-serif,bold;';
export const FENCE_VIEWING_HEIGHT = 12;
export const ZOOM_THRESHOLD = 22.5;

const textStroke = new Stroke({
  color: 'rgba(0, 0, 0, 0.6)',
  width: 3,
});

export type ClusterPointStyleFn = (count: number) => Style[];

export const selectedLayerClusterPointStyle: ClusterPointStyleFn = count => [
  new Style({
    image: outerCircleMicrofence,
  }),
  new Style({
    image: innerCircleMicrofence,
    text: new Text({
      text: count.toString(),
      font: CLUSTER_FONT_FAMILY,
      fill: new Fill({
        color: WHITE,
      }),
      stroke: textStroke,
    }),
  }),
];

export const selectedFenceClusterPointStyle: ClusterPointStyleFn = count => [
  new Style({
    image: outerCircleMicrofence,
  }),
  new Style({
    image: innerCircleMicrofence,
    text: new Text({
      text: count.toString(),
      font: CLUSTER_FONT_FAMILY,
      fill: new Fill({
        color: WHITE,
      }),
      stroke: textStroke,
    }),
  }),
];

export const styleFunctionForClusteredFeatures = (
  cache: Map<string, Style | Style[]>,
  cachePrefix: string,
  singleFeatureStyleFn: StyleFunction,
  clusterPointStyle: ClusterPointStyleFn,
): StyleFunction => {
  return (feature: Feature<Geometry> | RenderFeature, resolution: number) => {
    const clusterFeatures = feature.get('features');
    if (!clusterFeatures) {
      const key = `${cachePrefix}-single-${cacheKeyForFeature(feature)}`;
      if (cache.has(key)) {
        return cache.get(key);
      }
      const style = singleFeatureStyleFn(feature, resolution);
      if (
        style &&
        !(
          feature?.get('points')?.type?.toLowerCase()?.includes('line') ||
          feature?.get('type')?.toLowerCase()?.includes('line')
        ) &&
        feature?.get('numberOfArrows') === 0
      ) {
        cache.set(key, style);
      }
      return style;
    } else if (clusterFeatures.length === 1) {
      const key = `${cachePrefix}-${cacheKeyForFeature(clusterFeatures[0])}`;
      if (cache.has(key)) {
        return cache.get(key);
      }
      const style = singleFeatureStyleFn(clusterFeatures[0], resolution);
      if (style) {
        const style0 = Array.isArray(style) ? style[0] : style;
        style0.setGeometry(clusterFeatures[0].getGeometry());
      }
      return style;
    }
    const key = `${cachePrefix}-${clusterFeatures.length}`;
    if (cache.has(key)) {
      return cache.get(key);
    }
    const style = clusterPointStyle(clusterFeatures.length);
    cache.set(key, style);
    return style;
  };
};
