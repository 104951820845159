import { GridRowData } from '@material-ui/data-grid';
import { Accordion, AccordionSummary, Grid } from '@mui/material';
import { transformExtent } from 'ol/proj';
import { SearchTypeIDs } from '../../../../../util/enums';
import { MICROFENCE } from '../../../BeaconUtils';
import {
  displayAssetIsActive,
  displayAssetLabel,
  getMicroFenceIcon,
  selectedElement,
} from '../../../Helpers';
import { ACTIVE_MICROFENCE } from '../../../../../util/constants';
import { SearchListProps } from '../../Props';

export const useMicrofenceList = (
  props: SearchListProps & { isLoading: boolean; availableMicrofences: GridRowData[] },
) => {
  return (
    <>
      {!selectedElement(props) &&
        !props.isLoading &&
        props.searchType?.id === SearchTypeIDs.Microfences &&
        props.availableMicrofences.map(microfence => {
          const isActive = !!microfence.feature.get(ACTIVE_MICROFENCE);
          return (
            <Accordion
              id={microfence.feature.get('id')}
              key={microfence.feature.get('id')}
              style={{ width: '98%' }}
            >
              <AccordionSummary
                onClick={() => {
                  const coords = microfence.feature.get('point')?.coordinates;
                  const extent = transformExtent([...coords, ...coords], 'EPSG:4326', 'EPSG:3857');
                  microfence.feature.set('selected', true);
                  props.setSelectedFromMap(false);
                  props.setSelectedMicrofence(microfence.feature.getProperties());
                  if (extent) {
                    props.setExtent(extent);
                  }
                  const activeMicrofence = props.activeAssets.find(
                    active =>
                      (active.prefix === MICROFENCE &&
                        active.id?.gatewayId !== undefined &&
                        active.id?.gatewayId === microfence.feature.get('assetId')?.gatewayId) ||
                      (active.id?.uuid !== undefined &&
                        active.id?.major !== undefined &&
                        active.id?.minor !== undefined &&
                        active.id?.uuid === microfence.feature.get('assetId')?.uuid &&
                        active.id?.major === microfence.feature.get('assetId')?.major &&
                        active.id?.minor === microfence.feature.get('assetId')?.minor) ||
                      (active.id?.deviceId !== undefined &&
                        active.id?.deviceId === microfence.feature.get('assetId')?.deviceId),
                  );
                  if (!activeMicrofence) return;
                  props.setSelectedAsset(activeMicrofence);
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                {getMicroFenceIcon(microfence.feature.get('microfenceType'))}
                <Grid container direction="row">
                  <Grid item>
                    {displayAssetLabel(
                      microfence.feature.get('name'),
                      microfence.feature.get('name'),
                    )}
                  </Grid>
                  <Grid
                    item
                    style={{
                      width: 'max-content',
                      marginLeft: 'auto',
                    }}
                  >
                    {isActive && displayAssetIsActive()}
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
