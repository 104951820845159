import axios, { AxiosRequestConfig } from 'axios';
import { array, string } from 'fp-ts';
import { option } from 'fp-ts/es6';
import { sequenceT } from 'fp-ts/es6/Apply';
import { fromNullable } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/pipeable';
import { Feature } from 'ol';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { GeoJSONPolygon } from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { StyleFunction } from 'ol/style/Style';
import {
  microfenceClusterFromJson,
  layerFromJson,
  LayerResult,
} from '../../../hooks/geomoby/LiveMapOlFunctions';
import { StrongFeatureHolder } from '../../../hooks/geomoby/useLiveMapLoader';
import { FenceZone, MicrofenceZone } from '../../../util/enums';
import { MICROFENCE_LAYER_ID } from '../BeaconUtils';
import { Bounds, olFenceLayers, olMicrofenceLayer } from '../MapDefaults';
import { GlobalProjectId, LayerFenceData, MicrofenceData } from '../Messages';
import { GeomobyOverride, MicrofenceAssetId } from '../types';

// GETTING LAYERS
export const getVectorLayers = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  bounds: Bounds,
): Promise<
  { id: string; name: string; source: VectorLayer<VectorSource<Geometry>> }[] | undefined
> => {
  const layers = await axios.get<
    {
      id: string;
      name: string;
      polygons: { id: string; name: string; points: GeoJSONPolygon }[];
      multipolygons: { id: string; name: string; points: GeoJSONPolygon }[];
      lines: { id: string; name: string; points: GeoJSONPolygon }[];
    }[]
  >(
    // Pushed the extentInDegrees to its maximum for now. To speed up the loading.
    `${triggersUrl}/${gpid.cid}/${gpid.pid}/geofences/withinExtent?latitude=${
      bounds.latitude
    }&longitude=${bounds.longitude}&extentInDegrees=${1}&maxPoints=100`,
    requestConfig,
  );
  const lrs: LayerResult[] = layers.data.map(({ id, name, polygons, multipolygons, lines }) =>
    layerFromJson(
      id,
      name,
      [
        ...polygons,
        ...multipolygons.map(m => {
          return {
            ...m,
            zone: FenceZone.cleared,
          };
        }),
        ...lines,
      ],
      true,
    ),
  );
  return olFenceLayers(
    lrs.map(({ lid, name, layer }) => {
      return { id: lid, name, source: layer };
    }),
  );
};

export const getVectorLayer = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  layerId: string,
  bounds: Bounds,
): Promise<
  { id: string; name: string; source: VectorLayer<VectorSource<Geometry>> } | undefined
> => {
  try {
    const { data: layer } = await axios.get<{
      id: string;
      name: string;
      polygons: { id: string; name: string; points: GeoJSONPolygon }[];
      multipolygons: { id: string; name: string; points: GeoJSONPolygon }[];
      lines: { id: string; name: string; points: GeoJSONPolygon }[];
    }>(
      `${triggersUrl}/${gpid.cid}/${gpid.pid}/geofences/withinExtent/${layerId}?latitude=${bounds.latitude}&longitude=${bounds.longitude}&extentInDegrees=${bounds.extentInDegrees}&maxPoints=100`,
      requestConfig,
    );
    const lr: LayerResult = layerFromJson(
      layerId,
      layer.name,
      [
        ...layer.polygons,
        ...layer.multipolygons.map(m => {
          return {
            ...m,
            zone: FenceZone.cleared,
          };
        }),
        ...layer.lines,
      ],
      true,
    );

    return olFenceLayers([{ id: lr.lid, name: layer.name, source: lr.layer }])?.find(
      lyr => lyr.id === layerId,
    );
  } catch (error) {
    return;
  }
};

export const getMicrofencesVectorLayer = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  bounds: Bounds,
): Promise<AnimatedCluster> => {
  const { data: microfences } = await axios.get<MicrofenceData[]>(
    `${triggersUrl}/${gpid.cid}/${gpid.pid}/microfences`,
    requestConfig,
  );
  const { microfenceCluster } = microfenceClusterFromJson(MICROFENCE_LAYER_ID, microfences, true);
  return olMicrofenceLayer(microfenceCluster, new Map(), { showActivity: false });
};

// STYLING
export const setSelectedLayerStyle = (
  old:
    | {
        layer: VectorLayer<VectorSource<Geometry>>;
        name: string;
      }
    | undefined,
  now:
    | {
        layer: VectorLayer<VectorSource<Geometry>>;
        name: string;
      }
    | undefined,
  selectedVectorLayerStyle: StyleFunction,
) => {
  // LTP-1179 Clean up any left-overs from the iniital 'Tech Debt' tickets
  const layers = sequenceT(option.option)(fromNullable(old), fromNullable(now));
  const res = pipe(
    layers,
    option.map(([oldL, newL]) => {
      newL.layer.setStyle(selectedVectorLayerStyle);
      return null;
    }),
  );
  if (option.isSome(res)) return;

  pipe(
    fromNullable(now),
    option.map(o => o.layer.setStyle(selectedVectorLayerStyle)),
  );
};

export const updateFenceOnMap = (
  source: VectorSource<Geometry>,
  layerChanged: () => void,
  id: string,
  name: string,
  fenceZone: FenceZone | undefined,
  assetId?: MicrofenceAssetId,
  microfenceZone?: MicrofenceZone,
) => {
  const f = pipe(
    // If a feat named `old` exists, this will be it... Maybe?
    option.some(source),
    option.map((source: VectorSource<Geometry>) => source.getFeatures()),
    option.map<Feature<Geometry>[], Feature<Geometry>[]>(fs =>
      pipe(
        fs,
        array.filterMap(f => {
          const fid = option.fromNullable(f.getProperties()['id']);
          return pipe(
            fid,
            option.chain(fid => {
              if (fid === id) return option.some(f);
              return option.none;
            }),
          );
        }),
      ),
    ),
    option.chain(fs => {
      if (fs.length > 0) return option.some(fs[0]);
      return option.none;
    }),
  );
  if (option.isNone(f)) return;

  f.value.setProperties({ ...f.value.getProperties(), name, updated: true });
  if (assetId) {
    f.value.set('zone', microfenceZone);
    f.value.set('assetId', assetId);
  }
  if (fenceZone) {
    f.value.set('zone', fenceZone);
  }
  layerChanged();
};
