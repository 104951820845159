/* eslint-disable @typescript-eslint/no-explicit-any */
import { either } from 'fp-ts/es6';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import {
  GeoJSONLineString,
  GeoJSONMultiPolygon,
  GeoJSONPoint,
  GeoJSONPolygon,
} from 'ol/format/GeoJSON';
import { MultiPolygon } from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import * as olProj from 'ol/proj';
import Projection from 'ol/proj/Projection';
import VectorSource from 'ol/source/Vector';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import {
  MICROFENCE_LAYER_ID,
  MICROFENCE_LAYER_LABEL,
  MICROFENCE_PROP_LABELS,
  POINT,
} from '../../Components/Map/BeaconUtils';
import { OVERLAY_CONTENT_ELEMENT } from '../../Components/Map/MapOverlay';
import { LayerFenceData, MicrofenceData, PointData } from '../../Components/Map/Messages';
import { FenceZone } from '../../util/enums';
import { StrongFeatureHolder } from './useLiveMapLoader';

const toString = (e: any) => '' + e;

const gpsToMap = olProj.getTransformFromProjections(
  olProj.get('EPSG:4326'),
  olProj.get('EPSG:3857'),
);
const mapToGPS = olProj.getTransformFromProjections(
  olProj.get('EPSG:3857'),
  olProj.get('EPSG:4326'),
);

export const MULTIPOLYGON_LAYER_ID = '__multiploygons__';
export const MULTIPOLYGON_LAYER_LABEL = 'Custom areas';

export interface LayerResult {
  lid: string;
  name: string;
  layer: StrongFeatureHolder<Polygon, LayerFenceData>[];
}

export function layerFromJson(
  layerId: string,
  layerName: string,
  features: {
    points: GeoJSONPolygon | GeoJSONLineString | GeoJSONPoint;
    id: string;
    name: string;
    zone?: FenceZone;
  }[],
  toMercator: boolean,
): LayerResult {
  const efeats: either.Either<string, StrongFeatureHolder<Polygon, LayerFenceData>>[] =
    features.map(feature => polyFromJson(layerId, layerName, feature, toMercator));
  return {
    lid: layerId,
    name: layerName,
    layer: efeats.filter(either.isRight).map(e => e.right),
  };
}

export function jsonFromLayer(layerSource: VectorSource<Geometry>) {
  const copied = layerSource
    .getFeatures()
    .map(f => f.clone())
    .flatMap(f => f.get('features') ?? f);
  copied.forEach(f => f.getGeometry()?.applyTransform(mapToGPS)); // Mutating change on cloned data
  return new GeoJSON().writeFeaturesObject(copied, {
    featureProjection: 'EPSG:4326',
  });
}
export function jsonFromBeaconLayer(
  vl: VectorLayer<VectorSource<Geometry>>,
  projection: Projection,
) {
  const copied = vl
    .getSource()
    .getFeatures()
    .map(f => f.clone());
  return new GeoJSON().writeFeaturesObject(copied, {
    featureProjection: projection,
  });
}

function polyFromJson(
  layerId: string,
  layerName: string,
  feature: {
    points: GeoJSONPolygon | GeoJSONLineString;
    id: string;
    name: string;
    zone?: FenceZone;
  },
  toMercator: boolean,
): either.Either<string, StrongFeatureHolder<Polygon, LayerFenceData>> {
  return either.tryCatch<string, StrongFeatureHolder<Polygon, LayerFenceData>>(() => {
    const f = new GeoJSON().readFeature(
      {
        type: 'Feature',
        geometry: feature.points,
        properties: {
          ...feature,
          layerId,
          layerName,
        },
      },
      {
        featureProjection: 'EPSG:4326',
      },
    );
    if (toMercator) {
      f.getGeometry().applyTransform(gpsToMap);
    }
    const lfd: LayerFenceData = {
      fenceId: feature.id,
      layerId: layerId,
      fenceName: feature.name,
      layerName: layerName,
    };
    const fp = f as Feature<Polygon>;
    const sfh: StrongFeatureHolder<Polygon, LayerFenceData> = {
      data: lfd,
      feature: fp,
    };
    return sfh;
  }, toString);
}

export const multipolygonsFromJson = (
  layerId: string,
  layerName: string,
  features: {
    points: GeoJSONPolygon | GeoJSONLineString | GeoJSONPoint;
    id: string;
    name: string;
  }[],
  toMercator: boolean,
) =>
  features
    .map(feature => multipolyFromJson(layerId, layerName, feature, toMercator))
    .filter((f): f is StrongFeatureHolder<MultiPolygon, LayerFenceData> => !!f);

function multipolyFromJson(
  layerId: string,
  layerName: string,
  feature: {
    points: GeoJSONMultiPolygon;
    id: string;
    name: string;
  },
  toMercator: boolean,
): StrongFeatureHolder<MultiPolygon, LayerFenceData> | null {
  try {
    const f = new GeoJSON().readFeature(
      {
        type: 'Feature',
        geometry: feature.points,
        properties: {
          ...feature,
          layerId,
          layerName,
        },
      },
      {
        featureProjection: 'EPSG:4326',
      },
    );
    if (toMercator) {
      f.getGeometry().applyTransform(gpsToMap);
    }
    const lfd: LayerFenceData = {
      fenceId: feature.id,
      layerId: layerId,
      fenceName: feature.name,
      layerName: layerName,
    };
    const fp = f as Feature<MultiPolygon>;
    const sfh: StrongFeatureHolder<MultiPolygon, LayerFenceData> = {
      data: lfd,
      feature: fp,
    };
    return sfh;
  } catch (e) {
    console.log('Error reading MultiPolygon', e);
    return null;
  }
}
export interface MicrofenceClusterResult {
  cid: string;
  microfenceCluster: StrongFeatureHolder<Point, MicrofenceData>[];
}
export interface PointClusterResult {
  cid: string;
  pointCluster: StrongFeatureHolder<Point, PointData>[];
}
export interface GeometryPointsResult {
  cid: string;
  geometry: StrongFeatureHolder<Geometry, { type: string }>;
}
export function microfenceClusterFromJson(
  clid: string,
  data: any,
  toMercator: boolean,
): MicrofenceClusterResult {
  let efeats: either.Either<string, StrongFeatureHolder<Point, MicrofenceData>>[];

  //if receive obj with features
  if (Array.isArray(data)) {
    efeats = data
      .filter(f => !!f.point)
      .map(f => {
        return beaconFromJson(f, toMercator);
      });
  } else {
    efeats = data.features.map((f: any) => {
      return beaconFromJson(f, toMercator);
    });
  }

  return {
    cid: clid,
    microfenceCluster: efeats.filter(either.isRight).map(e => e.right),
  };
}

export function polygonPointsFromJson(data: any): Feature<Geometry> | undefined {
  const efeat: either.Either<string, StrongFeatureHolder<Geometry, { type: string }>> =
    geometryFromJson(data);
  return either.isRight(efeat) ? efeat.right.feature : undefined;
}

export function geometryFromJson(
  data: any,
): either.Either<string, StrongFeatureHolder<Geometry, { type: string }>> {
  return either.tryCatch<string, StrongFeatureHolder<Geometry, { type: string }>>(() => {
    const f = new GeoJSON().readFeature(data.points, {
      featureProjection: 'EPSG:4326',
    });
    f.getGeometry().applyTransform(gpsToMap);
    const cbd: { type: string } = {
      ...data,
      type: Polygon,
    };
    const fp = f as Feature<Geometry>;
    const sfh: StrongFeatureHolder<Geometry, { type: string }> = {
      data: cbd,
      feature: fp,
    };
    return sfh;
  }, toString);
}

export function pointClusterFromJson(clid: string, data: any): PointClusterResult {
  let efeats: either.Either<string, StrongFeatureHolder<Point, PointData>>[];

  //if receive obj with features
  if (Array.isArray(data)) {
    efeats = data
      .filter(f => !!f.point)
      .map(f => {
        return pointBeaconFromJson(f);
      });
  } else {
    efeats = data.features.map((f: any) => {
      return pointBeaconFromJson(f);
    });
  }

  return {
    cid: clid,
    pointCluster: efeats.filter(either.isRight).map(e => e.right),
  };
}

function beaconFromJson(
  data: any,
  toMercator: boolean,
): either.Either<string, StrongFeatureHolder<Point, MicrofenceData>> {
  return either.tryCatch<string, StrongFeatureHolder<Point, MicrofenceData>>(() => {
    const f = new GeoJSON().readFeature(data.point, {
      featureProjection: 'EPSG:4326',
    });
    if (toMercator) {
      f.getGeometry().applyTransform(gpsToMap);
    }
    const cbd: MicrofenceData = {
      ...data,
      fenceId: data.id,
      fenceName: data.name,
      layerId: MICROFENCE_LAYER_ID,
      layerName: MICROFENCE_LAYER_LABEL,
      microfenceType: data.type,
      assetId: data.assetId,
      geomobyProperties: {
        [MICROFENCE_PROP_LABELS.boundaryRssi]: data.boundaryRssi,
        [MICROFENCE_PROP_LABELS.timeoutSeconds]: data.timeoutSeconds,
      },
    };
    f.setProperties(cbd);
    const fp = f as Feature<Point>;
    const sfh: StrongFeatureHolder<Point, MicrofenceData> = {
      data: cbd,
      feature: fp,
    };
    return sfh;
  }, toString);
}

function pointBeaconFromJson(
  data: any,
): either.Either<string, StrongFeatureHolder<Point, PointData>> {
  return either.tryCatch<string, StrongFeatureHolder<Point, PointData>>(() => {
    const f = new GeoJSON().readFeature(data.point, {
      featureProjection: 'EPSG:4326',
    });
    f.getGeometry().applyTransform(gpsToMap);
    const cbd: PointData = {
      ...data,
      type: POINT,
    };
    const fp = f as Feature<Point>;
    const sfh: StrongFeatureHolder<Point, PointData> = {
      data: cbd,
      feature: fp,
    };
    return sfh;
  }, toString);
}

export function pointFromLatLon(lat: number, lon: number): Point {
  return new Point(olProj.fromLonLat([lon, lat]));
}

export const OverlayPortal = ({ children }: { children: ReactNode }) => {
  return createPortal(children, OVERLAY_CONTENT_ELEMENT);
};
