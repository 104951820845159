import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { EntityType, SearchTypeIDs, SearchTypeValue } from '../../../../../util/enums';
import {
  displayAssetActivityStatus,
  displayAssetHeading,
  displayAssetId,
  findActiveAsset,
} from '../../../Helpers';
import { SearchListProps } from '../../Props';
import { Build } from '@mui/icons-material';
import { PRIMARY, WHITE } from '../../../../../Style/GeoMobyBaseTheme';

export const useSelectedTool = (props: SearchListProps) => {
  return (
    <>
      {props.selectedTool && (
        <Paper
          variant="outlined"
          style={{
            padding: '10px',
            height: 'fit-content',
          }}
        >
          <Box color="primary.main">
            <Build
              style={{
                color: 'lightslategray',
                marginRight: '5px',
                marginBottom: '10px',
              }}
            />
          </Box>

          {displayAssetHeading(props.selectedTool.label, props.selectedTool.id, EntityType.Tool)}
          <Grid
            container
            direction="column"
            style={{
              marginBottom: '10px',
              width: 'fit-content',
            }}
          >
            <Button
              style={{
                marginLeft: '-7px',
                color: WHITE,
              }}
              onClick={() => {
                props.setSearchType({ id: SearchTypeIDs.Tools, value: SearchTypeValue.Tools });
                props.setSelectedTool(undefined);
                props.setSelectedAsset(undefined);
                props.setRefreshSearch(true);
              }}
            >
              Tools
            </Button>
          </Grid>

          <Grid container direction={'row'} justifyContent={'center'}>
            {
              <>
                {displayAssetId(props.selectedTool.id as string, EntityType.Beacon)}
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Tool Type'}>
                    <Typography style={{ color: PRIMARY, paddingRight: '5px' }}>
                      Tool Type:
                    </Typography>
                  </Tooltip>
                  <Tooltip title={props.selectedTool.tool}>
                    <Typography>{props.selectedTool.tool}</Typography>
                  </Tooltip>
                </Grid>

                {displayAssetActivityStatus(
                  !!findActiveAsset(props.selectedTool.id, props.activeAssets),
                )}
              </>
            }
          </Grid>
        </Paper>
      )}
    </>
  );
};
