import { Accordion, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { GeofenceFilter, SearchType, GeomobyOverride } from '../../../types';
import { Point } from 'ol/geom';
import { getFenceIcon } from '../../../Helpers';
import { GeomobyProperties } from '../Geofence/GeomobyProperties';
import { GeomobyOverrides } from '../Geofence/GeomobyOverrides';
import {
  BufferShapeType,
  FenceZone,
  GeofenceEntityType,
  SearchTypeIDs,
} from '../../../../../util/enums';
import { GeofenceListProps, SearchListProps } from '../../Props';
import { FRESH, UNKNOWN_LAYER } from '../../../../../util/constants';

export const geofenceList = (props: GeofenceListProps & SearchListProps) => {
  return (
    <>
      {!props.isLoading &&
        props.searchType?.id === SearchTypeIDs.Geofences &&
        props.availableGeofences.map(fence => {
          return (
            <Accordion id={fence.id} key={fence.id} expanded={false} style={{ width: '98%' }}>
              <AccordionSummary
                // LTP-1142 'Live/Replay tech debt' will see this function passed in instead.
                onClick={() => {
                  if (fence.id?.includes(FRESH) && fence.layerId === UNKNOWN_LAYER) {
                    props.setOpenGenericDialog(true);
                    return;
                  }
                  props.setDisplayGeomobyProperties(
                    Object.entries(fence.geomobyProperties).map(([property, value], index) => {
                      return {
                        index: index as number,
                        property: property as string,
                        value: value as string,
                      };
                    }),
                  );
                  props.setDisplayGeomobyOverrides(fence.geomobyOverrides);
                  fence.selected = true;
                  props.setSelectedGeofence(fence);
                  props.setFenceNameInput(fence.name);
                  props.setReassignedLayerId('');
                  props.setBufferOffset(undefined);
                  props.setBufferShape(BufferShapeType.Circle);
                  props.getGeofence(fence);
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                {getFenceIcon(
                  fence.type?.toLowerCase()?.includes(GeofenceEntityType.Multipolygon) ||
                    fence.points?.type?.toLowerCase()?.includes(GeofenceEntityType.Multipolygon)
                    ? FenceZone.cleared
                    : fence.zone,
                )}
                <Tooltip
                  title={`${fence.name}${
                    !fence.zone || fence.zone === FenceZone.none
                      ? ''
                      : ' (' + fence.zone.toUpperCase() + ')'
                  }`}
                >
                  <Typography
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {fence.name}
                  </Typography>
                </Tooltip>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
