import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Fab, Grid, Tooltip, Typography } from '@mui/material';
import { DrawType, EditType, FenceGeometryType, MeasurementType } from '../../../util/enums';

type Tools = [JSX.Element, MeasurementType | undefined, string][];

export const MEASUREMENT_TOOLS: Tools = [
  [<b key={MeasurementType.M}>m</b>, MeasurementType.M, `Metres`],
  [<b key={MeasurementType.FT}>ft</b>, MeasurementType.FT, `Feet`],
  [<b key={MeasurementType.KM}>km</b>, MeasurementType.KM, `Kilometres`],
  [<b key={MeasurementType.MI}>mi</b>, MeasurementType.MI, `Miles`],
  [
    <b key={MeasurementType.M2}>
      m<sup>2</sup>
    </b>,
    MeasurementType.M2,
    `Square Metres`,
  ],
  [
    <b key={MeasurementType.FT2}>
      ft<sup>2</sup>
    </b>,
    MeasurementType.FT2,
    `Square Feet`,
  ],
  [
    <b key={MeasurementType.KM2}>
      km<sup>2</sup>
    </b>,
    MeasurementType.KM2,
    `Square Kilometres`,
  ],
  [
    <b key={MeasurementType.MI2}>
      mi<sup>2</sup>
    </b>,
    MeasurementType.MI2,
    `Square Miles`,
  ],
];

export const createPenSubTools = (props: {
  mapType: 'INDOOR' | 'OUTDOOR';
  selectedLayer: string | undefined;
  geofenceType: FenceGeometryType | undefined;
  geofenceTooBig: boolean;
  editing: boolean;
  isLoading: boolean;
  setEditing: () => void;
  unsetEditing: () => void;
  drawType: DrawType | undefined;
  setDrawType: Dispatch<SetStateAction<DrawType | undefined>>;
  editType: EditType | undefined;
  setEditType: Dispatch<SetStateAction<EditType | undefined>>;
  measurementType: MeasurementType | undefined;
  setMeasurementType: Dispatch<SetStateAction<MeasurementType | undefined>>;
}): ReactNode[] => {
  return [
    <Typography variant="caption" key="units">
      Units
    </Typography>,
    ...MEASUREMENT_TOOLS.map(([label, measurementType, tooltip], index) => {
      return (
        <Grid item key={`${DrawType.Measure}_${index}`}>
          <Tooltip title={tooltip} arrow>
            <Fab
              color={
                measurementType && measurementType === props.measurementType
                  ? 'secondary'
                  : undefined
              }
              size="small"
              onClick={() => {
                props.setMeasurementType(measurementType);
              }}
            >
              {label}
            </Fab>
          </Tooltip>
        </Grid>
      );
    }),
  ];
};
