import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, useEffect } from 'react';
import {
  GeofenceFilter,
  GeofenceOrderByTypeValue,
  OrderTypeValue,
  ZoneTypeValue,
  FenceTypeId,
} from '../types';
import { GeofenceOrderByTypes, OrderTypes, ZoneTypes } from '../values';
import { FilterList, Search, Sort } from '@mui/icons-material';
import { Header } from '../../../Common/Sidebar';
import { Extent } from 'ol/extent';
import InputContainer from '../../Global/InputContainer';
import { FilterComponentProps, SearchListProps } from '../Editor/Props';
import { FenceZone, SearchTypeIDs } from '../../../util/enums';

export const GeofenceFilterComponent = (props: SearchListProps | FilterComponentProps) => {
  return (
    <>
      {props.searchType?.id === SearchTypeIDs.Geofences && (
        <Grid
          style={{
            marginTop: '0px',
            contentVisibility: props.showFilter ? 'visible' : 'hidden',
          }}
        >
          {/*Filter*/}
          <div
            style={{
              width: '100px',
              alignSelf: 'start',
              marginBottom: '10px',
            }}
          >
            <Header icon={<FilterList />}>Filter</Header>
          </div>
          <Grid
            style={{
              height: '80%',
              alignSelf: 'center',
            }}
          >
            {/*Geofence Type*/}
            <FormControl
              fullWidth
              style={{
                marginBottom: 20,
                alignSelf: 'center',
              }}
            >
              <InputLabel id="geofence-type-option">Geofence Type</InputLabel>
              <Select
                fullWidth
                labelId="geofence-type-action"
                id="geofence-type-dropdown"
                value={
                  ZoneTypes.find(
                    z =>
                      z.id === props.geofenceFilter?.zone ||
                      z.id === props.geofenceFilter?.fenceType,
                  )?.value ?? ''
                }
                label="Geofence Type"
                onChange={e => {
                  const zoneType = ZoneTypes.find(
                    z => z.value === (e.target.value as ZoneTypeValue),
                  );
                  if (!zoneType) return;
                  props.setGeofenceFilter({
                    ...props.geofenceFilter,
                    ...(zoneType.id === FenceZone.breach || zoneType.id === FenceZone.buffer
                      ? {
                          zone: zoneType.id as FenceZone,
                          fenceType: undefined,
                        }
                      : {
                          zone: undefined,
                          fenceType: zoneType.id as FenceTypeId,
                        }),
                  });
                }}
              >
                {ZoneTypes.map(zoneType => (
                  <MenuItem key={zoneType.id} value={zoneType.value}>
                    <Tooltip title={zoneType.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {zoneType.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/*Search*/}
          <div
            style={{
              width: '100px',
              alignSelf: 'start',
              marginTop: '10px',
            }}
          >
            <Header icon={<Search />}>Search</Header>
          </div>
          <div
            style={{
              width: '300px',
              alignSelf: 'start',
              marginBottom: '10px',
            }}
          >
            Search Keywords:
          </div>

          {/*Search Name*/}
          <Grid
            style={{
              height: '80%',
              alignSelf: 'center',
            }}
          >
            {
              <FormControl
                fullWidth
                style={{
                  marginBottom: 20,
                  alignSelf: 'center',
                }}
              >
                <InputContainer
                  id="searchName"
                  label="Name"
                  key={'searchName'}
                  name={'searchName'}
                  value={props.geofenceFilter?.searchName ?? ''}
                  onChange={(e: { target: { value: string } }) => {
                    props.setGeofenceFilter({
                      ...props.geofenceFilter,
                      searchName: e.target.value,
                    });
                  }}
                  placeholder="Enter a name"
                />
              </FormControl>
            }
          </Grid>

          {/*Search Geomoby Property Keys */}
          <Grid
            style={{
              height: '80%',
              alignSelf: 'center',
            }}
          >
            {
              <FormControl
                fullWidth
                style={{
                  marginBottom: 20,
                  alignSelf: 'center',
                }}
              >
                <InputContainer
                  id="geomobyPropertyKey"
                  label="Property Name"
                  key={'geomobyPropertyKey'}
                  name={'geomobyPropertyKey'}
                  value={props.geofenceFilter?.searchGeomobyKey ?? ''}
                  onChange={(e: { target: { value: string } }) => {
                    props.setGeofenceFilter({
                      ...props.geofenceFilter,
                      searchGeomobyKey: e.target.value,
                    });
                  }}
                  placeholder="Enter a name"
                />
              </FormControl>
            }
          </Grid>

          {/*Search Geomoby Property Values */}
          <Grid
            style={{
              height: '80%',
              alignSelf: 'center',
            }}
          >
            {
              <FormControl
                fullWidth
                style={{
                  marginBottom: 20,
                  alignSelf: 'center',
                }}
              >
                <InputContainer
                  id="geomobyPropertyValue"
                  label="Property Value"
                  key={'geomobyPropertyValue'}
                  name={'geomobyPropertyValue'}
                  value={props.geofenceFilter?.searchGeomobyValue ?? ''}
                  onChange={(e: { target: { value: string } }) => {
                    props.setGeofenceFilter({
                      ...props.geofenceFilter,
                      searchGeomobyValue: e.target.value,
                    });
                  }}
                  placeholder="Enter a value"
                />
              </FormControl>
            }
          </Grid>

          {/*Sort*/}
          <div
            style={{
              width: 'fit-content',
              alignSelf: 'start',
              marginTop: '10px',
            }}
          >
            <Header icon={<Sort />}>Sort</Header>
          </div>

          <Grid
            style={{
              height: '80%',
              alignSelf: 'center',
              marginTop: '10px',
            }}
          >
            {/*/!*Sort By*!/*/}
            <FormControl
              fullWidth
              style={{
                marginBottom: '20px',
                alignSelf: 'center',
              }}
            >
              <InputLabel id="sort-option">Sort By</InputLabel>
              <Select
                fullWidth
                labelId="sort-action"
                id="sort-dropdown"
                value={props.geofenceFilter?.orderBy?.value ?? ''}
                label="Sort By"
                onChange={e => {
                  const orderBy = GeofenceOrderByTypes.find(
                    l => l.value === (e.target.value as GeofenceOrderByTypeValue),
                  );
                  if (!orderBy) return;
                  props.setGeofenceFilter({
                    ...props.geofenceFilter,
                    orderBy,
                  });
                }}
              >
                {GeofenceOrderByTypes.map(orderBy => (
                  <MenuItem key={orderBy.id} value={orderBy.value}>
                    <Tooltip title={orderBy.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {orderBy.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/*/!*Order*!/*/}
            <FormControl
              fullWidth
              style={{
                marginBottom: 20,
                alignSelf: 'center',
              }}
            >
              <InputLabel id="order-option">Order</InputLabel>
              <Select
                fullWidth
                labelId="order-action"
                id="order-dropdown"
                value={props.geofenceFilter?.order?.value ?? ''}
                label="Order"
                onChange={e => {
                  const order = OrderTypes.find(
                    l => l.value === (e.target.value as OrderTypeValue),
                  );
                  props.setGeofenceFilter({
                    ...props.geofenceFilter,
                    order,
                  });
                }}
              >
                {OrderTypes.map(orderBy => (
                  <MenuItem key={orderBy.id} value={orderBy.value}>
                    <Tooltip title={orderBy.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {orderBy.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            direction="column"
            style={{
              width: 'fit-content',
              marginTop: '30px',
              display: 'grid',
              gap: '6%',
              gridTemplateColumns: '30% 30% 30%',
            }}
          >
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => props.setClearFilter(true)}
            >
              Clear
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => {
                props.setShowFilter(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => {
                props.setRefreshSearch(true);
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
