import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { BACKGROUND } from '../../../Style/GeoMobyBaseTheme';
import { EBTTriggerSubTypeValue } from '../../../util/enums';
import InputContainer from '../../Global/InputContainer';
import { getTriggerSubTypIcon } from '../Helpers';
import { EBTFormProps } from '../Props';

const TriggerLocationType = (props: EBTFormProps) => {
  return (
    <>
      {props.selectedTriggerType?.id === 'LOCATION' && (
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            width: 'calc(100% - 10vw)',
            alignSelf: 'center',
          }}
        >
          <InputLabel id="location-type">Location Type</InputLabel>
          <Select
            fullWidth
            labelId="location-type"
            id="location-type-dropdown"
            value={props.selectedTriggerSubType?.value ?? ''}
            label="Location Type"
            onChange={e => {
              if (!props.selectedTriggerType) return;
              const triggerSubType = props.selectedTriggerType.subTypes?.find(
                subType => subType.value === (e.target.value as EBTTriggerSubTypeValue),
              );
              props.setSelectedTriggerSubType(triggerSubType);
              props.setSelectedTriggerOption(undefined);
              props.setAvailableFeatures([]);
              props.setSelectedLayer(null);
              props.setTriggerValue(null);
              props.setSelectedEBT(undefined);
              props.setSelectedTableData(undefined);
              props.setIsEditing(false);
            }}
          >
            {props.selectedTriggerType.subTypes?.map(subType => (
              <MenuItem key={subType.id} value={subType.value}>
                <Tooltip title={subType.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {getTriggerSubTypIcon(subType.id)}
                    <span
                      style={{
                        padding: '10px',
                      }}
                    ></span>{' '}
                    {subType.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};
export default TriggerLocationType;
