import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import { NearMe, NearMeOutlined } from '@mui/icons-material';
import { PRIMARY } from '../../../Style/GeoMobyBaseTheme';

export const Follow = ({ following, onClick }: { following: boolean; onClick: () => void }) => (
  <Tooltip title={following ? 'Stop following' : 'Follow'}>
    <Fab color="default" size="small" onClick={onClick}>
      {following && <NearMe style={{ color: PRIMARY }} />}
      {!following && <NearMeOutlined />}
    </Fab>
  </Tooltip>
);
