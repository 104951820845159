import {
  Accordion,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { GeofenceFilter, SearchType, GeomobyOverride, FenceZoneTypeValue } from '../../../types';
import { Geometry } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import { ReassignedFence } from '../../../MapDefaults';
import {
  EntityType,
  FenceGeometryType,
  RequestType,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../../../util/enums';
import { Cancel, CheckCircle, Delete, Edit } from '@mui/icons-material';
import { LayersProps, SearchListProps } from '../../../Editor/Props';
import { MICROFENCE_LAYER_ID } from '../../../BeaconUtils';
import { ALL_GROUPS, ALL_LAYERS, FRESH, UNKNOWN_LAYER } from '../../../../../util/constants';
import { PRIMARY } from '../../../../../Style/GeoMobyBaseTheme';

export const layersList = (props: LayersProps & SearchListProps) => {
  return (
    <>
      {/* Layers */}
      {!(props.selectedGeofence || props.selectedMicrofence) &&
        props.searchType?.id === SearchTypeIDs.Geofences &&
        !props.selectedLayer && (
          <Grid container>
            <Tooltip title={'Select a group'}>
              <Typography style={{ color: PRIMARY, marginBottom: '10px' }}>
                Select a group
              </Typography>
            </Tooltip>
            {[
              { name: ALL_GROUPS, id: ALL_LAYERS },
              ...props.layerIds.filter(lyr => lyr.id !== MICROFENCE_LAYER_ID),
            ].map(layer => {
              return (
                <Accordion id={layer.id} key={layer.id} expanded={false} style={{ width: '98%' }}>
                  <AccordionSummary
                    onClick={async () => {
                      if (layer?.id?.includes(FRESH)) return;
                      props.setSelectedLayer(layer);
                      props.pageRef.current = 1;
                      await props.paginateGeofences({
                        ...props.geofenceFilter,
                        layerId: layer?.id,
                      } as GeofenceFilter);

                      if (layer?.id === ALL_LAYERS) {
                        props.setSelectedGeofence(undefined);
                        props.setSelectedMicrofence(undefined);
                        props.deselectAllFences();
                        props.setDrawType(undefined);
                        props.layerIds?.forEach(layer =>
                          props.changeVisibility(layer.id, layer.id !== MICROFENCE_LAYER_ID),
                        );
                        props.setLayerNameInput('');
                      }
                    }}
                    sx={{
                      '& .MuiAccordionSummary-content': {
                        width: '90%',
                      },
                    }}
                  >
                    <Tooltip title={layer.name}>
                      <Typography
                        style={{
                          width: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: layer.id === ALL_LAYERS ? 'bolder' : 'normal',
                        }}
                      >
                        {layer.name}
                      </Typography>
                    </Tooltip>
                  </AccordionSummary>
                </Accordion>
              );
            })}
          </Grid>
        )}
    </>
  );
};
