import { Accordion, AccordionSummary, Grid } from '@mui/material';
import { SearchTypeIDs } from '../../../../../util/enums';
import { SearchListProps } from '../../Props';
import {
  displayAssetIsActive,
  displayAssetLabel,
  findActiveAsset,
  selectedElement,
} from '../../../Helpers';
import { PortableAssetTool } from '../../../types';

export const useToolList = (
  props: SearchListProps & { availableTools: PortableAssetTool[]; isLoading: boolean },
) => {
  return (
    <>
      {!selectedElement(props) &&
        !props.isLoading &&
        props.searchType?.id === SearchTypeIDs.Tools &&
        props.availableTools.map(tool => {
          return (
            <Accordion id={tool.uuid} key={tool.uuid} style={{ width: '98%' }}>
              <AccordionSummary
                onClick={() => {
                  props.setSelectedTool(tool);
                  const activeTool = findActiveAsset(tool.id, props.activeAssets);
                  if (activeTool) {
                    props.setSelectedAsset({
                      id: activeTool.id,
                      label: tool.label,
                    });
                  }
                }}
                sx={{
                  '& .MuiAccordionSummary-content': {
                    width: '90%',
                  },
                }}
              >
                <Grid container direction="row">
                  <Grid item>{displayAssetLabel(tool.label, tool.id)}</Grid>
                  <Grid
                    item
                    style={{
                      width: 'max-content',
                      marginLeft: 'auto',
                    }}
                  >
                    {findActiveAsset(tool.id, props.activeAssets) && displayAssetIsActive()}
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
          );
        })}
    </>
  );
};
