import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { AssetUpdateType, LiveMapUpdate } from '../../../hooks/geomoby/LiveMapActions';
import { pointFromLatLon } from '../../../hooks/geomoby/LiveMapOlFunctions';
import {
  ACTIVE_MICROFENCE,
  ASSET_ID,
  ASSET_LABEL,
  LAST_SENSED_ISO8601,
  LOCALISED_SUFFIX,
  LOCAL_BEACONS,
  LOCATION_HISTORY,
  SENSED_ASSETS,
  SENSED_EXITED_ASSETS_IDS,
  TRACKING_BOUNDS,
} from '../../../util/constants';
import { distanceBetweenGpsCoords, minutesDifferent } from '../Helpers';
import {
  DeviceLocation,
  GeofenceEvent,
  idFromFenceEvent,
  SensedAssetsReport,
  SensedTriggeredEvent,
} from '../Messages';
import { MapState } from './Props';
import { transform } from 'ol/proj';
import {
  coordinatesEqual,
  LocationHistory,
  updateLocationTrace,
} from '../../../hooks/geomoby/locationTrace';
import { animateCoordinates, panTo } from '../../../hooks/geomoby/MapAnimation';
import { Coordinate } from 'ol/coordinate';
import { microfenceIsActive } from '../../../util/time';
import { SelectedAsset } from '../types';

export const localiseAssetId = (id: string) => `${id} ${LOCALISED_SUFFIX}`;
export const unlocaliseAssetId = (id: string) => id?.replace(' ' + LOCALISED_SUFFIX, '');
export const isLocalisedAssetId = (id: string) => id?.endsWith(' ' + LOCALISED_SUFFIX);

const doLocationUpdate = (mapState: MapState, deviceLocation: DeviceLocation) => {
  const newPoint = pointFromLatLon(deviceLocation.lat, deviceLocation.lon);
  const assetId = deviceLocation.source
    ? localiseAssetId(deviceLocation.source?.label)
    : deviceLocation.assetId;
  const asset = mapState.assets?.find(asset => asset.id === assetId);

  if (asset) {
    if (deviceLocation.source?.label) {
      const localBeacons: DeviceLocation[] = asset.point.get(LOCAL_BEACONS) || [];
      const updatedLocalBeacons = [
        deviceLocation,
        ...localBeacons.filter(
          beacon =>
            beacon.assetId !== deviceLocation.assetId &&
            distanceBetweenGpsCoords(
              [deviceLocation.lon, deviceLocation.lat],
              [beacon.lon, beacon.lat],
            ) < beacon.radius,
        ),
      ];
      asset.point.set(LOCAL_BEACONS, updatedLocalBeacons);
      if (JSON.stringify(mapState.selectedAsset?.id) === JSON.stringify(deviceLocation.id)) {
        mapState.setLocalBeacons(
          updatedLocalBeacons,
          transform([deviceLocation.lon, deviceLocation.lat], 'EPSG:4326', 'EPSG:3857'),
        );
      }
    }

    const allCoordsWithTime = asset.point.get(LOCATION_HISTORY) as LocationHistory;
    const previousCoordinates = allCoordsWithTime[allCoordsWithTime.length - 1];
    if (coordinatesEqual(previousCoordinates.coordinates, newPoint.getCoordinates())) return;

    const newTime = new Date(deviceLocation.timestamp);
    const showLabel = minutesDifferent(newTime, previousCoordinates.time);
    const newAllCoordsWithTime: LocationHistory = [
      ...allCoordsWithTime,
      {
        time: newTime,
        coordinates: newPoint.getCoordinates(),
        showLabel,
      },
    ];
    asset.point.set(LOCATION_HISTORY, newAllCoordsWithTime);

    const geometry = asset.point.getGeometry();
    if (geometry) {
      animateCoordinates(
        geometry.getCoordinates(),
        newPoint.getCoordinates(),
        300,
        (coordinate: Coordinate) => asset.point.setGeometry(new Point(coordinate)),
      );
    }
  } else {
    const point = new Feature(newPoint);
    const locationHistory: LocationHistory = [
      {
        time: new Date(deviceLocation.timestamp),
        coordinates: newPoint.getCoordinates(),
        showLabel: true,
      },
    ];
    point.set(ASSET_ID, deviceLocation.source?.id ?? deviceLocation.id);
    point.set(ASSET_LABEL, assetId);
    point.set(LOCATION_HISTORY, locationHistory);
    point.set(LOCAL_BEACONS, deviceLocation.beaconId ? [deviceLocation] : undefined);
    mapState.assets = [
      { id: assetId, point: point },
      ...(mapState.assets?.filter(a => a.id !== assetId) ?? []),
    ];
    mapState.assetSrc.addFeature(point);
  }

  if (
    mapState.selectedAsset?.following &&
    JSON.stringify(deviceLocation.source?.id ?? deviceLocation.id) ===
      JSON.stringify(mapState.selectedAsset.id)
  ) {
    panTo(mapState.map.getView(), newPoint.getCoordinates(), (b: boolean) => {});
  }

  if (
    asset &&
    !!mapState.selectedAsset &&
    JSON.stringify(mapState.selectedAsset.id) === JSON.stringify(deviceLocation.id)
  ) {
    updateLocationTrace(mapState, asset.point);
  }
};

const doGeofenceUpdate = (
  mapState: MapState,
  geofenceEvent: GeofenceEvent,
  cid: string,
  pid: string,
) => {
  const newPoint: Point = pointFromLatLon(geofenceEvent.y, geofenceEvent.x);
  const geofenceEvents = mapState.fenceEvents;
  const eventId = idFromFenceEvent(geofenceEvent.assetId, geofenceEvent.event, geofenceEvent.type);

  let isTrackingBoundsLayer;
  const trackingLayer = mapState.layers?.find(layer => layer.name === TRACKING_BOUNDS);
  if (trackingLayer) {
    isTrackingBoundsLayer = trackingLayer.source
      .getSource()
      .getFeatures()
      .some(feature => feature.getProperties().id === geofenceEvent.fenceId);
  }

  if (isTrackingBoundsLayer && geofenceEvent.event.toLowerCase() === 'exited') {
    [geofenceEvent.assetId, localiseAssetId(geofenceEvent.assetId)].forEach(assetId => {
      const pin = mapState.assets?.find(a => a.id === assetId);
      if (pin) {
        mapState.assets = mapState.assets?.filter(a => a.id !== assetId);
        mapState.assetSrc.removeFeature(pin.point);
      }
    });
  }

  const foundFenceEvent = geofenceEvents?.find(event => event.id === eventId);
  if (foundFenceEvent) {
    foundFenceEvent.point.setGeometry(newPoint);
  } else {
    const point = new Feature(newPoint);
    point.set('eventId', eventId);
    point.set('event', '' + geofenceEvent.event);
    mapState.fenceEvents = [
      { id: eventId, point },
      ...(mapState.fenceEvents?.filter(event => event.id !== eventId) ?? []),
    ];
    mapState.feSrc.addFeature(point);
  }
};

const doMicrofenceUpdate = (mapState: MapState, sensedAssetsReport: SensedAssetsReport) => {
  const microfence = mapState.microfences.find(
    ({ data, feature: f }) =>
      JSON.stringify(data.assetId) === JSON.stringify(sensedAssetsReport.id),
  );
  if (!microfence) return;

  microfence.feature.set(SENSED_ASSETS, sensedAssetsReport.assets);
  const previousLastSensed = microfence.feature.get(LAST_SENSED_ISO8601);
  const lastSensed = previousLastSensed
    ? new Date(
        Math.max(
          new Date(previousLastSensed).getTime(),
          new Date(sensedAssetsReport.timestamp).getTime(),
        ),
      ).toISOString()
    : sensedAssetsReport.timestamp;
  microfence.feature.set(LAST_SENSED_ISO8601, lastSensed);
};

const doMicrofenceTriggeredUpdate = (
  mapState: MapState,
  sensedTriggeredEvent: SensedTriggeredEvent,
) => {
  const microfence = mapState.microfences.find(
    ({ data, feature: f }) =>
      JSON.stringify(data.assetId) === JSON.stringify(sensedTriggeredEvent.id),
  );
  if (!microfence) return;

  const previousExited: Record<string, string | unknown>[] =
    microfence.feature.get(SENSED_EXITED_ASSETS_IDS) ?? [];
  if (sensedTriggeredEvent.entered) {
    microfence.feature.set(
      SENSED_EXITED_ASSETS_IDS,
      previousExited.filter(
        e => JSON.stringify(e) !== JSON.stringify(sensedTriggeredEvent.sensedId),
      ),
    );
  } else {
    microfence.feature.set(SENSED_EXITED_ASSETS_IDS, [
      sensedTriggeredEvent.sensedId,
      ...previousExited,
    ]);
  }
};

export const updateMicrofencesActivity = (mapState: MapState | undefined, now: Date) => {
  mapState?.microfences.forEach(microfence => {
    const lastSensedIso8601 = microfence.feature.get(LAST_SENSED_ISO8601);
    const wasActive = !!microfence.feature.get(ACTIVE_MICROFENCE);
    const nowActive = microfenceIsActive(lastSensedIso8601, now);
    if (wasActive !== nowActive) {
      microfence.feature.set(ACTIVE_MICROFENCE, nowActive);
    }
  });
};

export const doMapUpdates = (
  mapState: MapState,
  liveUpdates: LiveMapUpdate[],
  cid: string,
  pid: string,
) => {
  liveUpdates.forEach(update => {
    switch (update.type) {
      case AssetUpdateType.Location:
        doLocationUpdate(mapState, update.payload);
        break;
      case AssetUpdateType.GeofenceEvent:
        doGeofenceUpdate(mapState, update.payload, cid, pid);
        break;
      case AssetUpdateType.SensedTriggered:
        doMicrofenceTriggeredUpdate(mapState, update.payload);
        break;
      case AssetUpdateType.SensedAssets:
        doMicrofenceUpdate(mapState, update.payload);
        break;
      case AssetUpdateType.FullStateUpdate: {
        update.payload.assets.forEach(asset => {
          if (asset.assetState.lastFenceEvent) {
            doGeofenceUpdate(mapState, asset.assetState.lastFenceEvent, cid, pid);
          }
          if (asset.assetState.lastLocation) {
            doLocationUpdate(mapState, asset.assetState.lastLocation);
          }
          if (
            asset.assetState.recentSensedTriggered &&
            asset.assetState.recentSensedTriggered.length > 0
          ) {
            asset.assetState.recentSensedTriggered.map(sensedTriggered =>
              doMicrofenceTriggeredUpdate(mapState, sensedTriggered),
            );
          }
          if (asset.assetState.lastSensed) {
            doMicrofenceUpdate(mapState, asset.assetState.lastSensed);
          }
        });
        break;
      }
    }
  });
};

export const updateAssetSelection = (
  mapState: MapState,
  selectedAsset: SelectedAsset | undefined,
) => {
  const sameAssetAsMapState =
    !!mapState.selectedAsset &&
    !!selectedAsset &&
    JSON.stringify(mapState.selectedAsset.id) === JSON.stringify(selectedAsset.id);
  if (sameAssetAsMapState || (!mapState.selectedAsset && !selectedAsset)) return;

  const foundAssetFromMapState = mapState.assets?.find(
    a => mapState.selectedAsset?.label && a.id === mapState.selectedAsset.label,
  );
  if (mapState.selectedAsset && !selectedAsset && foundAssetFromMapState) {
    foundAssetFromMapState.point.set('selected', false);
    mapState.selectedAsset = undefined;
    return;
  }

  const foundNewAsset = mapState.assets?.find(
    a => selectedAsset?.label && a.id === selectedAsset.label,
  );
  if (!mapState.selectedAsset && selectedAsset && foundNewAsset) {
    foundNewAsset.point.set('selected', true);
    mapState.selectedAsset = selectedAsset;
    return;
  }

  const differentAssetToMapState =
    mapState.selectedAsset &&
    selectedAsset &&
    JSON.stringify(mapState.selectedAsset.id) !== JSON.stringify(selectedAsset.id);
  if (differentAssetToMapState && selectedAsset?.label && mapState.selectedAsset?.label) {
    foundAssetFromMapState?.point.set('selected', false);
    foundNewAsset?.point.set('selected', true);
    mapState.selectedAsset = selectedAsset;
  }
};

export const doPinUpdate = (
  coords: number[],
  props:
    | { address: string; isLastKnownLocation?: never }
    | { address?: never; isLastKnownLocation: boolean },
): Feature<Point> => {
  const feature = new Feature(new Point(coords));
  const displayedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');

  feature.set(
    'searchedCoordinates',
    parseFloat(displayedCoords[1].toFixed(5)) + ', ' + parseFloat(displayedCoords[0].toFixed(5)),
  );
  feature.set('searchedAddress', props.address);
  feature.set('isLastKnownLocation', props.isLastKnownLocation);

  return feature;
};
