import { GridRowData } from '@material-ui/data-grid';
import { Extent } from 'ol/extent';
import { FC, useMemo, useState } from 'react';
import { Item, Sidebar } from '../../../Common/Sidebar';
import { MapRenderer } from '../../../Components/Map/LiveAndReplay/MapRenderer';
import { useGeomobyLiveStream } from '../../../hooks/geomoby/useGeomobyLiveStream';
import { useLiveMapLoader } from '../../../hooks/geomoby/useLiveMapLoader';
import { RenderAssetState } from '../AssetRenderer';
import { AssetState } from '../Messages';
import { SidebarAndMap } from '../SidebarAndMap/SidebarAndMap';
import { WelfareCheck } from '../WelfareCheck/WelfareCheck';
import { LocationDisplayType, LocationSearchData } from '../Toolbar/LocationSearch';
import { MICROFENCE } from '../BeaconUtils';
import { SearchList } from '../LiveAndReplay/Sidebar/Search/SearchList';
import {
  AssetFilter,
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  Asset,
  PortableAssetTool,
  SearchType,
  ToolFilter,
  ToolType,
  SelectedAsset,
} from '../types';
import { Feature } from 'ol';
import { AssetLatestLocation } from '../SidebarAndMap/Tiles/AssetLastestLocation';
import { addWeeks } from 'date-fns';
import { TRACKED_DEVICE } from '../../../util/constants';
import { MapType } from '../../../util/enums';
import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS } from '../../../store/tools';
import { getLocalisedAssets, getSearchSelectOptions } from '../Helpers';

export const LiveMap: FC = () => {
  const liveStreamData = useGeomobyLiveStream({ useFor: 'livemap' });
  const liveStreamState = liveStreamData.state;
  const [streamedMicrofences, setStreamedMicrofences] = useState<SelectedAsset[]>([]);
  const [locationDisplay, setLocationDisplay] = useState<LocationDisplayType>();
  const liveMapData = useLiveMapLoader();
  const loadedMicrofences = useMemo(
    () =>
      liveMapData.result
        ? liveMapData.result.microfences.map(({ data }) => ({
            id: data.assetId,
            label: data.fenceName ?? data.fenceId,
          }))
        : [],
    [liveMapData.result],
  );
  const streamedAssets: SelectedAsset[] = useMemo(
    () =>
      getSearchSelectOptions(liveStreamState.assets).map(o => {
        const microfence = loadedMicrofences.find(
          m => JSON.stringify(m.id) === JSON.stringify(o.id),
        );
        const label = microfence?.label ?? o.label;
        return { ...o, label, prefix: microfence ? MICROFENCE : TRACKED_DEVICE };
      }),
    [liveStreamState.assets, loadedMicrofences],
  );

  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [assetFilter, setAssetFilter] = useState<AssetFilter | undefined>();
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [deselectFence, setDeselectFence] = useState<boolean>(false);
  const [geofenceFilter, setGeofenceFilter] = useState<GeofenceFilter | undefined>();
  const [microfenceFilter, setMicrofenceFilter] = useState<MicrofenceFilter | undefined>();
  const [layerFilter, setLayerFilter] = useState<LayerFilter | undefined>();
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData | undefined>();
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [range, setRange] = useState<[Date | null, Date | null]>([
    addWeeks(new Date(), -1),
    new Date(),
  ]);
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [resetStyling, setResetStyling] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<SearchType | undefined>();
  const [selectedAsset, setSelectedAsset] = useState<SelectedAsset | undefined>();
  const [selectedBeacon, setSelectedBeacon] = useState<Asset | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<Asset | undefined>();
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const [selectedGPSTracker, setSelectedGPSTracker] = useState<Asset | undefined>();
  const [selectedMicrofence, setSelectedMicrofence] = useState<GridRowData | undefined>();
  const [selectedTool, setSelectedTool] = useState<PortableAssetTool | undefined>();
  const [selectedFromMap, setSelectedFromMap] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [toolFilter, setToolFilter] = useState<ToolFilter | undefined>();
  const [toolTypes, setToolTypes] = useState<ToolType[]>([]);
  const [userExtent, setUserExtent] = useState<Extent | undefined>();

  const knownTools = useAtomValue(KNOWN_TOOLS);

  const assetIsSelected = (): boolean => {
    return (
      !!selectedAsset ||
      !!selectedBeacon ||
      !!selectedDevice ||
      !!selectedGeofence ||
      !!selectedGPSTracker ||
      !!selectedMicrofence ||
      !!selectedTool
    );
  };

  const uiWfcAllow = () => {
    if (resetStyling) {
      setResetStyling(false);
    }
  };

  const uiWfcClear = () => {
    if (!resetStyling) {
      setResetStyling(true);
    }
  };

  return (
    <SidebarAndMap
      sidebar={
        <Sidebar>
          <WelfareCheck allow={uiWfcAllow} reset={uiWfcClear} />
          <Item>
            {liveMapData.result && (
              <SearchList
                layers={liveMapData.result.layers
                  .map(({ id, name }) => ({ id, name }))
                  ?.sort((a, b) => a.name.localeCompare(b.name))}
                microfences={liveMapData.result.microfences.sort((a, b) =>
                  a.feature.get('name').localeCompare(b.feature.get('name')),
                )}
                selectedBeacon={selectedBeacon}
                setSelectedBeacon={setSelectedBeacon}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                selectedGeofence={selectedGeofence}
                setSelectedGeofence={setSelectedGeofence}
                selectedMicrofence={selectedMicrofence}
                setSelectedMicrofence={setSelectedMicrofence}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                selectedGPSTracker={selectedGPSTracker}
                setSelectedGPSTracker={setSelectedGPSTracker}
                toolTypes={toolTypes}
                setToolTypes={setToolTypes}
                setExtent={setUserExtent}
                searchType={searchType}
                setSearchType={setSearchType}
                layerFilter={layerFilter}
                setLayerFilter={setLayerFilter}
                assetFilter={assetFilter}
                setAssetFilter={setAssetFilter}
                toolFilter={toolFilter}
                setToolFilter={setToolFilter}
                geofenceFilter={geofenceFilter}
                setGeofenceFilter={setGeofenceFilter}
                microfenceFilter={microfenceFilter}
                setMicrofenceFilter={setMicrofenceFilter}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                refreshSearch={refreshSearch}
                setRefreshSearch={setRefreshSearch}
                activeAssets={streamedAssets}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
                setLocationSearchData={setLocationSearchData}
                currentCenter={currentCenter}
                replayStartDateTime={null}
                locationDisplay={locationDisplay}
                setLocationDisplay={setLocationDisplay}
                setNavigateTo={setNavigateTo}
                setSelectedFromMap={setSelectedFromMap}
                setDeselectFence={setDeselectFence}
              ></SearchList>
            )}
            <>
              <AssetLatestLocation
                assetStates={liveStreamState.assets.map(asset => asset.assetState)}
                microfences={liveMapData.result ? liveMapData.result.microfences : []}
                activeAssets={streamedAssets}
                range={range}
                setRange={setRange}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                selectedGPSTracker={selectedGPSTracker}
                setSelectedGPSTracker={setSelectedGPSTracker}
                setSelectedMicrofence={setSelectedMicrofence}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                selectedBeacon={selectedBeacon}
                setSelectedBeacon={setSelectedBeacon}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
                setLocationDisplay={setLocationDisplay}
                setLocationSearchData={setLocationSearchData}
              ></AssetLatestLocation>
            </>
            {assetIsSelected() && (
              <>
                {getLocalisedAssets(selectedAsset, liveStreamState.assets, selectedGeofence).map(
                  (localisedAsset: AssetState) =>
                    RenderAssetState(localisedAsset, knownTools, !selectedAsset),
                )}
              </>
            )}
          </Item>
        </Sidebar>
      }
      // Rename selectedAsset to selectedActiveAsset in Phase 2 LTP-1192
      map={
        <MapRenderer
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          streamedData={liveStreamData}
          selectedGeofence={selectedGeofence}
          setSelectedGeofence={setSelectedGeofence}
          selectedMicrofence={selectedMicrofence}
          setSelectedMicrofence={setSelectedMicrofence}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedDevice={setSelectedDevice}
          setSelectedTool={setSelectedTool}
          setSelectedGPSTracker={setSelectedGPSTracker}
          userExtent={userExtent}
          setUserExtent={setUserExtent}
          onExtentChanged={liveMapData.setBounds}
          liveMapStaticData={liveMapData.result}
          fencesLoading={liveMapData.loading}
          resetStyling={resetStyling}
          mapType={MapType.OUTDOOR_LIVE_MAP}
          locationSearchData={locationSearchData}
          setCurrentCenter={setCurrentCenter}
          locationDisplay={locationDisplay}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          selectedFromMap={selectedFromMap}
          setSelectedFromMap={setSelectedFromMap}
          deselectFence={deselectFence}
          setDeselectFence={setDeselectFence}
          setLocationDisplay={setLocationDisplay}
          setLocationSearchData={setLocationSearchData}
          getNow={() => new Date()}
        />
      }
    />
  );
};
