import { option } from 'fp-ts/es6';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import RenderFeature from 'ol/render/Feature';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import ImageStyle from 'ol/style/Image';
import { StyleFunction } from 'ol/style/Style';
import {
  ACTIVE_MICROFENCE,
  ASSET_ID,
  ASSET_LABEL,
  LAST_SENSED_ISO8601,
  LOCAL_BEACONS,
  SENSED_ASSETS,
} from '../../util/constants';
import { SensedAssetsReport, ReportBeacon } from './Messages';
import { Color as OlColor } from 'ol/color';
import { FenceZIndexes } from '../../util/ZIndexes';
import CircleStyle from 'ol/style/Circle';
import { microfenceIsActive } from '../../util/time';
import { MicrofenceEntity } from '../../util/enums';
import { ACTIVE_GREEN, PURPLE, RED, WHITE } from '../../Style/GeoMobyBaseTheme';

export const LOCALISED_MAX_DISTANCE_M = 15;

export const MICROFENCE_LAYER_ID = '__microfences__';
export const MICROFENCE_LAYER_LABEL = 'MicroFences';
export const MICROFENCE = 'Microfence';
export const MICROFENCE_PROP_LABELS = {
  boundaryRssi: 'Boundary RSSI',
  timeoutSeconds: 'Timeout (seconds)',
};
export const MICROFENCE_DEFAULT_PROPS = {
  [MICROFENCE_PROP_LABELS.boundaryRssi]: -90,
  [MICROFENCE_PROP_LABELS.timeoutSeconds]: 15,
};
export const POINT = '__point__';

export interface BeaconStylable {
  uuid: string;
  distance: number;
}

export function iconFromElement(
  el: HTMLImageElement,
  w: number,
  h: number,
  ax: number,
  ay: number,
  options?: {
    scale?: number;
    opcaity?: number;
    color?: OlColor | string;
  },
): Icon {
  return new Icon({
    img: el,
    imgSize: [w, h],
    anchor: [ax, ay],
    scale: options?.scale === undefined ? 1 : options.scale,
    opacity: options?.opcaity === undefined ? 1 : options.opcaity,
    color: options?.color,
  });
}

export const gatewaySensorIconStyled = (options: {
  color?: OlColor | string;
  scale?: number;
  opacity?: number;
}) =>
  iconFromElement(
    document.getElementById('RouterFar') as HTMLImageElement,
    46,
    46,
    0.5,
    0.5,
    options,
  );

const bcnStyle = (el: string, w: number, h: number) =>
  iconFromElement(document.getElementById(el) as HTMLImageElement, w, h, 0.5, 0.5);

export enum MessageType {
  Undefined = 'Undefined',
  NoOp = 'NoOp',
  DeviceLocation = 'DeviceLocation',
  GeofenceEvent = 'GeofenceEvent',
  SensedBeacons = 'SensedBeacons',
  LocalBeacons = 'LocalBeacons',
}

export type DevBeacon = {
  readonly beacon_id: string;
  readonly x: number;
  readonly y: number;
  readonly distance: number;
};

export type BeaconProperties = {
  enter_rssi: number;
  exit_rssi: number;
  major: number;
  minor: number;
  path: string;
  uuid: string;
  beacon_id: string; //name displayed on ui
};
export type BeaconIdentificators = Omit<
  BeaconProperties,
  'enter_rssi' | 'exit_rssi' | 'path' | 'beacon_id'
>;

enum BeaconProximity {
  Unknown = 'Unknown',
  Immediate = 'Immediate',
  Near = 'Near',
  Far = 'Far',
}
export function proxFromDist(d: number): BeaconProximity {
  if (d < 2) return BeaconProximity.Immediate;
  else if (d < 10) return BeaconProximity.Near;
  else if (d < 20) return BeaconProximity.Far;
  else return BeaconProximity.Unknown;
}

function proxFromSb(sb: SensedAssetsReport, f: Feature<Geometry> | RenderFeature): BeaconProximity {
  const id = option.fromNullable(f.get('id'));
  if (option.isNone(id)) {
    return BeaconProximity.Unknown;
  }
  const match = sb.assets.filter(b => b.beaconId === id?.value).map(b => b.distance);
  if (match.length !== 1 || match[0] === undefined) {
    return BeaconProximity.Unknown;
  }
  return proxFromDist(match[0]);
}

export function createStyleCache(): Map<string, Style> {
  return new Map<string, Style>();
}

export const PIN_ELEMENT_SCALE = 0.07;
const pinFromElement = (id: string) =>
  iconFromElement(document.getElementById(id) as HTMLImageElement, 512, 512, 0.5, 1, {
    scale: PIN_ELEMENT_SCALE,
  });
const walkingBlueIcon = pinFromElement('walkingBlue');
const walkingPurpleIcon = pinFromElement('walkingPurple');
const walkingRedIcon = pinFromElement('walkingRed');
const walkingGreenIcon = pinFromElement('walkingGreen');
const walkingOrangeIcon = pinFromElement('walkingOrange');
const locationOnBlueIcon = pinFromElement('locationOnBlue');
const locationOnPurpleIcon = pinFromElement('locationOnPurple');
const locationOnRedIcon = pinFromElement('locationOnRed');

export const createCountStyle = (
  count: number,
  options?: {
    radius?: number;
    offset_x?: number;
    offset_y?: number;
    text_offset_x?: number;
    text_offset_y?: number;
    active?: boolean;
  },
) =>
  new Style({
    image: new CircleStyle({
      displacement: [12.5, 13],
      radius: count === 0 ? 6 : 10,
      fill: new Fill({
        color: options?.active ? ACTIVE_GREEN : RED,
      }),
    }),
    text: new Text({
      text: count === 0 ? '' : count > 99 ? '99+' : count.toString(),
      fill: new Fill({
        color: WHITE,
      }),
      font: `10px Open Sans, Montserrat, Arial, sans-serif`,
      stroke: new Stroke({
        color: WHITE,
        width: 1,
      }),
      offsetX: 13,
      offsetY: -13,
      scale: 0.9,
    }),
    zIndex: FenceZIndexes.MARKER_FENCE_Z_INDEX + 1,
  });
const createRadiusStyle = (radiusM: number) =>
  new Style({
    renderer: (coords, { context, resolution }) => {
      const [x, y] = coords;
      if (typeof x === 'number' && typeof y === 'number') {
        // Draw the radius for picking up local beacons
        const radius = radiusM / resolution;

        const gradient = context.createRadialGradient(x, y, 0, x, y, radius * 1.4);
        gradient.addColorStop(0, `${PURPLE}00`);
        gradient.addColorStop(0.6, `${PURPLE}44`);
        gradient.addColorStop(1, PURPLE);

        context.beginPath();
        context.arc(x, y, radius, 0, 2 * Math.PI, true);
        context.fillStyle = gradient;
        context.fill();

        context.arc(x, y, radius, 0, 2 * Math.PI, true);
        context.lineWidth = 0;
        context.strokeStyle = '#883DE800';
        context.stroke();
      } else {
        throw new Error('Unexpected coordinate parameter for Beacon renderer');
      }
    },
    zIndex: 1,
  });

function styleForWalkerId(
  assetId: { [key: string]: string },
  selected: boolean,
  wfCheck: option.Option<boolean>,
  localBeacons?: ReportBeacon[],
): Style[] {
  const icon = assetId?.gpsTrackerId
    ? iconForGPSTracker(selected, localBeacons)
    : iconForWalker(selected, wfCheck, localBeacons);
  const styles = [
    new Style({
      image: icon,
    }),
  ];

  if (localBeacons !== undefined) {
    if (localBeacons.length > 0) {
      styles.push(createCountStyle(localBeacons.length, { active: true }));
    }
    styles.push(createRadiusStyle(LOCALISED_MAX_DISTANCE_M));
  }
  return styles;
}

function iconForGPSTracker(selected: boolean, beacons?: unknown[]): ImageStyle {
  return selected ? locationOnRedIcon : beacons ? locationOnPurpleIcon : locationOnBlueIcon;
}

function iconForWalker(
  selected: boolean,
  wfCheck: option.Option<boolean>,
  beacons?: unknown[],
): ImageStyle {
  if (option.isSome(wfCheck)) return wfCheck.value ? walkingGreenIcon : walkingOrangeIcon;
  const unselectedColor = beacons ? walkingPurpleIcon : walkingBlueIcon;
  return selected ? walkingRedIcon : unselectedColor;
}

function keyForWalker(
  assetLabel: string,
  selected: boolean,
  wfCheck: option.Option<boolean>,
  nearby?: unknown,
): string {
  if (option.isSome(wfCheck)) return '' + assetLabel + '_wf_' + (wfCheck.value ? 'ok' : 'notok');
  return assetLabel + '_' + (selected ? 'selected' : 'not_selected') + JSON.stringify(nearby);
}

export function walkerStyle(cache: Map<string, Style | Style[]>): StyleFunction {
  return (f0: Feature<Geometry> | RenderFeature, p1) => {
    const assetId = f0.get(ASSET_ID);
    const assetLabel = f0.get(ASSET_LABEL) ?? 'Unknown';
    const selected = f0.get('selected') ?? false;
    const wfCheck = option.fromNullable(f0.get('wfCheck'));
    const localBeacons = f0.get(LOCAL_BEACONS);
    const key = keyForWalker(assetLabel, selected, wfCheck, localBeacons);
    if (cache.has(key)) {
      const cachedStyle = cache.get(key);
      if (cachedStyle !== undefined) return cachedStyle;
    }
    const style = styleForWalkerId(assetId, selected, wfCheck, localBeacons);
    cache.set(key, style);
    return style;
  };
}

function styleForMircofence({
  id,
  selected,
  sensedCount,
  type,
  active,
  showActivity,
}: {
  id: string;
  selected: boolean;
  sensedCount: number;
  type: MicrofenceEntity;
  active: boolean;
  showActivity: boolean;
}): Style[] {
  let icon = selected
    ? gatewaySensorIconStyled({ opacity: 1 })
    : gatewaySensorIconStyled({ color: [128, 128, 128], opacity: 0.5, scale: 0.8 }); /* #808080 */

  if (type === MicrofenceEntity.Beacon) {
    icon = selected
      ? beaconSensorIconStyled({ opacity: 1 })
      : beaconSensorIconStyled({ color: [128, 128, 128], opacity: 0.5, scale: 0.8 }); /* #808080 */
  } else if (type === MicrofenceEntity.Device) {
    icon = selected
      ? deviceSensorIconStyled({ opacity: 1 })
      : deviceSensorIconStyled({ color: [128, 128, 128], opacity: 0.5, scale: 0.8 }); /* #808080 */
  }

  const mainStyle = new Style({
    image: icon,
    zIndex: selected ? FenceZIndexes.MARKER_FENCE_Z_INDEX + 1 : FenceZIndexes.MARKER_FENCE_Z_INDEX,
  });

  if (!showActivity) return [mainStyle];

  const countStyle = createCountStyle(sensedCount, {
    active,
    offset_x: 20,
    offset_y: 20,
    text_offset_y: 13,
    text_offset_x: 13,
  });

  if (active || sensedCount > 0) {
    return [mainStyle, countStyle, createRadiusStyle(LOCALISED_MAX_DISTANCE_M)];
  }
  return [mainStyle, countStyle];
}

function keyForMicrofence(
  aid: string,
  selected: boolean,
  sensedCount: number,
  active: boolean,
): string {
  return `${aid}_${selected ? 'selected' : 'not_selected'}_${sensedCount}_${
    active ? 'active' : 'inactive'
  }`;
}

export function microfenceStyle(
  cache: Map<string, Style | Style[]>,
  options: { showActivity: boolean },
): StyleFunction {
  return (f0: Feature<Geometry> | RenderFeature, p1) => {
    const fid = f0.get('fenceId');
    const fname = f0.get('fenceName') ?? fid;
    const sensedAssets = f0.get(SENSED_ASSETS) ?? [];
    const sensedCount = Array.isArray(sensedAssets) ? sensedAssets.length : 0;
    const active = !!f0.get(ACTIVE_MICROFENCE);
    const key = keyForMicrofence(fid, !!f0.get('selected'), sensedCount, active);
    if (cache.has(key)) {
      const cachedStyle = cache.get(key);
      if (cachedStyle !== undefined) return cachedStyle;
    }
    const style = styleForMircofence({
      id: fid,
      selected: !!f0.get('selected'),
      sensedCount,
      type: f0.get('microfenceType'),
      active,
      showActivity: options.showActivity,
    });
    cache.set(key, style);
    return style;
  };
}

const dwellIcon = iconFromElement(
  document.getElementById('Dwell') as HTMLImageElement,
  21,
  22,
  0.5,
  1,
  { scale: 1.5 },
);
const enteredIcon = iconFromElement(
  document.getElementById('EntryBlue') as HTMLImageElement,
  21,
  22,
  0.5,
  1,
);
const exitedIcon = iconFromElement(
  document.getElementById('ExitRed') as HTMLImageElement,
  24,
  24,
  0.5,
  1,
);
export const beaconSensorIcon = iconFromElement(
  document.getElementById('BeaconFar') as HTMLImageElement,
  46,
  46,
  0.5,
  0.5,
);
export const deviceSensorIcon = iconFromElement(
  document.getElementById('SmartphoneFar') as HTMLImageElement,
  46,
  46,
  0.5,
  0.5,
);
export const gatewaySensorIcon = iconFromElement(
  document.getElementById('RouterFar') as HTMLImageElement,
  46,
  46,
  0.5,
  0.5,
);
export const beaconSensorIconStyled = (options: {
  color?: OlColor | string;
  scale?: number;
  opacity?: number;
}) =>
  iconFromElement(
    document.getElementById('BeaconFar') as HTMLImageElement,
    46,
    46,
    0.5,
    0.5,
    options,
  );

export const deviceSensorIconStyled = (options: {
  color?: OlColor | string;
  scale?: number;
  opacity?: number;
}) =>
  iconFromElement(
    document.getElementById('SmartphoneFar') as HTMLImageElement,
    46,
    46,
    0.5,
    0.5,
    options,
  );

function styleForFenceEvent(eventId: string, event: string): Style {
  const icon = event === 'Dwell' ? dwellIcon : event === 'Entered' ? enteredIcon : exitedIcon;
  return new Style({
    image: icon,
  });
}
export function fenceEventStyle(cache: Map<string, Style>): StyleFunction {
  return (f0: Feature<Geometry> | RenderFeature, p1) => {
    const eventId = f0.get('eventId') ?? 'Unknown';
    const eventType = f0.get('event') ?? 'Entered';
    if (cache.has(eventId)) {
      const cachedStyle = cache.get(eventId);
      if (cachedStyle !== undefined) return cachedStyle;
    }
    const style = styleForFenceEvent(eventId, eventType);
    cache.set(eventId, style);
    return style;
  };
}
