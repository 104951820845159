/* eslint-disable no-var */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array } from 'fp-ts';
import { pipe } from 'fp-ts/es6/pipeable';
import { Collection, Feature, Overlay, View } from 'ol';
import { always, click, never, platformModifierKeyOnly, primaryAction } from 'ol/events/condition';
import Circle from 'ol/geom/Circle';
import Geometry from 'ol/geom/Geometry';
import GeometryType from 'ol/geom/GeometryType';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Modify, Select, Snap, Translate } from 'ol/interaction';
import Draw from 'ol/interaction/Draw';
import { Vector as VectorLayer } from 'ol/layer';
import olMap from 'ol/Map';
import 'ol/ol.css';
import { Cluster, Vector as VectorSource } from 'ol/source';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { useCallback, useEffect, useRef, useState } from 'react';
import { polygonPointsFromJson, jsonFromLayer } from '../../../hooks/geomoby/LiveMapOlFunctions';
import { fitToExtent, getCoordinateDifference } from '../../../hooks/geomoby/MapAnimation';
import {
  Bounds,
  createMap,
  createOutdoorMapDefaults,
  MapSourceType,
  selectedMicrofenceBeaconStyle,
  defaultMicrofenceBeaconStyle,
  selectedMicrofenceGatewayStyle,
  selectedMicrofenceDeviceStyle,
  defaultMicrofenceGatewayStyle,
  defaultMicrofenceDeviceStyle,
  ReassignedFence,
  selectedBreachFenceStyle,
  breachFenceStyle,
  selectedBufferFenceStyle,
  bufferFenceStyle,
  selectedClearedFenceStyle,
  clearedFenceStyle,
  selectedDefaultFenceStyle,
  defaultFenceStyle,
  selectedTrackingFenceStyle,
  trackingFenceStyle,
  stylesForLineFence,
  getCoordsForNewTripwire,
  styleForInteraction,
  geometryIsLine,
} from '../MapDefaults';
import {
  getMicrofencesVectorLayer,
  getVectorLayer,
  getVectorLayers,
  setSelectedLayerStyle,
  updateFenceOnMap,
} from './EditorFunctions';
import { MapContainer } from '../MapContainer/MapContainer';
import { SidebarAndMap } from '../SidebarAndMap/SidebarAndMap';
import { ZoomIn } from '../Toolbar/ZoomTools/ZoomIn';
import { ZoomOut } from '../Toolbar/ZoomTools/ZoomOut';
import { MapToolbar } from '../Toolbar/MapToolbar';
import { ChangeLayer } from '../Toolbar/LayerTools/ChangeLayer';
import { ChangeMapSourceType } from '../Toolbar/LayerTools/ChangeMapSourceType';
import { useAtomValue, useSetAtom } from 'jotai';
import { CID, PID } from '../../../store/user';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import { containsExtent, Extent, getCenter } from 'ol/extent';
import { StyleFunction } from 'ol/style/Style';
import RenderFeature from 'ol/render/Feature';
import CircleStyle from 'ol/style/Circle';
import {
  CLUSTER_MAX_ZOOM,
  selectedFenceClusterPointStyle,
  selectedLayerClusterPointStyle,
  styleFunctionForClusteredFeatures,
  ZOOM_THRESHOLD,
} from '../ClusteringFunctions';
import { transform, transformExtent } from 'ol/proj';
import { debounce } from 'lodash';
import LineString from 'ol/geom/LineString';
import {
  MICROFENCE_DEFAULT_PROPS,
  MICROFENCE_LAYER_ID,
  MICROFENCE_LAYER_LABEL,
  MICROFENCE_PROP_LABELS,
} from '../BeaconUtils';
import BaseVectorLayer from 'ol/layer/BaseVector';
import CanvasVectorLayerRenderer from 'ol/renderer/canvas/VectorLayer';
import CanvasVectorTileLayerRenderer from 'ol/renderer/canvas/VectorTileLayer';
import CanvasVectorImageLayerRenderer from 'ol/renderer/canvas/VectorImageLayer';
import WebGLPointsLayerRenderer from 'ol/renderer/webgl/PointsLayer';
import { ModifyEvent } from 'ol/interaction/Modify';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { SaveResult, SAVE_NOTIFICATION } from '../../../store/notifications';
import { FenceZIndexes } from '../../../util/ZIndexes';
import { LoadIndicator } from '../Toolbar/LayerTools/LoadIndicator';
import { getLength } from 'ol/sphere';
import { Button, Dialog, DialogActions, DialogTitle, Grid } from '@mui/material';
import { ToolPanel } from '../ControlPanels/ToolPanel';
import { MAP_API_KEYS } from '../../../store/map';
import { LocationDisplayType, LocationSearch, LocationSearchData } from '../Toolbar/LocationSearch';
import { SearchList } from '../Editor/Sidebar/Search/SearchList';
import { GridRowData } from '@material-ui/data-grid';
import {
  FenceNameIdZone,
  GeofenceFilter,
  GeomobyOverride,
  MicrofenceAssetId,
  MicrofenceFilter,
  NameId,
  SearchType,
} from '../types';
import { FilterComponent } from '../Editor/Sidebar/Search/FilterComponent';
import { normaliseErrorMessage } from '../../../util/ErrorMessages';
import {
  BufferShapeType,
  DrawType,
  EditType,
  EntityType,
  FenceGeometryType,
  FenceZone,
  GeofenceEntityType,
  GeomobyPropertiesValues,
  MapType,
  MeasurementType,
  MicrofenceEntity,
  MicrofenceZone,
  RequestType,
  SearchTypeIDs,
  SearchTypeValue,
} from '../../../util/enums';
import {
  findValueWithMoreThan256Chars,
  findValueWithNonWholeNumber,
  findInvalidAlertStyleValue,
  findNonBooleanValue,
} from './Sidebar/Geofence/GeomobyProperties';
import { geometryTypeOfEntity } from '../commons';
import {
  calculateArea,
  calculateCenterOfFence,
  calculateMeasurementDistance,
  defaultScaleRotFenceStyle,
  featureContainsFeature,
} from './Draw';
import {
  featureHasDuplicateName,
  featureHasEmptyGeomobyPropertyValue,
  featureHasIncompleteOverrideRule,
  featureHasInvalidPropertyOrOverride,
  featureHasUndefinedName,
} from './Validator';
import { jsUcFirst } from '../../Global/StringFormatterFunctions';
import {
  ALL_LAYERS,
  FRESH,
  UNKNOWN_LAYER,
  initialExtentInDegrees,
  initialLatitude,
  initialLongitude,
  initialZoomHeight,
  TRACKING_BOUNDS,
  FRESH_LAYER,
} from '../../../util/constants';
import { deselectAllFences } from '../Helpers';
import { WHITE } from '../../../Style/GeoMobyBaseTheme';

type AnyRenderer =
  | CanvasVectorLayerRenderer
  | CanvasVectorTileLayerRenderer
  | CanvasVectorImageLayerRenderer
  | WebGLPointsLayerRenderer;
type HitDetectionLayer = BaseVectorLayer<VectorSource<Geometry>, AnyRenderer>;

type SavedFeature = { id: string; name: string; parentId: string | undefined };

const styleCache = new Map<string, Style[]>();

const selectedLayerFeatureStyle =
  (): StyleFunction => (f0: Feature<Geometry> | RenderFeature, p1) => {
    const props = f0.getProperties();

    if (f0.getGeometry() instanceof Point) {
      if (props['selected']) {
        return props['microfenceType'] === MicrofenceEntity.Beacon
          ? selectedMicrofenceBeaconStyle
          : props['microfenceType'] === MicrofenceEntity.Device
          ? selectedMicrofenceDeviceStyle
          : selectedMicrofenceGatewayStyle;
      } else {
        return props['microfenceType'] === MicrofenceEntity.Beacon
          ? defaultMicrofenceBeaconStyle
          : props['microfenceType'] === MicrofenceEntity.Device
          ? defaultMicrofenceDeviceStyle
          : defaultMicrofenceGatewayStyle;
      }
    }

    if (props['zone'] === FenceZone.breach) {
      return props['selected'] ? selectedBreachFenceStyle : breachFenceStyle;
    } else if (props['zone'] === FenceZone.buffer) {
      return props['selected'] ? selectedBufferFenceStyle : bufferFenceStyle;
    } else if (
      props['zone'] === FenceZone.cleared ||
      props['type'] === FenceGeometryType.Multipolygon
    ) {
      return props['selected'] ? selectedClearedFenceStyle : clearedFenceStyle;
    } else if (
      props['layerName'] === TRACKING_BOUNDS &&
      geometryTypeOfEntity(props) !== FenceGeometryType.Line
    ) {
      return props['selected'] ? selectedTrackingFenceStyle : trackingFenceStyle;
    }

    if (props['selected']) {
      if (geometryTypeOfEntity(props) === FenceGeometryType.Line) {
        return [
          selectedDefaultFenceStyle,
          ...stylesForLineFence(
            getCoordsForNewTripwire(
              props.geometry.flatCoordinates ?? props?.points,
              props.numberOfArrows,
            ),
            true,
            true,
          ),
        ];
      }
      if (props.fresh && geometryIsLine(props.geometry.flatCoordinates)) {
        return [
          selectedDefaultFenceStyle,
          ...stylesForLineFence(
            getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
            true,
            true,
          ),
        ];
      }
      return selectedDefaultFenceStyle;
    } else if (!props['isMeasurementTool'] && geometryIsLine(props.geometry.flatCoordinates)) {
      return [
        defaultFenceStyle,
        ...stylesForLineFence(
          getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
          false,
          true,
        ),
      ];
    } else if (props['isMeasurementTool']) {
      return new Style({
        stroke: new Stroke({ color: [1, 1, 1, 0] }),
      });
    }
    return defaultFenceStyle;
  };

const defaultLayerFeatureStyle: StyleFunction = (f0: Feature<Geometry> | RenderFeature, p1) => {
  if (f0.getGeometry() instanceof Point) {
    return f0.get('microfenceType') === 'beacon'
      ? defaultMicrofenceBeaconStyle
      : f0.get('microfenceType') === 'device'
      ? defaultMicrofenceDeviceStyle
      : defaultMicrofenceGatewayStyle;
  }
  const props = f0.getProperties();
  if (props['zone'] === FenceZone.breach) {
    return breachFenceStyle;
  } else if (props['zone'] === FenceZone.buffer) {
    return bufferFenceStyle;
  } else if (props['zone'] === FenceZone.cleared || props['type'] === 'multipolygon') {
    return clearedFenceStyle;
  } else if (
    props['layerName'] === TRACKING_BOUNDS &&
    !(geometryTypeOfEntity(props) === FenceGeometryType.Line)
  ) {
    return trackingFenceStyle;
  }
  if (geometryIsLine(props.geometry.flatCoordinates)) {
    return [
      defaultFenceStyle,
      ...stylesForLineFence(
        getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
      ),
    ];
  }
  return defaultFenceStyle;
};

const selectedFenceFeatureStyle: StyleFunction = (f0: Feature<Geometry> | RenderFeature, _p1) => {
  if (f0.getGeometry() instanceof Point) {
    return f0.get('microfenceType') === 'beacon'
      ? selectedMicrofenceBeaconStyle
      : f0.get('microfenceType') === 'device'
      ? selectedMicrofenceDeviceStyle
      : selectedMicrofenceGatewayStyle;
  }
  const props = f0.getProperties();
  if (props['zone'] === FenceZone.breach) {
    return selectedBreachFenceStyle;
  } else if (props['zone'] === FenceZone.buffer) {
    return selectedBufferFenceStyle;
  } else if (props['zone'] === FenceZone.cleared || props['type'] === 'multipolygon') {
    return selectedClearedFenceStyle;
  } else if (
    props['layerName'] === TRACKING_BOUNDS &&
    !(geometryTypeOfEntity(props) === FenceGeometryType.Line)
  ) {
    return trackingFenceStyle;
  }
  if (geometryIsLine(props.geometry.flatCoordinates)) {
    if (props.numberOfArrows === 0) {
      return f0.get('selected') ? selectedDefaultFenceStyle : defaultFenceStyle;
    }
    return [
      f0.get('selected') ? selectedDefaultFenceStyle : defaultFenceStyle,
      ...stylesForLineFence(
        getCoordsForNewTripwire(props.geometry.flatCoordinates, props.numberOfArrows),
        true,
      ),
    ];
  }
  return f0.get('selected') ? selectedDefaultFenceStyle : defaultFenceStyle;
};

const selectedLayerStyle = (
  cache: Map<string, Style | Style[]>,
  focusedFenceId?: string | null,
): StyleFunction =>
  styleFunctionForClusteredFeatures(
    cache,
    `selectedLayer_${focusedFenceId || ''}`,
    selectedLayerFeatureStyle(),
    selectedLayerClusterPointStyle,
  );

const selectedFenceStyle = (cache: Map<string, Style | Style[]>): StyleFunction =>
  styleFunctionForClusteredFeatures(
    cache,
    'selectedFence',
    selectedFenceFeatureStyle,
    selectedFenceClusterPointStyle,
  );

interface EditState {
  draw: Draw;
  snap: Snap;
  modify: Modify;
  modifyScaleRot: Modify;
  translate: Translate;
}

export const dropPin = (coords: number[], address?: string): Feature<Point> => {
  const feature = new Feature(new Point(coords));
  const displayedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');

  feature.set(
    'searchedCoordinates',
    parseFloat(displayedCoords[1].toFixed(5)) + ', ' + parseFloat(displayedCoords[0].toFixed(5)),
  );
  feature.set('searchedAddress', address);
  return feature;
};

/* Editor Map */
export const MapRenderer = () => {
  const MAX_NUMBER_OF_POLYGONS = 10000;

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const mapApiKeys = useAtomValue(MAP_API_KEYS);

  const currentlyDisplayedTripwiresRef = useRef<Feature<Geometry>[]>([]);
  const editRef = useRef<EditState | undefined>();
  const extentInDegreesRef = useRef<number>(initialExtentInDegrees);
  const knownExtents = useRef<Extent[]>([]);
  const layerRef =
    useRef<{ id: string; name: string; source: VectorSource<Geometry> } | undefined>();
  const mapRef = useRef<olMap | undefined>();
  const measureTooltipRef = useRef<Overlay>();
  const measureTooltipElementRef = useRef<HTMLElement>();
  const microfenceDrawTypeRef = useRef<MicrofenceEntity | undefined>(undefined);
  const freshGeofencesRef = useRef<GridRowData[]>([]);
  const selectedFenceRef = useRef<Feature<Geometry>>();
  const setSourceRef = useRef<(type: MapSourceType) => void>();
  const showGhostGeofencesRef = useRef<boolean>(false);

  const [availableGeofences, setAvailableGeofences] = useState<GridRowData[]>([]);
  const [availableMicrofences, setAvailableMicrofences] = useState<GridRowData[]>([]);
  const [bounds, setBounds] = useState<Bounds>({
    latitude: initialLatitude,
    longitude: initialLongitude,
    extentInDegrees: initialExtentInDegrees,
  });
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [createEditFence, setCreateEditFence] = useState<RequestType | undefined>();
  const [createEditLayer, setCreateEditLayer] = useState<RequestType | undefined>();
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [deletedFenceIds, setDeletedFenceIds] = useState<{ type: FenceGeometryType; id: string }[]>(
    [],
  );
  const [deselectFence, setDeselectFence] = useState<boolean>(false);
  const [dirtySave, setDirtySave] = useState<{
    isDirty: boolean;
    issue: string | null;
  }>({
    isDirty: false,
    issue: null,
  });
  const [displayGeomobyOverrides, setDisplayGeomobyOverrides] = useState<
    (GeomobyOverride & { index: number })[]
  >([]);
  const [displayGeomobyProperties, setDisplayGeomobyProperties] = useState<
    { index: number; property: string; value: string }[]
  >([]);
  const [drawType, setDrawType] = useState<DrawType | undefined>();
  const [dropLocationPin, setDropLocationPin] = useState<Feature<Point> | undefined>();
  const [editing, setEditing] = useState<boolean>(false);
  const [editType, setEditType] = useState<EditType | undefined>();
  const [errorCode, serErrorCode] = useState<number | undefined>();
  const [fencesLoading, setFencesLoading] = useState<boolean>(true);
  const [geofenceFilter, setGeofenceFilter] = useState<GeofenceFilter | undefined>();
  const [hasFences, setHasFences] = useState<boolean>(false);
  const [paginating, setPaginating] = useState<boolean>(false);
  const [layersHaveChanged, setLayersHaveChanged] = useState<boolean>(false);
  const [layers, setLayers] = useState<
    { id: string; name: string; source: VectorSource<Geometry> }[]
  >([]);
  // TODO: This will be removed in a subsequent tech debt ticket (https://geomoby.atlassian.net/browse/LTP-1193). Not today thought. Too many changes already.
  const [layerIds, setLayerIds] = useState<NameId[]>([]);
  const [locationDisplay, setLocationDisplay] = useState<LocationDisplayType>();
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData | undefined>();
  const [mapIsLoading, setMapIsLoading] = useState<boolean>(true);
  const [mapSourceType, setMapSourceType] = useState<MapSourceType>('Terrain & Roads');
  const [measurementType, setMeasurementType] = useState<MeasurementType | undefined>();
  const [microfenceFilter, setMicrofenceFilter] = useState<MicrofenceFilter | undefined>();
  const [freshGeofences, setFreshGeofences] = useState<GridRowData[]>([]);
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [openGenericDialog, setOpenGenericDialog] = useState<boolean>(false);
  const [paginatedCount, setPaginatedCount] = useState<number>(0);
  const [reassignedFences, setReassignedFences] = useState<ReassignedFence[]>([]);
  const [renamingLayer, setRenamingLayer] = useState<string | null>(null);
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [selectedFromMap, setSelectedFromMap] = useState<boolean>(false);
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const [selectedMicrofence, setSelectedMicrofence] = useState<GridRowData | undefined>();
  const [selectedLayer, setSelectedLayer] = useState<NameId | undefined>();
  const [searchType, setSearchType] = useState<SearchType | undefined>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showGhostGeofences, setShowGhostGeofences] = useState<boolean>(false);
  const [specifiedCoordinates, setSpecifiedCoordinates] = useState<[number, number]>();
  const [totalFences, setTotalFences] = useState<number>(0);
  const [userExtent, setUserExtent] = useState<Extent | undefined>();
  const [zoneChange, setZoneChange] = useState<Date | null>(null);

  if (!specifiedCoordinates) {
    navigator.geolocation?.getCurrentPosition(
      loc => {
        setSpecifiedCoordinates([loc?.coords.longitude, loc?.coords.latitude]);
      },
      () => {
        setSpecifiedCoordinates([initialLongitude, initialLatitude]);
      },
    );
  }

  const debouncedOnMapMoved = useRef(
    debounce(
      (olmap: olMap) => {
        const view = olmap?.getView();
        if (!view) return;
        const viewCenter = view.getCenter();
        if (!viewCenter) return;
        const center = transform(viewCenter, view.getProjection(), 'EPSG:4326');
        setBounds({
          latitude: center[1],
          longitude: center[0],
          extentInDegrees: extentInDegreesRef.current ?? initialExtentInDegrees,
        });
      },
      1000,
      { leading: true },
    ),
  ).current;

  const animateToSearchedLocation = useCallback(
    async (
      view: View,
      coords: number[],
      address?: string,
      isStreetAddress?: boolean,
    ): Promise<Feature<Point>> => {
      return new Promise(
        debounce((resolve, reject) => {
          const duration = Math.min(
            6000,
            Math.max(
              300,
              getCoordinateDifference(view.getCenter() ?? [0, 0], coords ?? [0, 0]) / 1000,
            ),
          );

          view.animate(
            {
              center: coords,
              zoom: duration > 300 ? 9 - duration / 1000 : view.getZoom(),
              duration: duration,
            },
            () => {
              view.animate(
                {
                  resolution: isStreetAddress ? 18 : 14,
                  duration: 2000,
                },
                () => {
                  resolve(dropPin(coords, address));
                },
              );
            },
          );
        }, 2000),
      );
    },
    [],
  );

  const getGeofence = useCallback(
    async (id: string, layerId: string, type: FenceGeometryType | undefined) => {
      if (!type || type === FenceGeometryType.Microfence) return;
      return (
        await axios.get<{
          id: string;
          name: string;
          points: { coordinates: Extent };
          geomobyProperties: Record<string, string>;
          geomobyOverrides: GeomobyOverride[];
        }>(`${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`, authedRequestConfig)
      ).data;
    },
    [triggersUrl, cid, pid, authedRequestConfig],
  );

  const animateToLocationGlobally = useCallback(
    async ([lon1, lat1, lon2, lat2]: [number, number, number, number]) => {
      if (!mapRef.current) return;
      const duration = Math.min(
        6000,
        Math.max(
          300,
          getCoordinateDifference(
            mapRef.current?.getView().getCenter() ?? [0, 0],
            getCenter([lon1, lat1, lon2, lat2]) ?? [0, 0],
          ) / 1000,
        ),
      );
      mapRef.current.getView().animate(
        {
          center: [lon1, lat1, lon2, lat2],
          zoom: duration > 300 ? 9 - duration / 1000 : mapRef.current.getView().getZoom(),
          duration: duration,
        },
        () => {
          if (selectedFenceRef.current?.get('layerId') === MICROFENCE_LAYER_ID) {
            const extent = selectedFenceRef.current.getGeometry()?.getExtent() ?? [];
            if (extent.length === 0) return;
            mapRef.current?.getView().animate({
              center: getCenter(extent),
              duration: 250,
              zoom: CLUSTER_MAX_ZOOM,
            });
            return;
          }
          if (!mapRef.current) return;
          fitToExtent(mapRef.current.getView(), [lon1, lat1, lon2, lat2]);
        },
      );
    },
    [],
  );

  const animateToFeature = useCallback(async () => {
    if (selectedMicrofence || selectedGeofence?.id?.includes(FRESH)) {
      animateToLocationGlobally(
        (selectedFenceRef.current?.getGeometry()?.getExtent() ||
          selectedMicrofence?.geometry?.getExtent()) as [number, number, number, number],
      );
      return;
    }

    if (deletedFenceIds.find(f => f.id === selectedGeofence?.id) || !selectedGeofence) return;
    if (!selectedGeofence) return;

    const geofence = await getGeofence(
      selectedGeofence?.id,
      selectedGeofence?.layerId,
      geometryTypeOfEntity(selectedGeofence),
    );
    if (!geofence) return;
    const fenceType = geometryTypeOfEntity(selectedGeofence);
    const newFence =
      fenceType === FenceGeometryType.Polygon
        ? new Polygon(geofence.points.coordinates)
        : fenceType === FenceGeometryType.Multipolygon
        ? new MultiPolygon(geofence.points.coordinates)
        : new LineString(geofence.points.coordinates);
    const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
    if (!extent || !mapRef.current) return;
    animateToLocationGlobally(extent as [number, number, number, number]);
  }, [
    selectedGeofence,
    selectedMicrofence,
    deletedFenceIds,
    getGeofence,
    animateToLocationGlobally,
  ]);

  const getLayerFromMap = (name: string | undefined, id?: string) => {
    const layer = mapRef.current
      ?.getAllLayers()
      .find(layer =>
        layer.getProperties().id === MICROFENCE_LAYER_ID
          ? layer instanceof AnimatedCluster &&
            (layer.get('name') === name || layer.get('id') === id)
          : layer instanceof VectorLayer &&
            ((layer.getSource().get('name') ?? layer.get('name')) === name ||
              (layer.getSource().get('id') ?? layer.get('id')) === id),
      );
    if (!layer) {
      name
        ? console.log('map layer not found for name', name)
        : console.log('map layer not found for id', id);
      return;
    }

    if (layer.getProperties().id === MICROFENCE_LAYER_ID) return layer as AnimatedCluster;
    return layer as VectorLayer<VectorSource<Geometry>>;
  };

  const setMapClickDeselection = (m: olMap) => {
    m.on('click', e => {
      deselectAllFences(mapRef.current);
      if (freshGeofencesRef.current.find(f => f.layerId === UNKNOWN_LAYER)) {
        setOpenGenericDialog(true);
        return;
      }
      if (m.getFeaturesAtPixel(e.pixel).length === 0 && !editRef.current) {
        setSelectedLayer(undefined);
        setSelectedGeofence(undefined);
        setSelectedMicrofence(undefined);
        selectedFenceRef.current = undefined;
        setDeselectFence(true);
        setRefreshSearch(true);
      } else {
        if (editRef.current) return;
        const fences: GeoJSONFeature[] = m.getFeaturesAtPixel(e.pixel) ?? [];
        if (!fences || !fences.length) {
          setSelectedLayer(undefined);
          setSelectedGeofence(undefined);
          setSelectedMicrofence(undefined);
          selectedFenceRef.current = undefined;
          setRefreshSearch(true);
          return;
        }

        let smallestExtentArea = Number.MAX_SAFE_INTEGER;
        let selectedFence = fences[0];
        fences.forEach(f => {
          const extent = f.getGeometry()?.getExtent();
          const extentArea = extent
            ? Math.abs(extent[0] - extent[2]) * Math.abs(extent[1] - extent[2])
            : Number.MAX_SAFE_INTEGER;
          if (smallestExtentArea > extentArea) {
            smallestExtentArea = extentArea;
            selectedFence = f;
          }
        });
        const layerId =
          selectedFence.get('layerId') ??
          (selectedFence.getGeometry() instanceof Point ? MICROFENCE_LAYER_ID : undefined);
        if (!layerId) return;

        if (layerId === MICROFENCE_LAYER_ID) {
          setSelectedLayer({ name: SearchTypeValue.Microfences, id: MICROFENCE_LAYER_ID });
          if (selectedFence.get('features').length > 0) {
            selectedFence.get('features')[0].set('selected', true);
            selectedFenceRef.current = selectedFence.get('features')[0] as Feature<Geometry>;
            setSelectedMicrofence(selectedFence.get('features')[0]?.getProperties() as GridRowData);
            setSearchType({ id: SearchTypeIDs.Microfences, value: SearchTypeValue.Microfences });
          }
        } else {
          if (showGhostGeofencesRef.current) return;
          setShowGhostGeofences(false);
          selectedFence.set('selected', true);
          selectedFenceRef.current = selectedFence as Feature<Geometry>;
          setSelectedGeofence(selectedFence?.getProperties() as GridRowData);
          const foundLayer = getLayerFromMap(undefined, layerId);
          if (foundLayer) {
            setSelectedLayer({ id: layerId, name: foundLayer.getSource().get('name') });
          }
          setSearchType({ id: SearchTypeIDs.Geofences, value: SearchTypeValue.Geofences });
          if (geometryTypeOfEntity(selectedFence.getProperties()) === FenceGeometryType.Polygon) {
            const geom = selectedFence?.getGeometry();
            if (geom && layerRef.current) {
              setHasFences(!!featureContainsFeature(geom, layerRef.current.source));
            }
          }
        }

        setSelectedFromMap(true);
        layerIds.forEach(layer =>
          changeVisibility(layer.id, layer.id === layerId || showGhostGeofencesRef.current),
        );
      }
    });
  };

  const unsetMapClickSelection = (m: olMap) => {
    m.getInteractions().forEach(i => i instanceof Select && m.removeInteraction(i));
  };

  const setMapMoveHandler = (m: olMap) => {
    m.on('moveend', () => {
      setCurrentCenter(m.getView().getCenter());
      debouncedOnMapMoved(m);
    });
  };

  const layerChanged = () => {
    if (currentlyDisplayedTripwiresRef.current.length === 0) {
      currentlyDisplayedTripwiresRef.current =
        layerRef.current?.source
          .getFeatures()
          .filter(f => geometryTypeOfEntity(f.getProperties()) === FenceGeometryType.Line) ?? [];
    }
    if (
      !(selectedGeofence ?? selectedMicrofence) &&
      mapRef.current?.getAllLayers().find(lyr => lyr.getSource()?.get('id') === UNKNOWN_LAYER)
    )
      return;
    setLayersHaveChanged(true);
  };

  const featureModified = (e: ModifyEvent, source: VectorSource<Geometry>) => {
    const modifiedFids = e.features.getArray();
    source.forEachFeature(feature => {
      const modifiedFeature = modifiedFids.find(f => f.get('id')?.includes(feature.get('id')));
      if (modifiedFeature) {
        feature.setProperties({ updated: true });

        if (modifiedFeature && modifiedFeature.get('layerId') !== MICROFENCE_LAYER_ID) {
          feature.setGeometry((modifiedFeature as Feature<Geometry>).getGeometry());
          const geometry = modifiedFeature?.getGeometry();

          const type = geometryTypeOfEntity(feature.getProperties());
          if (geometry) {
            if (type === FenceGeometryType.Polygon) {
              const coordinates = (geometry as Polygon).getCoordinates()[0];
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = [newCoords];
              }
            } else if (type === FenceGeometryType.Multipolygon) {
              const coordinates = (geometry as MultiPolygon).getCoordinates()[0][0];
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = [[newCoords]];
              }
            } else {
              const coordinates = (geometry as LineString).getCoordinates();
              const newCoords = coordinates.map(coord =>
                transform(coord, 'EPSG:3857', 'EPSG:4326'),
              );
              const points = (modifiedFeature as Feature<Geometry>).get('points');
              if (newCoords && feature.get('points')) {
                feature.get('points').coordinates = newCoords;
              }
            }
          }
        }
      }
    });
    layerChanged();
  };

  const onMicrofenceDrawEnd = ({ feature }: { feature: Feature<Geometry> }) => {
    feature.set(
      'geomobyProperties',
      Object.fromEntries(Object.entries(MICROFENCE_DEFAULT_PROPS).map(([k, v]) => [k, String(v)])),
    );
    setLayersHaveChanged(true);
  };

  const uiUpdateFenceIdentifiers = async (
    id: string,
    name: string,
    type: FenceGeometryType | undefined,
    layerId: string,
    fenceZone: FenceZone | undefined,
    assetId: MicrofenceAssetId | undefined,
    microfenceZone: MicrofenceZone | undefined,
  ): Promise<void> => {
    if (!id)
      throw new Error(
        'UI should not be able to update a feature name or ID while no feature is selected',
      );
    if (!layerRef.current)
      throw new Error(
        'UI should not be able to update a feature name or ID while no layer is selected',
      );

    if (layerId !== MICROFENCE_LAYER_ID) {
      await findFeature(id, layerId, type);
    }

    if (!layerRef.current)
      throw new Error(
        'UI should not be able to update a feature name or ID while no layer is selected',
      );

    updateFenceOnMap(
      layerRef.current.source,
      layerChanged,
      id,
      name,
      fenceZone,
      assetId,
      microfenceZone,
    );

    const updatedNameFeature = freshGeofencesRef.current.find(f => f.id === id);
    if (updatedNameFeature) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== id),
        { ...updatedNameFeature, name },
      ];
    }

    if (layerId !== MICROFENCE_LAYER_ID) {
      const bufferZoneFence = layerRef.current?.source
        .getFeatures()
        .find(f => f.get('parentId') === id && f.get('zone') === FenceZone.buffer);
      const relatedFenceName = bufferZoneFence ? `${name}_warning` : undefined;
      if (relatedFenceName) {
        updateFenceOnMap(
          layerRef.current?.source,
          layerChanged,
          bufferZoneFence?.get('id'),
          relatedFenceName,
          fenceZone === FenceZone.breach ? FenceZone.buffer : undefined,
        );
        const bufferIsFresh = freshGeofencesRef.current.find(
          f => f.id === bufferZoneFence?.get('id'),
        );
        if (bufferIsFresh) {
          freshGeofencesRef.current = [
            ...freshGeofencesRef.current.filter(f => f.id !== bufferZoneFence?.get('id')),
            { ...bufferIsFresh, name: relatedFenceName },
          ];
        }
      }
      setAvailableGeofences(fenceIds =>
        availableGeofences.map(fence => {
          if (fence.id === id) {
            return { ...fence, name };
          } else if (relatedFenceName && fence.id === bufferZoneFence?.get('id')) {
            return { ...fence, name: relatedFenceName };
          }
          return fence;
        }),
      );
    } else if (layerId === MICROFENCE_LAYER_ID) {
      if (!mapRef.current) return;
      setAvailableMicrofences(fenceIds =>
        availableMicrofences.map(fence => {
          if (fence.id === id && assetId) {
            return { ...fence, name, assetId };
          }
          return fence;
        }),
      );
    }
    setFreshGeofences(freshGeofencesRef.current);
    setLayersHaveChanged(true);
  };

  const changeVisibility = (layerId: string, visible: boolean, opacity?: number) => {
    if (!layers || !mapRef.current) return;

    const layer = layers.find(lyr => lyr.id === layerId);
    if (!layer) return;

    const foundLayer = getLayerFromMap(layer.name);
    if (foundLayer) {
      foundLayer.setVisible(visible);
      if (visible && selectedLayer?.id === MICROFENCE_LAYER_ID) {
        foundLayer.setOpacity(layerId === MICROFENCE_LAYER_ID ? 1 : 0.6);
      } else if (visible) {
        foundLayer.setOpacity(1);
      }
      if (opacity) foundLayer.setOpacity(opacity);
    }
  };

  const uiResetLayerChanges = async (): Promise<void> => {
    if (!selectedLayer)
      throw new Error('UI should not be trying to reset a layer when no layer is selected');
    if (!layerRef.current)
      throw new Error('UI should not be trying to reset a layer when no layer is selected');
    if (!mapRef.current) return;
    if (!layers) return;

    deselectAllFences(mapRef.current);
    if (selectedLayer) {
      changeVisibility(selectedLayer?.id, false);
    } else {
      layerIds.forEach(layer => changeVisibility(layer.id, layer.id !== MICROFENCE_LAYER_ID));
    }

    setLayersHaveChanged(false);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    setCreateEditLayer(undefined);
    setCreateEditFence(undefined);

    setAvailableGeofences([]);
    setReassignedFences([]);
    selectedFenceRef.current = undefined;
    freshGeofencesRef.current = [];
    setFreshGeofences([]);
    const mapLayer = getLayerFromMap(undefined, layerRef.current?.id);
    if (mapLayer) {
      mapRef.current.removeLayer(mapLayer);
    }

    const scaleRotLayer = mapRef.current
      .getAllLayers()
      .find(l => l.getClassName() === 'Scalable-rotatable-layer');
    if (scaleRotLayer) {
      mapRef.current.removeLayer(scaleRotLayer);
    }

    setMapIsLoading(true);
    const restoredLayer =
      selectedLayer?.id === MICROFENCE_LAYER_ID
        ? {
            id: MICROFENCE_LAYER_ID,
            name: MICROFENCE_LAYER_LABEL,
            source: await getMicrofencesVectorLayer(
              triggersUrl,
              authedRequestConfig,
              { cid, pid },
              bounds,
            ),
          }
        : await getVectorLayer(
            triggersUrl,
            authedRequestConfig,
            { cid, pid },
            selectedLayer?.id,
            bounds,
          );
    setMapIsLoading(false);

    // If no layer with these credentials is returned from db, then remove it from front-end.
    if (!restoredLayer) {
      if (layerIds.length === 0 || !layerRef.current) return;

      const layerToRemove = layerIds.find(i => i.id === selectedLayer?.id)?.id;
      if (layerToRemove) {
        setLayers(layers.filter(lyr => lyr.id !== layerToRemove));
        setLayerIds(layerIds.filter(lyr => lyr.id === layerToRemove));
        mapRef.current.setLayers(
          mapRef.current
            .getAllLayers()
            .filter(
              lyr =>
                lyr?.getSource().get('id') !== layerToRemove &&
                lyr.get('id') !== layerToRemove &&
                lyr?.getSource().get('id') !== FRESH_LAYER &&
                lyr.get('id') !== FRESH_LAYER,
            ),
        );
      }

      setSelectedLayer(undefined);
      layerRef.current = undefined;
      setMapIsLoading(false);
      return;
    }
    restoredLayer.source.setStyle(
      selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
    );

    setTimeout(() => {
      if (!mapRef.current) return;
      const foundLayer = mapRef.current
        .getAllLayers()
        .find(lyr => lyr.getSource().get('id') === selectedLayer?.id);
      if (foundLayer) {
        mapRef.current.removeLayer(foundLayer);
      }
      mapRef.current.addLayer(restoredLayer.source);
      const newLayerSourceAndName = {
        id: selectedLayer?.id,
        name: restoredLayer.name,
        source:
          selectedLayer?.id === MICROFENCE_LAYER_ID
            ? (restoredLayer.source.getSource() as Cluster).getSource()
            : restoredLayer.source.getSource(),
      };

      setLayerIds([
        { id: selectedLayer?.id, name: restoredLayer.name },
        ...layerIds.filter(i => i.id !== selectedLayer?.id),
      ]);
      setLayers([newLayerSourceAndName, ...layers.filter(lyr => lyr.id !== selectedLayer?.id)]);
      layerRef.current = newLayerSourceAndName;
      setSelectedLayer({ id: selectedLayer?.id, name: restoredLayer.name });
      selectedFenceRef.current = undefined;
      setDeletedFenceIds([]);
      setRefreshSearch(true);

      // Force modified/undeleted features to be redrawn
      mapRef.current.getView().adjustZoom(-1);
      mapRef.current.getView().adjustZoom(+1);
    });
  };

  const processNextReassignedFence = (reassignedFence: ReassignedFence, layerId: string) => () =>
    axios
      .patch(
        `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${reassignedFence.type}/change-layer/${reassignedFence.id}/${reassignedFence.newLayerId}`,
        {},
        authedRequestConfig,
      )
      .then(() => true);

  const saveMicrofenceLayerChanges = async (): Promise<undefined> => {
    if (!layerRef.current || !selectedLayer || selectedLayer?.id !== MICROFENCE_LAYER_ID) return;

    try {
      await Promise.all(
        deletedFenceIds
          .filter(f => !f.id?.includes(FRESH))
          .map(({ id }) =>
            axios.delete(`${triggersUrl}/${cid}/${pid}/microfences/${id}`, authedRequestConfig),
          ),
      );

      setDeletedFenceIds([]);

      const geojson = jsonFromLayer(layerRef.current?.source);

      const newIdsbyOldId: { [oldId: string]: string | undefined } = Object.fromEntries(
        await Promise.all(
          geojson.features
            .filter(
              (feature: GeoJSONFeature) => feature.properties?.updated || feature.properties?.fresh,
            )
            .map(async (feature: GeoJSONFeature) => {
              const typeIsPoint = feature.geometry.type === 'Point';
              if (!typeIsPoint) {
                console.error(
                  'Should not be trying to save non-point feature as a microfence:',
                  feature,
                );
              }
              const boundaryRssi = Number(
                feature.properties.geomobyProperties[MICROFENCE_PROP_LABELS.boundaryRssi],
              );
              const timeoutSeconds = Number(
                feature.properties.geomobyProperties[MICROFENCE_PROP_LABELS.timeoutSeconds],
              );
              const name = feature.properties.name;
              const type = feature.properties.microfenceType?.replace('smartplug', 'gateway'); // fix legacy type
              const assetId = feature.properties.assetId;
              const zone = feature.properties.zone;

              const body = {
                assetId,
                name,
                type,
                zone: zone === 'none' || zone === undefined ? null : zone,
                boundaryRssi,
                timeoutSeconds,
                geometry: feature.geometry,
              };
              if (feature.properties.id?.includes(FRESH)) {
                const id: string = (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/microfences/`,
                    body,
                    authedRequestConfig,
                  )
                ).data.id;
                return [feature.properties.id, id];
              } else {
                await axios.patch(
                  `${triggersUrl}/${cid}/${pid}/microfences/${feature.properties.id}`,
                  body,
                  authedRequestConfig,
                );
              }
              return [];
            }),
        ),
      );

      const layer = layers.find(lyr => lyr.id === selectedLayer?.id);
      layer?.source.getFeatures().forEach(f => {
        f.setProperties({ updated: undefined, fresh: undefined });
        const newId = newIdsbyOldId[f.get('id')];
        if (newId) {
          f.set('id', newId);
        }
      });
      setLayersHaveChanged(false);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
      const newMicrofences = layer?.source.getFeatures();
      if (!newMicrofences) return;
      setAvailableMicrofences(newMicrofences.map(fence => fence.getProperties()));
    } catch (error) {
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Microfence);
      uiResetLayerChanges();
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: errorMessage,
      });
    }
    deselectAllFences(mapRef.current);
  };

  const saveGeofenceLayerChanges = async (): Promise<string | undefined> => {
    if (!layerRef.current || !selectedLayer) return;
    let layerId = selectedLayer?.id;
    const layerData = layerRef.current;
    const geojson = jsonFromLayer(layerData.source);
    freshGeofencesRef.current = [];
    setFreshGeofences([]);

    const features: {
      properties: {
        id: string;
        name: string;
        type: string;
        zone: FenceZone;
        parentId: string;
        geomobyProperties: Record<string, string>;
        geomobyOverrides: GeomobyOverride[];
      };
    }[] = geojson.features
      .filter(
        (feature: GeoJSONFeature) => feature?.properties?.updated || feature?.properties?.fresh,
      )
      .map((feature: GeoJSONFeature) => {
        const type = feature.geometry.type?.toLowerCase().includes(FenceGeometryType.Line)
          ? FenceGeometryType.Line
          : feature.geometry.type?.toLowerCase();
        return {
          ...feature,
          properties: {
            id: feature?.properties?.id,
            name: feature?.properties?.name,
            type,
            geomobyProperties: feature?.properties?.geomobyProperties,
            geomobyOverrides: feature?.properties?.geomobyOverrides,
            zone:
              type === FenceGeometryType.Polygon
                ? feature?.properties?.zone ?? FenceZone.none
                : undefined,
            parentId: feature?.properties?.parentId,
          },
        };
      });

    const existingFences = features.filter(f => !f.properties.id?.includes(FRESH));
    const newPolygons = features.filter(
      f =>
        f.properties.type?.toLowerCase() === FenceGeometryType.Polygon &&
        f.properties.id?.includes(FRESH),
    );

    const newMultipolygons = features.filter(
      f =>
        f.properties.type?.toLowerCase() === FenceGeometryType.Multipolygon &&
        f.properties.id?.includes(FRESH),
    );

    const newLines = features.filter(
      f =>
        f.properties.type?.toLowerCase()?.includes(FenceGeometryType.Line) &&
        f.properties.id?.includes(FRESH),
    );

    const newlyCreatedPolygons: { id: string; name: string; parentId: string | undefined }[] = [];
    let newlyCreatedMultiPolygons: { id: string; name: string; parentId: string | undefined }[] =
      [];
    let newlyCreatedLines: { id: string; name: string; parentId: string | undefined }[] = [];

    try {
      // Create layer
      if (layerId === FRESH_LAYER) {
        layerId = (
          await axios.post(
            `${triggersUrl}/${cid}/${pid}/geofences`,
            {
              name: layerData.name,
            },
            authedRequestConfig,
          )
        ).data.id;
        const olmap = mapRef.current;
        const freshLayer = olmap
          ?.getAllLayers()
          .find(
            (l, i) =>
              l instanceof VectorLayer &&
              (l.getSource()?.get('id') === FRESH_LAYER || l.get('id') === FRESH_LAYER),
          );
        if (freshLayer) {
          freshLayer.set('id', layerId);
          freshLayer
            .getSource()
            .getFeatures()
            .forEach((f: Feature<Geometry>) => {
              f.set('layerId', layerId);
            });
          freshLayer.getSource().setProperties({
            id: layerId,
            name: freshLayer.get('name'),
          });
        }
      } else if (layerId !== FRESH_LAYER && renamingLayer) {
        // Update layer
        layerId = (
          await axios.patch(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}`,
            {
              name: renamingLayer,
            },
            authedRequestConfig,
          )
        ).data.id;
      }

      // Delete fences
      if (deletedFenceIds.length > 0) {
        await Promise.all(
          deletedFenceIds
            .filter(f => !f.id?.includes(FRESH))
            .map(({ type, id }) =>
              axios.delete(
                `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${type}/${id}`,
                authedRequestConfig,
              ),
            ),
        );
        setDeletedFenceIds([]);
      }

      // Update existing fences
      if (existingFences.length > 0) {
        await Promise.all(
          existingFences.map(async (feature: GeoJSONFeature) => {
            await axios.patch(
              `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${feature?.properties.type}/${feature?.properties?.id}`,
              feature,
              authedRequestConfig,
            );
          }),
        );
      }

      // Reassign to another layer
      const layer = layerRef.current;
      if (reassignedFences.length > 0) {
        setMapIsLoading(true);
        await reassignedFences
          .map(r => processNextReassignedFence(r, layerId))
          .reduce((prev, cur) => {
            return prev.then(() => cur());
          }, Promise.resolve(true));

        if (availableGeofences.length > 0) {
          reassignedFences.forEach(reassignedFence => {
            const foundFeature = layer.source
              .getFeatures()
              .find((f: Feature<Geometry>) => f.get('id') === reassignedFence.id);
            if (foundFeature && mapRef.current) {
              const newlayer = mapRef.current
                .getAllLayers()
                .find(
                  l =>
                    l instanceof VectorLayer &&
                    l.getProperties().source.get('id') === reassignedFence.newLayerId,
                );

              if (newlayer) {
                const updatedFeature = foundFeature;
                updatedFeature.setProperties({
                  ...updatedFeature.getProperties(),
                  layerId: newlayer.getProperties().source.get('id'),
                  layerName: newlayer.getProperties().source.get('name'),
                });

                const foundBuffer = layer.source
                  .getFeatures()
                  .find((f: Feature<Geometry>) => f.get('parentId') === foundFeature.get('id'));
                if (foundBuffer) {
                  const updatedBuffer = foundBuffer;
                  updatedBuffer.setProperties({
                    ...updatedBuffer.getProperties(),
                    layerId: newlayer.getProperties().source.get('id'),
                    layerName: newlayer.getProperties().source.get('name'),
                  });
                  newlayer.getProperties().source.addFeature(updatedBuffer as Feature<Geometry>);
                  layer.source.removeFeature(foundBuffer as Feature<Geometry>);
                }
                newlayer.getProperties().source.addFeature(updatedFeature as Feature<Geometry>);
                layer.source.removeFeature(foundFeature as Feature<Geometry>);
              }
            }
          });

          setAvailableGeofences(
            availableGeofences.filter(f => !reassignedFences.find(r => r.id === f.id)),
          );
        }
        setReassignedFences([]);
        setMapIsLoading(false);
      }

      // Create polygons
      if (newPolygons.length > 0) {
        const createdPolygons = (
          await axios.post(
            `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/polygon/multiple`,
            { polygons: newPolygons },
            authedRequestConfig,
          )
        ).data;
        newlyCreatedPolygons.push(
          ...createdPolygons.map((p: SavedFeature) => {
            return {
              id: p.id,
              name: p.name,
              parentId: p.parentId,
            };
          }),
        );
      }

      // Create multipolygons
      newlyCreatedMultiPolygons =
        newMultipolygons.length > 0
          ? await Promise.all(
              newMultipolygons.map(async (multipolygon: GeoJSONFeature) => {
                return (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/multipolygon`,
                    multipolygon,
                    authedRequestConfig,
                  )
                ).data;
              }),
            )
          : [];

      // Create lines
      newlyCreatedLines =
        newLines.length > 0
          ? await Promise.all(
              newLines.map(async (line: GeoJSONFeature) => {
                return (
                  await axios.post(
                    `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/line`,
                    line,
                    authedRequestConfig,
                  )
                ).data;
              }),
            )
          : [];

      setSelectedLayer({ id: layerId, name: layerData.name });
      setRefreshSearch(true);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Geofence);
      uiResetLayerChanges();
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: errorMessage,
      });
    }

    let allLayers = layers;
    const layer = allLayers.find(lyr => lyr.id === selectedLayer?.id);
    layer?.source.getFeatures().forEach(f => {
      f.setProperties({ updated: undefined, fresh: undefined });
      const newFence = [
        ...newlyCreatedPolygons,
        ...newlyCreatedMultiPolygons,
        ...newlyCreatedLines,
      ].find(n => n.name === f.get('name'));

      if (newFence) {
        f.set('id', newFence.id);
        if (newFence.parentId) {
          f.set('parentId', newFence.parentId);
        }
      }
    });
    if (layer && selectedLayer?.id !== layerId) {
      allLayers = [
        { ...layer, id: layerId },
        ...allLayers.filter(lyr => lyr.id !== selectedLayer?.id),
      ];
      setLayers(allLayers);
      if (layers) {
        const lyr = layers.find(lyr => lyr.id === layerId);
        lyr?.source.setProperties({
          id: layerId,
          name: lyr.name,
        });
      }
      setSelectedLayer({ id: layerId, name: layerData.name });
    }

    selectedFenceRef.current = undefined;
    setSelectedGeofence(undefined);
    setLayersHaveChanged(false);
    deselectAllFences(mapRef.current);
    return layerId;
  };

  const saveLayerChanges = async (): Promise<string | undefined> => {
    if (!layerRef.current || !selectedLayer) return;
    const layerId = selectedLayer;

    if (layerId?.id === MICROFENCE_LAYER_ID) {
      // Validate Microfences
      let hasDuplicateAssetId: Feature<Geometry> | undefined;
      const features = layerRef.current?.source.getFeatures();

      if (features) {
        const hasUndefinedAssetId = features.find(f => {
          const assetId = f.get('assetId');
          const type = f.get('microfenceType');
          if (type === MicrofenceEntity.Beacon)
            return !assetId?.uuid || !assetId?.major || !assetId?.minor;

          return (
            ((type === MicrofenceEntity.Gateway || type === MicrofenceEntity.Smartplug) &&
              !assetId?.gatewayId) ||
            (type === MicrofenceEntity.Device && !assetId?.deviceId)
          );
        });

        setDirtySave({
          isDirty: !!hasUndefinedAssetId,
          issue: hasUndefinedAssetId
            ? hasUndefinedAssetId.get('microfenceType') === MicrofenceEntity.Beacon
              ? `Beacon IDs are required`
              : `${
                  hasUndefinedAssetId.get('microfenceType') === MicrofenceEntity.Gateway ||
                  hasUndefinedAssetId.get('microfenceType') === MicrofenceEntity.Smartplug
                    ? jsUcFirst(MicrofenceEntity.Gateway)
                    : jsUcFirst(MicrofenceEntity.Device)
                } ID is required`
            : null,
        });

        const hasUndefinedName = featureHasUndefinedName(
          features,
          EntityType.Microfence,
          setDirtySave,
        );
        const hasDuplicateName = featureHasDuplicateName(
          features,
          EntityType.Microfence,
          setDirtySave,
        );

        if (!hasUndefinedAssetId) {
          features.forEach(feature => {
            const foundDuplicateId = layerRef.current?.source.getFeatures().find(f => {
              const differentId = f.get('id') !== feature.get('id');
              if (!differentId) return false;

              const duplicateBeaconIds =
                !!f.get('assetId').uuid &&
                f.get('assetId').uuid === feature.get('assetId').uuid &&
                !!f.get('assetId').major &&
                f.get('assetId').major === feature.get('assetId').major &&
                !!f.get('assetId').minor &&
                f.get('assetId').minor === feature.get('assetId').minor;
              const duplicateGatewayId =
                !!f.get('assetId').gatewayId &&
                f.get('assetId').gatewayId === feature.get('assetId').gatewayId;
              const duplicateDeviceId =
                !!f.get('assetId').deviceId &&
                f.get('assetId').deviceId === feature.get('assetId').deviceId;

              return duplicateBeaconIds || duplicateGatewayId || duplicateDeviceId;
            });
            if (foundDuplicateId) hasDuplicateAssetId = feature;
          });

          if (hasDuplicateAssetId !== undefined && !hasUndefinedName) {
            setDirtySave({
              isDirty: !!hasDuplicateAssetId,
              issue: hasDuplicateAssetId
                ? hasDuplicateAssetId.get('type') === MicrofenceEntity.Beacon
                  ? 'UUID, Major and Minor combination must be unique'
                  : 'Microfence ID must be unique'
                : null,
            });
          }
        }

        if (hasUndefinedAssetId || hasUndefinedName || hasDuplicateName || hasDuplicateAssetId) {
          selectedFenceRef.current =
            hasUndefinedAssetId ?? hasUndefinedName ?? hasDuplicateName ?? hasDuplicateAssetId;
          const dirtyFence = (
            hasUndefinedAssetId ??
            hasUndefinedName ??
            hasDuplicateName ??
            hasDuplicateAssetId
          )?.getProperties() as GridRowData;
          setSelectedMicrofence(dirtyFence);
          return;
        }
      }
      selectedFenceRef.current = undefined;
      setSelectedMicrofence(undefined);
      return await saveMicrofenceLayerChanges();
    } else {
      // Validate Geofences
      let hasDuplicateName;
      let hasEmptyGeomobyPropertyName;
      let hasIncompleteOverrideRule;
      let hasInvalidPropertyOrOverride;

      const features = layerRef.current?.source.getFeatures();
      if (features) {
        const layerNotSelected = selectedGeofence?.layerId === UNKNOWN_LAYER;
        setDirtySave({
          isDirty: !!layerNotSelected,
          issue: layerNotSelected
            ? 'A group has not yet been selected. Please assign one to this geofence.'
            : null,
        });
        if (layerNotSelected) return;

        const hasUndefinedName = featureHasUndefinedName(
          features,
          EntityType.Geofence,
          setDirtySave,
        );

        if (!hasUndefinedName)
          hasDuplicateName = featureHasDuplicateName(features, EntityType.Geofence, setDirtySave);

        if (!hasUndefinedName && !hasDuplicateName)
          hasEmptyGeomobyPropertyName = featureHasEmptyGeomobyPropertyValue(features, setDirtySave);

        if (!hasUndefinedName && !hasDuplicateName && !hasEmptyGeomobyPropertyName)
          hasIncompleteOverrideRule = featureHasIncompleteOverrideRule(features, setDirtySave);

        if (
          !hasUndefinedName &&
          !hasDuplicateName &&
          !hasIncompleteOverrideRule &&
          !hasEmptyGeomobyPropertyName
        )
          hasInvalidPropertyOrOverride = featureHasInvalidPropertyOrOverride(
            features,
            setDirtySave,
          );

        if (
          hasUndefinedName ||
          hasDuplicateName ||
          hasEmptyGeomobyPropertyName ||
          hasIncompleteOverrideRule ||
          hasInvalidPropertyOrOverride
        ) {
          selectedFenceRef.current =
            hasUndefinedName ??
            hasDuplicateName ??
            hasEmptyGeomobyPropertyName ??
            hasIncompleteOverrideRule ??
            hasInvalidPropertyOrOverride;
          const dirtyFence = (
            hasUndefinedName ??
            hasDuplicateName ??
            hasEmptyGeomobyPropertyName ??
            hasIncompleteOverrideRule ??
            hasInvalidPropertyOrOverride
          )?.getProperties() as GridRowData;
          setSelectedGeofence(dirtyFence as GridRowData);
          setDisplayGeomobyProperties(
            Object.entries(dirtyFence.geomobyProperties)?.map(([property, value], index) => {
              return {
                index: index as number,
                property: property as string,
                value: value as string,
              };
            }),
          );
          setDisplayGeomobyOverrides(dirtyFence.geomobyOverrides ?? []);
          return;
        }
      }
      return await saveGeofenceLayerChanges();
    }
  };

  const uiUnsetEditing = () => {
    //when we editing and clicking cancel
    if (!editRef.current)
      throw new Error('UI should not be able to unset editing while not editing');
    if (!layerRef.current)
      throw new Error('UI should not be able to unset editing while no layer selected');
    if (!mapRef.current) return;

    //remove edit interactions
    const e = editRef.current;
    const m = mapRef.current;
    m.removeInteraction(e.draw);
    m.removeInteraction(e.modify);
    m.removeInteraction(e.modifyScaleRot);
    m.removeInteraction(e.snap);
    m.removeInteraction(e.translate);

    //remove edit state
    editRef.current = undefined;
    setEditing(false);
    document.removeEventListener('keyup', removeLastDrawnSegment);
  };

  const createNewLayer = (id: string, name: string) => {
    if (!mapRef.current) throw new Error('UI is unable to create a new layer when no map exists.');

    const newSource = new VectorSource();
    const newLayer = new VectorLayer({
      source: newSource,
      style: selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
      properties: {
        name,
        id,
        geomobyProperties: {},
      },
    });
    setLayers([{ id, name, source: newSource }, ...layers]);
    mapRef.current.addLayer(newLayer);
    setSelectedLayer({ id, name });
    layerChanged();
  };

  const deleteLayer = async (): Promise<void> => {
    if (!selectedLayer || !layerRef.current)
      throw new Error('UI should not trigger delete when no layer selected');
    if (!layers) throw new Error('UI should not trigger delete when there are no layers');
    if (!mapRef.current) return;

    const layerId = selectedLayer?.id;
    const layer = layerRef.current;
    const m = mapRef.current;

    const mapLayer = getLayerFromMap(layer.name);
    if (mapLayer) {
      m.removeLayer(mapLayer);
    }
    const freshLayer = m
      .getAllLayers()
      .find(
        (l, i) =>
          l instanceof VectorLayer &&
          (l.getSource()?.get('id') === FRESH_LAYER || l.get('id') === FRESH_LAYER),
      );
    if (freshLayer) {
      m.removeLayer(freshLayer);
    }

    const lyrs = layers;
    setLayers(lyrs.filter(lyr => lyr.id !== layerId));

    try {
      await axios.delete(`${triggersUrl}/${cid}/${pid}/geofences/${layerId}`, authedRequestConfig);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Delete' });
    } catch (error) {
      const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Layer);
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Delete',
        message: errorMessage,
      });
    }
    setDeletedFenceIds([]);
    setSelectedLayer(undefined);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    setCreateEditLayer(undefined);
    setCreateEditFence(undefined);
    selectedFenceRef.current = undefined;
    deselectAllFences(mapRef.current);
    setDrawType(undefined);
    layerIds.forEach(layer => changeVisibility(layer.id, layer.id !== layerId));
    setTotalFences(0);
  };

  const findMicrofenceFeature = (fenceId: string, layerId: string) => {
    const layer = getLayerFromMap(undefined, layerId);
    if (!layer) return;
    if (layer instanceof AnimatedCluster && layerId === MICROFENCE_LAYER_ID) {
      return layer
        .getSource()
        .getFeatures()
        .find(feature =>
          feature.get('features').find((f: Feature<Geometry>) => f.get('id') === fenceId),
        );
    }
  };

  const findFeature = async (
    fenceId: string,
    layerId: string,
    type: FenceGeometryType | undefined,
  ) => {
    let feature;

    const layer = getLayerFromMap(undefined, layerId);
    if (!layer) return;

    if (layerId === MICROFENCE_LAYER_ID) return findMicrofenceFeature(fenceId, layerId);

    feature = layer
      ?.getSource()
      .getFeatures()
      .find(f => f.get('id') === fenceId);
    if (!feature) {
      const geofence = await getGeofence(fenceId, layerId, type);
      if (geofence) {
        const newFence =
          type === FenceGeometryType.Polygon
            ? new Polygon(geofence.points.coordinates)
            : type === FenceGeometryType.Multipolygon
            ? new MultiPolygon(geofence.points.coordinates)
            : new LineString(geofence.points.coordinates);

        if (type === FenceGeometryType.Polygon) {
          const coords: number[][] = (newFence as Polygon).getCoordinates()[0];
          (newFence as Polygon).setCoordinates([
            coords.map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857')),
          ]);
        } else if (type === FenceGeometryType.Multipolygon) {
          const coords: number[][][] = (newFence as MultiPolygon).getCoordinates().map(polygon => {
            return polygon[0].map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857'));
          });
          (newFence as MultiPolygon).setCoordinates([coords]);
        } else if (type === FenceGeometryType.Line) {
          const coords: number[][] = (newFence as LineString).getCoordinates();
          (newFence as LineString).setCoordinates(
            coords.map(coord => transform(coord, 'EPSG:4326', 'EPSG:3857')),
          );
        }
        feature = new Feature(newFence);
        feature.setGeometry(newFence);
        feature.setProperties({ ...geofence, layerId });
        // Check again!
        const existingFeature = layer
          ?.getSource()
          .getFeatures()
          .find(f => f.get('id') === fenceId);
        if (existingFeature) {
          layer?.getSource().removeFeature(existingFeature);
        }
        layer?.getSource().addFeature(feature);
        return feature;
      }
    }
    return feature;
  };

  const uiDeleteFence = async (
    fence: GridRowData,
    type: FenceGeometryType | undefined,
  ): Promise<void> => {
    const fenceId = fence.id ?? fence.get('id');
    const layerId = fence.layerId ?? fence.get('layerId');
    const layer = getLayerFromMap(undefined, layerId);
    if (!layer) throw new Error('UI should not be able to delete a feature nonexistent layer.');

    const microfences =
      (
        (
          mapRef.current
            ?.getAllLayers()
            .find(lyr => lyr.get('id') === MICROFENCE_LAYER_ID) as AnimatedCluster
        )?.getSource() as Cluster
      )
        ?.getSource()
        .getFeatures() ?? [];

    if (!mapRef.current) return;
    const feature = await findFeature(fenceId, layerId, type);
    if (!feature) throw new Error(`Failed to find fence to perform deletion [${fenceId}]`);

    if (!fenceId?.includes(FRESH))
      setDeletedFenceIds(list => [
        ...list,
        {
          id: fenceId,
          type:
            feature.getGeometry()?.getType()?.toLowerCase() === FenceGeometryType.Polygon ||
            feature.get('points')?.type?.toLowerCase() === FenceGeometryType.Polygon
              ? FenceGeometryType.Polygon
              : feature.getGeometry()?.getType()?.toLowerCase() ===
                  FenceGeometryType.Multipolygon ||
                feature.get('points')?.type?.toLowerCase() === FenceGeometryType.Multipolygon
              ? FenceGeometryType.Multipolygon
              : FenceGeometryType.Line,
        },
      ]);

    const bufferFeature =
      feature.get('zone') === FenceZone.breach
        ? layer
            .getSource()
            .getFeatures()
            .find(f => f.get('parentId') === feature.get('id'))
        : undefined;

    layer.getSource().removeFeature(feature);
    setSelectedGeofence(undefined);
    setSelectedMicrofence(undefined);
    selectedFenceRef.current = undefined;

    if (layerId !== MICROFENCE_LAYER_ID && availableGeofences.length > 0) {
      setAvailableGeofences(availableGeofences.filter(f => f.id !== fenceId));
    } else if (
      selectedLayer?.id === MICROFENCE_LAYER_ID &&
      (availableMicrofences.length > 0 || microfences.length)
    ) {
      setAvailableMicrofences(
        (availableMicrofences ?? microfences.map(m => m.getProperties())).filter(
          f => f.id !== fenceId,
        ),
      );
    }

    if (bufferFeature) uiDeleteFence(bufferFeature, FenceGeometryType.Polygon);
  };

  const uiSetEditing = () => {
    //when user clicks edit
    if (editRef.current)
      throw new Error('UI should not be able to set editing while already editing');
    if (!mapRef.current) return;
    if (!selectedLayer || selectedLayer?.id === ALL_LAYERS) {
      createNewLayer(UNKNOWN_LAYER, UNKNOWN_LAYER);
      const foundNewLayer = mapRef.current
        .getAllLayers()
        .find(lyr => lyr instanceof VectorLayer && lyr.get('name') === UNKNOWN_LAYER);
      if (foundNewLayer) {
        layerRef.current = {
          id: UNKNOWN_LAYER,
          name: UNKNOWN_LAYER,
          source: foundNewLayer.getSource(),
        };
        // Starting to draw when no layer has beem selected, then Select all layers.
        setClearFilter(true);
      }
    }
    if (!layerRef.current)
      throw new Error('UI should not be able to set editing while no layer selected');

    const lyr = layerRef.current;
    const m = mapRef.current;
    if (
      selectedFenceRef.current?.getGeometry()?.getType().toLowerCase() !==
      GeofenceEntityType.Multipolygon
    ) {
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      setAvailableGeofences([]);
      setAvailableMicrofences([]);
      selectedFenceRef.current = undefined;
    }
    unsetMapClickSelection(m);

    lyr.source.on('addfeature', e => {
      if (e.feature?.get('id') || e.feature?.get('isMeasurementTool')) return;
      const layerId =
        (e.target as VectorSource<Geometry>)?.get('id') ??
        (!selectedLayer?.id || selectedLayer.id === ALL_LAYERS ? UNKNOWN_LAYER : selectedLayer.id);
      if (
        selectedLayer?.id !== MICROFENCE_LAYER_ID &&
        layers &&
        !layers.find(l => l.id === UNKNOWN_LAYER)
      ) {
        const foundLayer = getLayerFromMap(undefined, layerId);
        if (foundLayer && layerId) {
          setSelectedLayer({
            id: layerId,
            name: foundLayer.get('name') ?? foundLayer.getSource()?.get('name'),
          });
        }
      }
      if (e.feature?.getGeometry().getType() === 'Circle') {
        const tempFeat = fromCircle(e.feature.getGeometry() as Circle);
        e.feature.setGeometry(tempFeat);
      }

      if (
        selectedFenceRef.current &&
        selectedFenceRef.current.getGeometry() &&
        e.feature?.getGeometry().getType().toLowerCase() === FenceGeometryType.Multipolygon &&
        (selectedFenceRef.current.get('type') === FenceGeometryType.Multipolygon ||
          selectedFenceRef.current.get('points')?.type.toLowerCase() ===
            FenceGeometryType.Multipolygon)
      ) {
        const coords = (selectedFenceRef.current.getGeometry() as MultiPolygon)?.getCoordinates();
        if (
          coords.find(
            c => JSON.stringify(c) === JSON.stringify(e.feature?.getGeometry().getCoordinates()[0]),
          )
        )
          return;
        coords.push(e.feature.getGeometry().getCoordinates()[0]);
        selectedFenceRef.current.setGeometry(new MultiPolygon(coords));

        if (selectedFenceRef.current.get('points')) {
          selectedFenceRef.current.get('points').coordinates.push([
            e.feature
              .getGeometry()
              .getCoordinates()[0][0]
              .map((c: [number, number]) => transform(c, 'EPSG:3857', 'EPSG:4326')),
          ]);
          if (!selectedFenceRef.current.get(FRESH)) {
            selectedFenceRef.current.set('updated', true);
          }
        }
        const featureToRemove = layerRef.current?.source
          .getFeatures()
          .find((feature: Feature<Geometry>) => feature.get('id') === e.feature?.get('id'));
        if (featureToRemove) {
          layerRef.current?.source.removeFeature(featureToRemove);
        }
        setEditing(true);
        return;
      }

      const type =
        e.feature?.getGeometry().getType() === 'LineString'
          ? FenceGeometryType.Line
          : e.feature?.getGeometry().getType().toLowerCase();
      const fenceName =
        selectedLayer?.id === MICROFENCE_LAYER_ID
          ? jsUcFirst(EntityType.Microfence) + getMicroFenceNumber()
          : jsUcFirst(EntityType.Geofence) +
            getNewGeofenceNumber(
              lyr.source.getFeatures().map(f => f.getProperties() as GridRowData),
            );
      const fenceId = `fresh-${new Date().getTime()}`;
      e.feature?.setProperties({
        name: fenceName,
        id: fenceId,
        type,
        fresh: true,
        selected: true,
        layerId,
        layerName: lyr.source.get('name'),
        microfenceType: microfenceDrawTypeRef.current,
        geomobyProperties:
          selectedLayer?.id === MICROFENCE_LAYER_ID
            ? Object.fromEntries(
                Object.entries(MICROFENCE_DEFAULT_PROPS).map(([key, val]) => [key, String(val)]),
              )
            : {},
        zone:
          type === FenceGeometryType.Line
            ? undefined
            : type === FenceGeometryType.Multipolygon
            ? FenceZone.cleared
            : FenceZone.none,
        numberOfArrows: type === FenceGeometryType.Line ? 4 : 0,
      });

      if (e.feature) {
        setCreateEditFence(RequestType.Create);
        if (layerId === MICROFENCE_LAYER_ID) {
          let assetId: {
            deviceId?: string;
            gatewayId?: string;
            uuid?: string;
            major?: number;
            minor?: number;
          };
          if (e.feature.get('microfenceType') === MicrofenceEntity.Device) {
            assetId = {
              deviceId: '',
            };
          } else if (
            e.feature.get('microfenceType') === MicrofenceEntity.Gateway ||
            e.feature.get('microfenceType') === MicrofenceEntity.Smartplug
          ) {
            assetId = {
              gatewayId: '',
            };
          } else if (e.feature.get('microfenceType') === MicrofenceEntity.Beacon) {
            assetId = {
              uuid: '',
              major: 0,
              minor: 0,
            };
          }
          const newFeature = e.feature;
          lyr.source.forEachFeature(feature => {
            if (feature.get('id') === newFeature.get('id')) {
              feature.set('assetId', assetId);
              setSelectedMicrofence(feature.getProperties());
            }
          });

          setAvailableMicrofences([
            ...lyr.source.getFeatures().map(f => f.getProperties() as GridRowData),
          ]);
        } else {
          e.feature.set('geomobyOverrides', []);
          freshGeofencesRef.current = [...freshGeofencesRef.current, e.feature.getProperties()];
          setFreshGeofences(freshGeofencesRef.current);
          setAvailableGeofences(
            lyr.source
              .getFeatures()
              .filter(fence => !deletedFenceIds.find(f => f.id === fence.get('id')))
              .map(f => f.getProperties() as GridRowData),
          );
          setSelectedGeofence(e.feature.getProperties() as GridRowData);
        }
      }
    });
    const draw = new Draw({
      type: GeometryType.POLYGON,
      source: lyr.source,
      stopClick: true,
    });

    draw.on('drawend', layerChanged);
    const modify = new Modify({
      source: lyr.source,
    });

    const translate = new Translate({
      condition: function (event) {
        return primaryAction(event) && platformModifierKeyOnly(event);
      },
      layers: m.getAllLayers(),
    });

    modify.on('modifyend', (e: ModifyEvent) => featureModified(e, lyr.source));

    const es = {
      draw: draw,
      modify: modify,
      modifyScaleRot: modify,
      snap: new Snap({
        source: lyr.source,
      }),
      translate: translate,
    };

    editRef.current = es;
    m.addInteraction(es.draw);
    m.addInteraction(es.snap);
    m.addInteraction(es.translate);
    setEditing(true);
    document.addEventListener('keyup', removeLastDrawnSegment);
  };

  const scalableRotatableLayer = new VectorLayer({
    className: 'Scalable-rotatable-layer',
    source: layerRef.current?.source ?? new VectorSource(),
    style: feature => {
      const styles = [
        new Style({
          geometry: feature => {
            const modifyGeometry = feature.get('modifyGeometry');
            return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
          },
          stroke:
            feature.get('zone') === FenceZone.breach
              ? new Stroke({ color: [209, 27, 21, 1.0], width: 3 })
              : feature.get('zone') === FenceZone.buffer
              ? new Stroke({ color: [235, 138, 12, 1.0], width: 3 })
              : feature.get('zone') === FenceZone.cleared
              ? new Stroke({ color: [182, 66, 245], width: 3 })
              : new Stroke({ color: [76, 184, 196], width: 3 }),
          zIndex: FenceZIndexes.FENCE_MID_Z_INDEX,
        }),
      ];
      const modifyGeometry = feature.get('modifyGeometry');
      const geometry = modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
      const result = calculateCenterOfFence(
        geometry,
        geometryTypeOfEntity(feature.getProperties() as GridRowData),
      );
      const center = result.center;
      if (center) {
        const coordinates = result.coordinates;
        if (coordinates) {
          const minRadius = result.minRadius;
          const sqDistances = result.sqDistances;
          const rsq = minRadius * minRadius;
          const points = (coordinates as number[][]).filter(
            (coordinate: number[], index: number) => {
              return sqDistances[index] > rsq;
            },
          );
        }
      }
      if (geometryTypeOfEntity(feature) === FenceGeometryType.Line) {
        styles.push(
          ...stylesForLineFence(getCoordsForNewTripwire(geometry.flatCoordinates, 4), false, true),
        );
      }
      return styles;
    },
  });

  const updateMeasureTooltip = (olmap: olMap) => {
    if (measureTooltipElementRef.current) {
      measureTooltipElementRef.current.parentNode?.removeChild(measureTooltipElementRef.current);
    }
    measureTooltipElementRef.current = document.createElement('div');
    measureTooltipRef.current = new Overlay({
      element: measureTooltipElementRef.current,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    olmap.addOverlay(measureTooltipRef.current);
  };

  const addFreshFeature = (addedFeature: Feature<Geometry>) => {
    setTimeout(() => {
      if (addedFeature.getProperties()?.id?.includes(FRESH)) {
        currentlyDisplayedTripwiresRef.current.push(addedFeature);
      }
      currentlyDisplayedTripwiresRef.current = Array.from(
        new Set(currentlyDisplayedTripwiresRef.current),
      );
    }, 100);
  };

  const addShapeChangeModifyInteration = (
    source: VectorSource<Geometry>,
    type: GeofenceEntityType,
    addedFeature?: Feature<Geometry>,
  ) => {
    refreshInteractions();
    if (!editRef.current || !mapRef.current) return;
    const modifiableFeatures: Collection<Feature<Geometry>> = new Collection();
    if (addedFeature) {
      modifiableFeatures.push(addedFeature);
    }
    source.forEachFeature(feature => {
      if (
        geometryTypeOfEntity(feature.getProperties() as GridRowData) ===
        (type as unknown as FenceGeometryType)
      ) {
        modifiableFeatures.push(feature);
      }
    });
    editRef.current.modify = new Modify({
      source: source,
      insertVertexCondition: type === GeofenceEntityType.Line ? never : always,
      features: modifiableFeatures,
    });
    if (type === GeofenceEntityType.Multipolygon)
      editRef.current.modify = styleForInteraction(
        'MODIFIY',
        source,
        GeometryType.MULTI_POLYGON,
        '#B642F5',
      ) as Modify;

    editRef.current.modify.on('modifyend', (e: ModifyEvent) => {
      featureModified(e, source);
      if (type === GeofenceEntityType.Polygon || type === GeofenceEntityType.Multipolygon) {
        e.features.forEach(feature => {
          const geometry = feature.getGeometry();
          if (!(geometry instanceof Geometry)) return;
          if (selectedFenceRef.current?.get('id') !== feature.get('id')) return;
          if (type === GeofenceEntityType.Polygon && layerRef.current) {
            setHasFences(!!featureContainsFeature(geometry, layerRef.current.source));
          }
          selectedFenceRef.current = feature as Feature<Geometry>;
          selectedFenceRef.current.set('selected', true);
          setSelectedGeofence(feature.getProperties() as GridRowData);
        });
      }
      if (type !== GeofenceEntityType.Line || !addedFeature) return;
      addedFeature.setStyle(selectedFenceStyle(new Map()));
      addFreshFeature(addedFeature);
    });
    editRef.current.modify.on('modifystart', (e: ModifyEvent) => {
      selectedFenceRef.current = e.features.getArray()[0] as Feature<Geometry>;
      selectedFenceRef.current.set('selected', true);
      setSelectedGeofence(e.features.getArray()[0].getProperties() as GridRowData);
      if (type !== GeofenceEntityType.Line) return;
      if (!addedFeature) return;
      addedFeature.setProperties({ numberOfArrows: 4 });
    });

    mapRef.current.addInteraction(editRef.current.modify);
  };

  const addScaleRotModifyInteration = (
    source: VectorSource<Geometry>,
    type: GeofenceEntityType,
    addedFeature?: Feature<Geometry>,
  ) => {
    refreshInteractions();
    if (!editRef.current || !mapRef.current) return;
    const modifiableFeatures: Collection<Feature<Geometry>> = new Collection();
    if (addedFeature) {
      modifiableFeatures.push(addedFeature);
    }
    source.forEachFeature(feature => {
      if (
        geometryTypeOfEntity(feature.getProperties() as GridRowData) ===
        (type as unknown as FenceGeometryType)
      ) {
        modifiableFeatures.push(feature);
      }
    });

    editRef.current.modifyScaleRot = new Modify({
      source: source,
      condition: event => {
        return primaryAction(event) && !platformModifierKeyOnly(event);
      },
      deleteCondition: never,
      insertVertexCondition: type === GeofenceEntityType.Line ? never : always,
      style: defaultScaleRotFenceStyle(new Map()),
    });
    editRef.current.translate = new Translate({
      condition: event => {
        return primaryAction(event) && platformModifierKeyOnly(event);
      },
      layers: [scalableRotatableLayer],
    });

    mapRef.current.addLayer(scalableRotatableLayer);
    editRef.current.modifyScaleRot.on('modifystart', (e: ModifyEvent) => {
      mapRef.current?.getAllLayers().forEach(layer => {
        if (!(layer instanceof VectorLayer) || layer.getClassName() === 'Scalable-rotatable-layer')
          return;
        layer.setVisible(false);
      });

      e.features.forEach(feature => {
        const geom = feature.getGeometry();
        if (!(geom instanceof Geometry)) return;
        (feature as Feature<Geometry>).set('modifyGeometry', { geometry: geom.clone() }, true);
        selectedFenceRef.current = feature as Feature<Geometry>;
        selectedFenceRef.current?.set('selected', true);
        setSelectedGeofence(feature.getProperties() as GridRowData);
      });
      if (type !== GeofenceEntityType.Line) return;
      if (!addedFeature) return;
      addedFeature.setProperties({ numberOfArrows: 4 });
    });

    editRef.current.modifyScaleRot.on('modifyend', (e: ModifyEvent) => {
      changeVisibility(layerRef.current?.source.get('id'), true);
      e.features.forEach(feature => {
        const geom = feature.getGeometry();
        if (!(geom instanceof Geometry)) return;
        const modifyGeometry = feature.get('modifyGeometry');
        if (modifyGeometry) {
          (feature as Feature<Geometry>).setGeometry(modifyGeometry.geometry);
          (feature as Feature<Geometry>).unset('modifyGeometry', true);
          if (type === GeofenceEntityType.Polygon && layerRef.current) {
            setHasFences(
              !!featureContainsFeature(modifyGeometry.geometry, layerRef.current.source),
            );
          }
          selectedFenceRef.current = feature as Feature<Geometry>;
          selectedFenceRef.current?.set('selected', true);
          setSelectedGeofence(feature.getProperties() as GridRowData);
        }
      });

      featureModified(e, source);
      if (type !== GeofenceEntityType.Line || !addedFeature) return;
      addedFeature.setStyle(selectedFenceStyle(new Map()));
      addFreshFeature(addedFeature);
    });

    mapRef.current.addInteraction(editRef.current.modifyScaleRot);
    mapRef.current.addInteraction(editRef.current.translate);
  };

  const refreshInteractions = () => {
    if (!mapRef.current) return;
    if (!editRef.current) return;
    const olmap = mapRef.current;
    olmap.removeInteraction(editRef.current.draw);
    olmap.removeInteraction(editRef.current.modify);
    olmap.removeInteraction(editRef.current.modifyScaleRot);
    olmap.removeInteraction(editRef.current.translate);
    const scaleRotLayer = olmap
      .getAllLayers()
      .find(l => l.getClassName() === 'Scalable-rotatable-layer');
    if (scaleRotLayer) {
      mapRef.current.removeLayer(scaleRotLayer);
    }
  };

  const removeLastDrawnSegment = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      editRef.current?.draw.removeLastPoint();
    }
  };

  const drawEnd = (
    olmap: olMap,
    geometry: Geometry,
    layerSource: VectorSource<Geometry>,
    type: GeofenceEntityType,
  ) => {
    layerRef.current?.source
      .getFeatures()
      .filter((f: Feature<Geometry>) => f.get('selected'))
      .forEach((f: Feature<Geometry>) => {
        f.set('selected', false);
      });

    layerChanged();
    if (type === GeofenceEntityType.Polygon && layerRef.current) {
      setHasFences(!!featureContainsFeature(geometry, layerRef.current.source));
    }
    if (!editRef.current) return;
    // Slight hack to reset the editType. The default editType will not be active otherwise.
    setEditType(editType);
    // Needs a short interval for the new polygon to be registered as part of the layer.
    setTimeout(() => {
      addShapeChangeModifyInteration(layerSource, type);
      if (!editRef.current) return;
      olmap.addInteraction(editRef.current.draw);
    });
  };

  const updateMeasurementType = (type: 'Polygon' | 'LineString') => {
    if (!mapRef.current) return;
    if (!editRef.current) return;
    if (!layerRef.current) return;
    const olmap = mapRef.current;
    refreshInteractions();
    const layerSource = layerRef.current?.source;

    microfenceDrawTypeRef.current = undefined;
    editRef.current.draw = new Draw({
      source: layerSource,
      type,
      style: [
        new Style({
          image: new CircleStyle({
            radius: 2,
            fill: new Fill({
              color: [1, 1, 1],
            }),
          }),
          stroke: new Stroke({
            color: [1, 1, 1],
            lineDash: [4, 8],
          }),
          zIndex: FenceZIndexes.LINE_Z_INDEX,
        }),
      ],
    });

    olmap.addInteraction(editRef.current.draw);
    editRef.current.draw.on('drawstart', e => {
      e.feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: [1, 1, 1],
            lineDash: [4, 8],
          }),
        }),
      );

      updateMeasureTooltip(olmap);
      let tooltipCoord = e.feature?.getGeometry().getCoordinates();
      e.feature.getGeometry().on('change', (evt: Event) => {
        const geom = evt.target;
        if (geom instanceof Polygon) {
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
          const area = calculateArea(geom, measurementType);
          if (measureTooltipElementRef.current && measureTooltipRef.current && area) {
            measureTooltipElementRef.current.innerHTML = area;
            measureTooltipElementRef.current.style.background = `rgb(${[0, 0, 0, 0.25]})`;
            measureTooltipElementRef.current.style.borderRadius = '5px';
            measureTooltipRef.current.setPosition(tooltipCoord);
          }
        } else if (geom instanceof LineString) {
          tooltipCoord = geom.getLastCoordinate();
          const distance = calculateMeasurementDistance(geom, measurementType);
          if (measureTooltipElementRef.current && measureTooltipRef.current && distance) {
            measureTooltipElementRef.current.innerHTML = distance;
            measureTooltipElementRef.current.style.background = `rgb(${[0, 0, 0, 0.25]})`;
            measureTooltipElementRef.current.style.borderRadius = '5px';
            measureTooltipRef.current.setPosition(tooltipCoord);
          }
        }
      });
    });

    editRef.current.draw.on('drawend', e => {
      e.feature.set('isMeasurementTool', true);
      e.feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: [1, 1, 1, 0],
          }),
        }),
      );
      const foundFence = layerRef.current?.source
        .getFeatures()
        .find(f => f.get('id') === e.feature.get('id'));
      if (foundFence) {
        layerRef.current?.source.removeFeature(foundFence);
      }
      updateMeasureTooltip(olmap);
    });
  };

  const changeDrawShape = () => {
    if (!mapRef.current) return;
    if (!editRef.current) return;
    if (!layerRef.current) return;
    if (!drawType) return;
    const olmap = mapRef.current;
    refreshInteractions();
    const layerSource = layerRef.current?.source;

    if (editType) {
      switch (editType) {
        case EditType.ShapeChange:
          addShapeChangeModifyInteration(
            layerSource,
            drawType === DrawType.Circle || drawType === DrawType.Polygon
              ? GeofenceEntityType.Polygon
              : drawType === DrawType.Multipolygon
              ? GeofenceEntityType.Multipolygon
              : GeofenceEntityType.Line,
          );
          break;
        case EditType.ScaleRot:
          addScaleRotModifyInteration(
            layerSource,
            drawType === DrawType.Circle || drawType === DrawType.Polygon
              ? GeofenceEntityType.Polygon
              : drawType === DrawType.Multipolygon
              ? GeofenceEntityType.Multipolygon
              : GeofenceEntityType.Line,
          );
          break;
      }
    }

    switch (drawType) {
      case DrawType.Circle:
        microfenceDrawTypeRef.current = undefined;
        if (layerSource.get('name') === TRACKING_BOUNDS) {
          editRef.current.draw = styleForInteraction(
            'DRAW',
            layerSource,
            GeometryType.CIRCLE,
            WHITE,
          ) as Draw;
        } else {
          editRef.current.draw = new Draw({
            source: layerSource,
            type: GeometryType.CIRCLE,
            stopClick: true,
          });
        }

        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          if (layerSource.get('name') === TRACKING_BOUNDS) e.feature.setStyle(undefined);
          drawEnd(olmap, e.feature.getGeometry(), layerSource, GeofenceEntityType.Polygon);
        });
        break;
      case DrawType.Polygon:
        microfenceDrawTypeRef.current = undefined;
        if (layerSource.get('name') === TRACKING_BOUNDS) {
          editRef.current.draw = styleForInteraction(
            'DRAW',
            layerSource,
            GeometryType.POLYGON,
            WHITE,
          ) as Draw;
          olmap.addInteraction(editRef.current.draw);
          editRef.current.draw.on('drawstart', e => {
            e.feature.setStyle(
              new Style({
                fill: new Fill({ color: [255, 255, 255, 0.3] }),
                stroke: new Stroke({
                  color: 'rgba(0, 0, 0, 0)',
                  width: 3,
                }),
              }),
            );
          });
        } else {
          editRef.current.draw = new Draw({
            source: layerSource,
            type: GeometryType.POLYGON,
            stopClick: true,
          });
          olmap.addInteraction(editRef.current.draw);
        }
        editRef.current.draw.on('drawend', e => {
          if (layerSource.get('name') === TRACKING_BOUNDS) e.feature.setStyle(undefined);
          drawEnd(olmap, e.feature.getGeometry(), layerSource, GeofenceEntityType.Polygon);
        });
        break;
      case DrawType.Multipolygon:
        microfenceDrawTypeRef.current = undefined;
        editRef.current.draw = styleForInteraction(
          'DRAW',
          layerSource,
          GeometryType.MULTI_POLYGON,
          '#B642F5',
        ) as Draw;
        editRef.current.modify = styleForInteraction(
          'MODIFIY',
          layerSource,
          GeometryType.MULTI_POLYGON,
          '#B642F5',
        ) as Modify;
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawstart', e => {
          e.feature.setStyle(
            new Style({
              fill: new Fill({ color: [255, 255, 255, 0.3] }),
              stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0)',
                width: 3,
              }),
            }),
          );
        });

        editRef.current.draw.on('drawend', e => {
          e.feature.setStyle(undefined);
          drawEnd(olmap, e.feature.getGeometry(), layerSource, GeofenceEntityType.Multipolygon);
        });
        break;
      case DrawType.Tripwire:
        microfenceDrawTypeRef.current = undefined;
        editRef.current.draw = new Draw({
          source: layerSource,
          type: GeometryType.LINE_STRING,
          stopClick: true,
          maxPoints: 2,
          minPoints: 2,
        });
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          drawEnd(olmap, e.feature.getGeometry(), layerSource, GeofenceEntityType.Line);
          if (!editRef.current) return;
          olmap.removeInteraction(editRef.current.modify);
          addFreshFeature(e.feature);
        });
        break;
      case DrawType.MicrofenceGateway:
        microfenceDrawTypeRef.current = MicrofenceEntity.Gateway;
        editRef.current.draw = new Draw({
          source: layerSource,
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          layerChanged();
          e.feature.set('microfenceType', MicrofenceEntity.Gateway);
        });

        editRef.current.modify = new Modify({
          source: layerSource,
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.modify);
        editRef.current.modify.on('modifyend', (e: ModifyEvent) => featureModified(e, layerSource));
        break;
      case DrawType.MicrofenceBeacon:
        microfenceDrawTypeRef.current = MicrofenceEntity.Beacon;
        editRef.current.draw = new Draw({
          source: layerSource,
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          layerChanged();
          e.feature.set('microfenceType', MicrofenceEntity.Beacon);
        });

        editRef.current.modify = new Modify({
          source: layerSource,
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.modify);
        editRef.current.modify.on('modifyend', (e: ModifyEvent) => featureModified(e, layerSource));
        break;
      case DrawType.MicrofenceDevice:
        microfenceDrawTypeRef.current = MicrofenceEntity.Device;
        editRef.current.draw = new Draw({
          source: layerSource,
          type: GeometryType.POINT,
          stopClick: true,
        });
        olmap.addInteraction(editRef.current.draw);
        editRef.current.draw.on('drawend', e => {
          layerChanged();
          e.feature.set('microfenceType', MicrofenceEntity.Device);
        });
        editRef.current.modify = new Modify({
          source: layerSource,
          hitDetection: olmap.getAllLayers().find(l => l.get('id') === MICROFENCE_LAYER_ID) as
            | HitDetectionLayer
            | undefined,
        });
        olmap.addInteraction(editRef.current.modify);
        editRef.current.modify.on('modifyend', (e: ModifyEvent) => featureModified(e, layerSource));
        break;
      case DrawType.Measure:
        setMeasurementType(MeasurementType.M);
        break;
    }
  };

  const changeMeasurementType = () => {
    if (!measurementType) return;

    if (measurementType.includes('2')) {
      updateMeasurementType('Polygon');
    } else {
      updateMeasurementType('LineString');
    }
  };

  const updateBufferZoneGeometry = async (
    fence: GridRowData,
    offset: number,
    bufferShape: BufferShapeType,
  ): Promise<{ breach: Feature<Polygon>; buffer: Feature<Polygon> } | undefined> => {
    if (!mapRef.current) return;

    const feature = await findFeature(fence.id, fence.layerId, FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const geometry = (feature as Feature<Geometry>).getGeometry();
    if (!geometry)
      throw new Error('UI should not be able to add zones to a feature not without a geometry');

    const coords: number[][] = (geometry as Polygon).getCoordinates()[0];
    if (!coords.length)
      throw new Error('UI should not be able to add zones to a feature without coords');

    // Find a circle that contains the feature
    const center = getCenter(geometry.getExtent());
    const centerRadius = Math.max(
      ...coords.map(coord => new LineString([center, coord]).getLength()),
    );

    // Using the centroid may result in a smaller circle
    const centroid = coords
      .reduce((acc, cur) => [acc[0] + cur[0], acc[1] + cur[1]])
      .map(sum => sum / coords.length);
    const centroidRadius = Math.max(
      ...coords.map(coord => new LineString([centroid, coord]).getLength()),
    );

    const radiusScaleFactor = 1.005; // Determined by trial and error
    const coordinates = (geometry as Polygon)
      .getCoordinates()[0]
      .map(coord => transform(coord, 'EPSG:3857', 'EPSG:4326'));
    const maxY = Math.max(...coordinates.map(coord => coord[1]));
    const minY = Math.min(...coordinates.map(coord => coord[1]));
    const scaledOffset = offset / Math.cos((((maxY + minY) / 2) * Math.PI) / 180);

    // Use whichever has the smaller radius, adding on the buffer distance (scaling both as per the above)
    if (bufferShape === BufferShapeType.Circle) {
      return {
        breach: feature as Feature<Polygon>,
        buffer: new Feature(
          fromCircle(
            centerRadius < centroidRadius
              ? new Circle(center, centerRadius * radiusScaleFactor + scaledOffset)
              : new Circle(centroid, centroidRadius * radiusScaleFactor + scaledOffset),
          ),
        ),
      };
    } else {
      const bufferZone = (
        await axios.post<{ points: { type: string; coordinates: number[][][] } }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${feature.get('layerId')}/polygon/build-zone`,
          {
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
            bufferMeters: offset === undefined || offset < 1 ? 1 : offset,
            limit: 1000,
          },
          authedRequestConfig,
        )
      ).data;

      const bufferPoints = polygonPointsFromJson(bufferZone);
      return {
        breach: feature as Feature<Polygon>,
        buffer: new Feature({
          geometry: new Polygon((bufferPoints?.getGeometry() as Polygon).getCoordinates()),
        }),
      };
    }
  };

  const setAsBreachZone = async (
    fence: GridRowData,
    bufferId: string | undefined,
    offset: number,
    bufferShape: BufferShapeType,
  ): Promise<void> => {
    const layer = getLayerFromMap(undefined, fence.layerId);
    if (!layer) throw new Error('UI should not be able to delete a feature nonexistent layer.');

    const geometries = await updateBufferZoneGeometry(fence, offset, bufferShape);
    if (!geometries) throw new Error('UI was unable to create/update the desired buffer zone.');

    const breach = geometries.breach;
    const buffer = geometries.buffer;
    if (bufferId) {
      const existingBuffer = await findFeature(bufferId, fence.layerId, FenceGeometryType.Polygon);
      if (existingBuffer) {
        const updatedBuffer = buffer;
        updatedBuffer.setProperties({
          ...existingBuffer.getProperties(),
          geometry: updatedBuffer.getGeometry(),
          fresh: existingBuffer.get('id')?.includes(FRESH),
          updated: !existingBuffer.get('id')?.includes(FRESH),
        });
        layer.getSource().removeFeature(existingBuffer);
        layer.getSource().addFeature(updatedBuffer);
        return;
      }
    }

    const newFenceName = `${breach.get('name')}_warning`.replace(/(\D)0$/, '$1');
    const newfenceId = `fresh-buffer-${new Date().getTime()}`;
    buffer.setProperties({
      name: newFenceName,
      id: newfenceId,
      layerId: breach.get('layerId'),
      type: 'polygon',
      fresh: true,
      zone: FenceZone.buffer,
      geomobyProperties: {},
      geomobyOverrides: [],
      parentId: breach.get('id'),
    });
    layer.getSource().addFeature(buffer);

    breach.setProperties({
      zone: FenceZone.breach,
      parentId: undefined,
      updated: true,
    });

    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === breach.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== breach.get('id')),
        { ...breachIsFresh, zone: FenceZone.breach },
      ];
    }

    if (availableGeofences.length > 0) {
      const index = availableGeofences.findIndex(f => f.id === selectedFenceRef.current?.get('id'));
      const existingBufferIndex = availableGeofences.findIndex(
        f => f.id === breach.get('parentId'),
      );
      if (existingBufferIndex > -1) {
        availableGeofences.splice(existingBufferIndex, 1);
      }
      const bufferZone = {
        name: newFenceName,
        id: newfenceId,
        type: 'polygon',
        zone: FenceZone.buffer,
        geomobyProperties: {},
        geomobyOverrides: [] as GeomobyOverride[],
        parentId: breach.get('id'),
      } as FenceNameIdZone;
      if (index > -1) {
        availableGeofences[index].zone = FenceZone.breach;
        availableGeofences[index].parentId = undefined;
      }
      freshGeofencesRef.current = [...freshGeofencesRef.current, bufferZone];
      availableGeofences.splice(index + 1, 0, bufferZone);
    }
    setTotalFences(totalFences + 1 ?? 0);
    setAvailableGeofences(availableGeofences);
    setFreshGeofences(freshGeofencesRef.current);
    setZoneChange(new Date());
  };

  const setAsBufferZone = async (fence: GridRowData): Promise<void> => {
    if (!layerRef.current)
      throw new Error('UI should not be able to add zones while no layer selected');
    if (!mapRef.current) return;

    const lyr = layerRef.current;
    const feature = await findFeature(fence.id, lyr.source.get('id'), FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const geometry = feature.getGeometry();
    if (!geometry)
      throw new Error('UI should not be able to add zones to a feature without geometry');
    const containedFeature = featureContainsFeature(geometry, layerRef.current.source);

    if (!containedFeature?.get('parentId')) {
      containedFeature?.setProperties({
        zone: FenceZone.breach,
        type: 'polygon',
        parentId: undefined,
        updated: true,
      });
      feature.setProperties({
        zone: FenceZone.buffer,
        type: 'polygon',
        parentId: containedFeature?.get('id'),
        name: `${containedFeature?.get('name')}_warning`,
        updated: true,
      });
    }

    const bufferIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (bufferIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        {
          ...bufferIsFresh,
          name: `${containedFeature?.get('name')}_warning`,
          zone: FenceZone.buffer,
        },
      ];
    }
    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === containedFeature?.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== containedFeature?.get('id')),
        { ...breachIsFresh, zone: FenceZone.breach },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const bufferIndex = availableGeofences.findIndex(
          f => f.id === selectedFenceRef.current?.getProperties().id,
        );
        if (bufferIndex > -1 && containedFeature) {
          const containedFeatureIndex = availableGeofences.findIndex(
            f => f.id === containedFeature.getProperties().id,
          );
          if (containedFeatureIndex > -1) {
            availableGeofences[containedFeatureIndex].zone = FenceZone.breach;
            availableGeofences[containedFeatureIndex].parentId = undefined;
          }
          availableGeofences[bufferIndex].zone = FenceZone.buffer;
          availableGeofences[bufferIndex].parentId = containedFeature.get('id');
          availableGeofences[bufferIndex].name = `${containedFeature.get('name')}_warning`;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
    setFreshGeofences(freshGeofencesRef.current);
  };

  const unsetAsBreachZone = async (fence: GridRowData, bufferId: string): Promise<void> => {
    if (!layerRef.current)
      throw new Error('UI should not be able to add zones while no layer selected');
    if (!mapRef.current) return;

    const lyr = layerRef.current;
    const feature = await findFeature(fence.id, lyr.source.get('id'), FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const relatedFeature = await findFeature(
      bufferId,
      lyr.source.get('id'),
      FenceGeometryType.Polygon,
    );
    if (relatedFeature) {
      uiDeleteFence(relatedFeature, FenceGeometryType.Polygon);
    }
    feature.setProperties({
      zone: FenceZone.none,
      parentId: undefined,
      updated: true,
    });

    const breachIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (breachIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...breachIsFresh, zone: FenceZone.none },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0 && relatedFeature) {
        const relatedFeatureIndex = availableGeofences.findIndex(
          f => f.id === relatedFeature.get('id'),
        );
        if (relatedFeatureIndex > -1) {
          freshGeofencesRef.current = freshGeofencesRef.current.filter(
            f => f.id !== relatedFeature?.get('id'),
          );
          setFreshGeofences(freshGeofencesRef.current);
          availableGeofences.splice(relatedFeatureIndex, 1);
        }
        const breachIndex = availableGeofences.findIndex(f => f.id === feature.get('id'));
        if (breachIndex > -1) {
          availableGeofences[breachIndex].zone = FenceZone.none;
          availableGeofences[breachIndex].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
  };

  const setZone = async (fence: GridRowData, zone: FenceZone): Promise<void> => {
    if (!layerRef.current)
      throw new Error('UI should not be able to add zones while no layer selected');
    if (!mapRef.current) return;

    const lyr = layerRef.current;
    const feature = await findFeature(fence.id, lyr.source.get('id'), FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');
    selectedFenceRef.current = feature;

    feature.setProperties({
      ...feature.getProperties(),
      zone: zone,
      updated: true,
    });

    const zoneIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (zoneIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...zoneIsFresh, zone: zone },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const index = availableGeofences.findIndex(
          f => f.id === selectedFenceRef.current?.getProperties().id,
        );
        if (index > -1) {
          availableGeofences[index].zone = zone;
          availableGeofences[index].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }

    setSelectedGeofence(feature.getProperties() as GridRowData);
    setFreshGeofences(freshGeofencesRef.current);
  };

  const unsetZone = async (fence: GridRowData): Promise<void> => {
    if (!layerRef.current)
      throw new Error('UI should not be able to add zones while no layer selected');
    if (!mapRef.current) return;

    const lyr = layerRef.current;
    const feature = await findFeature(fence.id, lyr.source.get('id'), FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    feature.setProperties({
      zone: FenceZone.none,
      parentId: undefined,
      updated: true,
    });

    const clearedIsFresh = freshGeofencesRef.current.find(f => f.id === feature.get('id'));
    if (clearedIsFresh) {
      freshGeofencesRef.current = [
        ...freshGeofencesRef.current.filter(f => f.id !== feature.get('id')),
        { ...clearedIsFresh, zone: FenceZone.none },
      ];
    }

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const clearedIndex = availableGeofences.findIndex(f => f.id === feature.get('id'));
        if (clearedIndex > -1) {
          availableGeofences[clearedIndex].zone = FenceZone.none;
          availableGeofences[clearedIndex].parentId = undefined;
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
    setFreshGeofences(freshGeofencesRef.current);
  };

  const removeBufferZone = async (fence: GridRowData): Promise<void> => {
    if (!layerRef.current)
      throw new Error('UI should not be able to add zones while no layer selected');
    if (!mapRef.current) return;

    const lyr = layerRef.current;
    const feature = await findFeature(fence.id, lyr.source.get('id'), FenceGeometryType.Polygon);
    if (!feature)
      throw new Error('UI should not be able to add zones to a feature not in the selected layer');

    selectedFenceRef.current = feature;
    const relatedFeature = feature.get('parentId')
      ? lyr.source.getFeatures().find(f => f.get('id') === feature.get('parentId'))
      : undefined;
    relatedFeature?.setProperties({
      zone: FenceZone.none,
      updated: true,
    });

    uiDeleteFence(feature, FenceGeometryType.Polygon);

    if (selectedLayer?.id !== MICROFENCE_LAYER_ID) {
      if (availableGeofences.length > 0) {
        const relatedFeatureIndex = availableGeofences.findIndex(
          f => f.id === relatedFeature?.get('id') || f.id === feature.get('parentId'),
        );
        if (relatedFeatureIndex > -1) {
          availableGeofences[relatedFeatureIndex].zone = FenceZone.none;
          availableGeofences[relatedFeatureIndex].parentId = undefined;
        }

        const bufferIndex = availableGeofences.findIndex(f => f.id === fence.id);
        if (bufferIndex > -1) {
          availableGeofences.splice(bufferIndex, 1);
        }
      }
      setAvailableGeofences(availableGeofences);
    }
    setSelectedGeofence(feature.getProperties() as GridRowData);
    setZoneChange(new Date());
  };

  const updateGeomobyOverrides = async (
    geomobyOverrides: GeomobyOverride[],
  ): Promise<GeomobyOverride[] | undefined> => {
    if (!layerRef.current)
      throw new Error('UI should not be able to add geomobyOverrides while no layer is selected');
    if (!mapRef.current) return;
    if (!selectedGeofence)
      throw new Error('UI should not be able to add geomobyOverrides while no feature is selected');

    const lyr = layerRef.current;
    const feature = lyr.source.getFeatures().find(f => f.get('id') === selectedGeofence?.id);
    let foundFeature;
    if (!feature) {
      const fenceType = geometryTypeOfEntity(selectedGeofence);
      foundFeature = await findFeature(selectedGeofence?.id, selectedGeofence?.layerId, fenceType);
      if (foundFeature) {
        foundFeature.setProperties({
          ...foundFeature.getProperties(),
          geomobyOverrides,
          updated: true,
        });
      }
    }

    if (!feature && !foundFeature)
      throw new Error(
        'UI should not be able to add geomobyOverrides to a feature not in the selected layer',
      );

    if (feature) {
      feature.setProperties({
        geomobyOverrides,
        updated: true,
      });
    }

    setLayersHaveChanged(true);
    return geomobyOverrides;
  };

  const updateGeomobyProperties = async (
    geomobyProperties: Record<string, string>,
  ): Promise<Record<string, string> | undefined> => {
    if (!layerRef.current)
      throw new Error('UI should not be able to add geomobyProperties while no layer is selected');
    if (!mapRef.current) return;
    if (!selectedGeofence && !selectedMicrofence)
      throw new Error(
        'UI should not be able to add geomobyProperties while no feature is selected',
      );

    const lyr = layerRef.current;
    const feature = lyr.source
      .getFeatures()
      .find(f => f.get('id') === (selectedGeofence?.id ?? selectedMicrofence?.id));
    let foundFeature;
    if (!feature) {
      const fenceType = selectedGeofence ? geometryTypeOfEntity(selectedGeofence) : undefined;
      foundFeature = await findFeature(selectedGeofence?.id, selectedGeofence?.layerId, fenceType);
      if (foundFeature) {
        foundFeature.setProperties({
          ...foundFeature.getProperties(),
          geomobyProperties,
          updated: true,
        });
      }
    }

    if (!feature && !foundFeature)
      throw new Error(
        'UI should not be able to add geomobyProperties to a feature not in the selected layer',
      );

    if (feature) {
      feature.setProperties({
        geomobyProperties,
        updated: true,
      });
    }

    setLayersHaveChanged(true);
    return geomobyProperties;
  };

  const getNewGeofenceNumber = (newFences: GridRowData[]): number => {
    const geofenceNumbers: number[] = [];
    [...availableGeofences, ...(newFences ?? [])].forEach(f => {
      const name: string = f.name ?? '';
      if (
        name?.toLowerCase().startsWith(EntityType.Geofence.toLowerCase()) &&
        name.length > EntityType.Geofence.length &&
        !isNaN(Number(name.substring(EntityType.Geofence.length)))
      ) {
        geofenceNumbers.push(Number(name.substring(EntityType.Geofence.length)));
      }
    });
    const total = Math.max(...geofenceNumbers, Number(paginatedCount), 0) + 1;
    setTotalFences(total);
    return total;
  };

  const getMicroFenceNumber = (): number => {
    const microfences = layerRef.current?.source.getFeatures();
    const microfenceNumbers: number[] = [];
    if (microfences) {
      microfences.forEach(m => {
        const name = m.get('name');
        if (
          name?.toLowerCase()?.startsWith(EntityType.Microfence.toLowerCase()) &&
          name.length > EntityType.Microfence.length &&
          !isNaN(Number(name.substring(EntityType.Microfence.length)))
        ) {
          microfenceNumbers.push(Number(name.substring(EntityType.Microfence.length)));
        }
      });
    }
    return Number(Math.max(...microfenceNumbers, microfenceNumbers.length ?? 0) + 1);
  };

  const moveUnknownFenceToExistingLayer = (layerId: string) => {
    const freshUnknownLayer = getLayerFromMap(UNKNOWN_LAYER);
    const existingLayer = getLayerFromMap(layerIds.find(lyr => lyr.id === layerId)?.name ?? '');
    if (freshUnknownLayer && existingLayer && mapRef.current) {
      const freshUnknownFence = freshUnknownLayer.getSource().getFeatures()?.[0];
      freshUnknownFence.set('layerId', existingLayer.getSource().get('id'));
      freshUnknownFence.set('layerName', existingLayer.getSource().get('name'));

      existingLayer.getSource().addFeature(freshUnknownFence);
      mapRef.current.removeLayer(freshUnknownLayer);
      setLayers(layers.filter(lyr => lyr.id !== UNKNOWN_LAYER));
    }
  };

  useEffect(() => {
    if (!specifiedCoordinates) return;

    const initialise = async () => {
      const { map: olmap, setSource: setMapSource } = createMap(
        MapType.OUTDOOR_EDIT_MAP,
        createOutdoorMapDefaults({
          sourceType: mapSourceType,
          edit: true,
          specifiedCoordinates: specifiedCoordinates ?? [initialLongitude, initialLatitude],
          mapApiKeys,
        }),
      );
      setSourceRef.current = setMapSource;

      setFencesLoading(true);
      const [vectorLayers, microfenceLayer] = await Promise.all([
        getVectorLayers(triggersUrl, authedRequestConfig, { cid, pid }, bounds),
        getMicrofencesVectorLayer(triggersUrl, authedRequestConfig, { cid, pid }, bounds),
      ]);

      setLayers([
        {
          id: MICROFENCE_LAYER_ID,
          name: MICROFENCE_LAYER_LABEL,
          source: (microfenceLayer.getSource() as Cluster).getSource(),
        },
        ...(vectorLayers?.map(vectorLayer => {
          return {
            id: vectorLayer.id,
            name: vectorLayer.name,
            source: vectorLayer.source.getSource(),
          };
        }) ?? []),
      ]);

      vectorLayers?.forEach(vectorLayer => {
        vectorLayer.source.setSource(vectorLayer.source.getSource());
        olmap.addLayer(vectorLayer.source);
      });
      olmap.addLayer(microfenceLayer);

      setFencesLoading(false);
      setMapIsLoading(false);
      mapRef.current = olmap;
      if (!mapRef.current || mapRef.current.hasListener('click')) return;
      setMapClickDeselection(mapRef.current);
      setMapMoveHandler(mapRef.current);
      setCurrentCenter(mapRef.current.getView().getCenter());

      mapRef.current.getView().on('change:resolution', () => {
        const zoom = mapRef.current?.getView().getZoom() || 0;
        const res = mapRef.current?.getView().getResolution() || 0;

        if (zoom > ZOOM_THRESHOLD - 1) {
          mapRef.current?.getView().setZoom(ZOOM_THRESHOLD - 1);
        }
        extentInDegreesRef.current =
          zoom <= initialZoomHeight
            ? initialExtentInDegrees
            : (res / Math.min(zoom, ZOOM_THRESHOLD - 1)) * 0.1;
        if (currentlyDisplayedTripwiresRef.current.length === 0) return;
        currentlyDisplayedTripwiresRef.current.forEach(f => {
          const extent = f.getGeometry()?.getExtent();
          if (extent && extent.length > 0) {
            const size = Math.abs(extent[0] - extent[2]) + Math.abs(extent[1] - extent[3]);
            f.setProperties({ numberOfArrows: size / res < 50 ? 2 : size / res > 500 ? 4 : 3 });
          }
        });
      });
    };

    const timerId: NodeJS.Timeout = setTimeout(() => {
      initialise();
    }, 100);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specifiedCoordinates]);

  useEffect(() => {
    if (!layers) return;
    if (!mapRef.current) return;
    if (editing) return;

    const loadMoreFences = async () => {
      setFencesLoading(true);
      const { latitude, longitude, extentInDegrees } = bounds;
      const [xMax, yMax] = transform(
        [longitude + extentInDegrees, latitude + extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const [xMin, yMin] = transform(
        [longitude - extentInDegrees, latitude - extentInDegrees / 2],
        'EPSG:4326',
        'EPSG:3857',
      );
      const extent: [number, number, number, number] = [xMax, yMax, xMin, yMin];
      // already got features for this extent
      if (knownExtents.current.some(ext => containsExtent(ext, extent))) {
        setFencesLoading(false);
        return;
      }

      getVectorLayers(triggersUrl, authedRequestConfig, { cid, pid }, bounds)
        .then(vectorLayers => {
          setFencesLoading(false);
          vectorLayers?.forEach(vectorLayer => {
            const existingLayer = getLayerFromMap(vectorLayer.name);
            const newFeatures = vectorLayer.source.getSource().getFeatures();
            let existingSource = existingLayer?.getSource();
            if (existingSource instanceof Cluster) {
              existingSource = existingSource.getSource() as VectorSource<Geometry>;
            }
            const existingFeatures = existingSource?.getFeatures() || [];
            const deletedFeatures: [string, boolean][] = deletedFenceIds.map(({ id }) => [
              id,
              true,
            ]);
            const keepIds = Object.fromEntries([
              ...existingFeatures.map(f => [`${f.get('id')}`, true]),
              ...deletedFeatures,
            ]);

            const keepFeatures = newFeatures.filter(f => !keepIds[f.get('id')]);
            currentlyDisplayedTripwiresRef.current = Array.from(
              new Set([
                ...currentlyDisplayedTripwiresRef.current,
                ...existingFeatures,
                ...keepFeatures,
              ]),
            ).filter(f => f.get('points')?.type === 'LineString' || f.get('type') === 'LineString');
            existingSource?.addFeatures(keepFeatures);
          });
        })
        .catch(error => {
          setFencesLoading(false);
          if (selectedGeofence) {
            const fenceType = geometryTypeOfEntity(selectedGeofence);
            findFeature(selectedGeofence?.id, selectedGeofence?.layerId, fenceType);
          }

          const errorMessage = normaliseErrorMessage(error as AxiosError, EntityType.Microfence);
          setSaveNotification({
            id: SaveResult.FAIL,
            action: '',
            message: errorMessage,
          });
        });
      knownExtents.current = [...knownExtents.current, extent];
    };
    loadMoreFences();
    if (
      selectedFenceRef.current &&
      selectedFenceRef.current.get('layerId') !== MICROFENCE_LAYER_ID &&
      geometryTypeOfEntity(selectedFenceRef.current.getProperties()) !== FenceGeometryType.Line
    ) {
      const geometry = selectedFenceRef.current.getGeometry();
      if (geometry && layerRef.current) {
        setHasFences(!!featureContainsFeature(geometry, layerRef.current.source));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  // Handle selected layer change
  useEffect(() => {
    const oldLayerFromMap = getLayerFromMap(layerRef.current?.name);
    const currentLayerFromMap = getLayerFromMap(undefined, selectedLayer?.id);
    if (!currentLayerFromMap) return;

    const oldLayer =
      oldLayerFromMap && layerRef.current
        ? { id: layerRef.current.id, name: layerRef.current.name, layer: oldLayerFromMap }
        : undefined;
    const currentLayer = selectedLayer
      ? { id: selectedLayer.id, name: selectedLayer.name, layer: currentLayerFromMap }
      : undefined;

    setSelectedLayerStyle(
      oldLayer,
      currentLayer,
      selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
    );

    layerRef.current = currentLayer
      ? {
          id: currentLayer.id,
          name: currentLayer.name,
          source:
            currentLayer.id === MICROFENCE_LAYER_ID
              ? (currentLayer.layer.getSource() as Cluster).getSource()
              : currentLayer.layer.getSource(),
        }
      : undefined;
    if (currentLayer?.name === UNKNOWN_LAYER) return;
    if (layerRef.current) {
      layerIds.forEach(layer =>
        changeVisibility(layer.id, layer.id === selectedLayer?.id || showGhostGeofencesRef.current),
      );
    } else {
      setAvailableGeofences([]);
      setSelectedLayer(undefined);
      setSelectedGeofence(undefined);
      setSelectedMicrofence(undefined);
      selectedFenceRef.current = undefined;
      deselectAllFences(mapRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer]);

  useEffect(() => {
    if (!layers) return;
    setLayerIds(
      layers?.map(({ id, name }) => {
        return {
          id,
          name: name ?? id,
        };
      }),
    );
  }, [layers]);

  useEffect(() => {
    // Ideally it would be better do this everytime fenceIds changes but we can't always capture that event.
    if (mapRef.current && editRef.current) {
      const e = editRef.current;
      const m = mapRef.current;
      m.removeInteraction(e.modify);
      m.removeInteraction(e.modifyScaleRot);
    }

    if (layerRef.current && drawType) {
      const modifiedInteration =
        drawType === DrawType.Tripwire
          ? GeofenceEntityType.Line
          : drawType === DrawType.Circle || drawType === DrawType.Polygon
          ? GeofenceEntityType.Polygon
          : drawType === DrawType.Multipolygon
          ? GeofenceEntityType.Multipolygon
          : undefined;
      if (modifiedInteration && layerRef.current) {
        addShapeChangeModifyInteration(
          (layerRef.current as { id: string; name: string; source: VectorSource<Geometry> })
            ?.source,
          modifiedInteration,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneChange]);

  useEffect(() => {
    if (!selectedGeofence || !selectedMicrofence) return;
    const geofenceExists = availableGeofences.find(fence => fence.id === selectedGeofence?.id);
    const microfenceExists = availableMicrofences.find(
      fence => fence.id === selectedMicrofence?.id,
    );
    if (!mapIsLoading) {
      if (!geofenceExists) {
        setSelectedGeofence(undefined);
      } else if (!microfenceExists) {
        setSelectedMicrofence(undefined);
      }
      if (!(geofenceExists && microfenceExists)) {
        selectedFenceRef.current = undefined;
      }
    }
  }, [
    availableGeofences,
    availableMicrofences,
    selectedGeofence,
    selectedMicrofence,
    mapIsLoading,
  ]);

  useEffect(() => {
    // Set the current layer style to highlight the focused fence
    if (!layerRef.current) return;
    getLayerFromMap(layerRef.current?.name)?.setStyle(
      selectedLayerStyle(styleCache, selectedGeofence?.id ?? selectedMicrofence?.id),
    );

    if (!selectedFenceRef.current || !selectedLayer) return;
    const fence =
      availableGeofences.find(f => f.id === selectedGeofence?.id) ??
      availableMicrofences.find(f => f.id === selectedMicrofence?.id);
    if (!fence) return;

    layerRef.current?.source
      .getFeatures()
      .filter(
        (f: Feature<Geometry>) =>
          f.get('selected') && f.get('id') !== (selectedGeofence?.id ?? selectedMicrofence?.id),
      )
      .forEach((f: Feature<Geometry>) => {
        f.set('selected', false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeofence, selectedMicrofence, selectedLayer]);

  useEffect(() => {
    if (selectedFromMap || !(selectedGeofence ?? selectedMicrofence)?.selected) return;
    // Update selectedFenceRef.current when selecting from sideBar
    const fenceVisibleOnScreen = (selectedFenceRef.current = layerRef.current?.source
      .getFeatures()
      .find(f => f.get('id') === (selectedGeofence ?? selectedMicrofence)?.id));
    if (fenceVisibleOnScreen) {
      fenceVisibleOnScreen.set('selected', true);
      selectedFenceRef.current = fenceVisibleOnScreen;
    }
  }, [selectedFromMap, selectedGeofence, selectedMicrofence]);

  useEffect(() => {
    if (!navigateTo) return;
    if (navigateTo === (selectedGeofence?.id ?? selectedMicrofence?.id)) {
      animateToFeature();
      setNavigateTo(null);
    }
  }, [selectedGeofence, selectedMicrofence, selectedLayer, navigateTo, animateToFeature]);

  useEffect(() => {
    changeDrawShape();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawType, editType]);

  useEffect(() => {
    changeMeasurementType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementType]);

  useEffect(() => {
    if (
      !mapIsLoading &&
      availableGeofences.length > 0 &&
      selectedFenceRef.current &&
      layerRef.current
    ) {
      const id = selectedFenceRef.current.get('id');
      if (
        !id?.includes(FRESH) &&
        availableGeofences.find(f => f.id === id) &&
        !deletedFenceIds.find(f => f.id === id)
      ) {
        setSelectedGeofence(selectedFenceRef.current?.getProperties() as GridRowData);
      }
    }
    // Creating a fence while there is no list to push it to will cause problems.
    if (!mapRef.current) return;
    const m = mapRef.current;
    m.getInteractions().forEach(i => {
      if (i instanceof Draw || i instanceof Modify) {
        i.setActive(!mapIsLoading);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapIsLoading]);

  useEffect(() => {
    if (!mapRef.current) return;
    const olmap = mapRef.current;
    if (!dropLocationPin) {
      olmap?.setLayers(
        olmap?.getAllLayers().filter(layer => layer?.getClassName() !== 'pin-layer'),
      );
    } else {
      const pinLayer = olmap?.getAllLayers().find(layer => layer?.getClassName() === 'pin-layer');
      if (pinLayer) return;
      olmap?.addLayer(
        new VectorLayer({
          className: 'pin-layer',
          source: new VectorSource({
            features: [dropLocationPin],
          }),
          style: [
            new Style({
              image: new CircleStyle({
                radius: 14,
                fill: new Fill({
                  color: 'rgb(198,87,250, 0.5)',
                }),
              }),
            }),
            new Style({
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: 'rgb(184,0,255)',
                }),
              }),
            }),
          ],
        }),
      );
    }
  }, [dropLocationPin]);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!locationSearchData) {
      setDropLocationPin(undefined);
      return;
    }

    const { coords, address, isStreetAddress } = locationSearchData;
    const olmap = mapRef.current;
    animateToSearchedLocation(olmap.getView(), coords, address, isStreetAddress).then(pin => {
      setDropLocationPin(pin);
    });
  }, [locationSearchData, animateToSearchedLocation]);

  useEffect(() => {
    showGhostGeofencesRef.current = showGhostGeofences;
  }, [showGhostGeofences]);

  return (
    <>
      <SidebarAndMap
        sidebar={
          <>
            <SearchList
              layerIds={layerIds?.sort((a, b) => a.name.localeCompare(b.name))}
              setLayerIds={setLayerIds}
              layers={layers}
              setLayers={setLayers}
              availableGeofences={availableGeofences}
              setAvailableGeofences={setAvailableGeofences}
              freshGeofences={freshGeofences}
              setFreshGeofences={setFreshGeofences}
              layersFromMap={
                mapRef.current?.getAllLayers().filter(l => l instanceof VectorLayer) as VectorLayer<
                  VectorSource<Geometry>
                >[]
              }
              availableMicrofences={availableMicrofences}
              setAvailableMicrofences={setAvailableMicrofences}
              microfences={
                (
                  (
                    mapRef.current
                      ?.getAllLayers()
                      .find(lyr => lyr.get('id') === MICROFENCE_LAYER_ID) as AnimatedCluster
                  )?.getSource() as Cluster
                )
                  ?.getSource()
                  ?.getFeatures() ?? []
              }
              selectedGeofence={selectedGeofence}
              setSelectedGeofence={setSelectedGeofence}
              selectedMicrofence={selectedMicrofence}
              setSelectedMicrofence={setSelectedMicrofence}
              count={paginatedCount}
              setCount={setPaginatedCount}
              setExtent={setUserExtent}
              searchType={searchType}
              setSearchType={setSearchType}
              geofenceFilter={geofenceFilter}
              setGeofenceFilter={setGeofenceFilter}
              microfenceFilter={microfenceFilter}
              setMicrofenceFilter={setMicrofenceFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              showGhostGeofences={showGhostGeofences}
              setShowGhostGeofences={setShowGhostGeofences}
              createEditLayer={createEditLayer}
              setCreateEditLayer={setCreateEditLayer}
              refreshSearch={refreshSearch}
              setRefreshSearch={setRefreshSearch}
              setLocationSearchData={setLocationSearchData}
              currentCenter={currentCenter}
              locationDisplay={locationDisplay}
              setLocationDisplay={setLocationDisplay}
              setDrawType={setDrawType}
              layersHaveChanged={layersHaveChanged}
              setLayersHaveChanged={setLayersHaveChanged}
              selectedLayer={selectedLayer}
              setSelectedLayer={setSelectedLayer}
              setRenamingLayer={setRenamingLayer}
              reassignedFences={reassignedFences}
              setReassignedFences={setReassignedFences}
              setDirtySave={setDirtySave}
              dirtySave={dirtySave}
              openGenericDialog={openGenericDialog}
              setOpenGenericDialog={setOpenGenericDialog}
              mapIsLoading={mapIsLoading}
              isLoading={paginating}
              setIsLoading={setPaginating}
              createEditFence={createEditFence}
              setCreateEditFence={setCreateEditFence}
              setNavigateTo={setNavigateTo}
              selectedFromMap={selectedFromMap}
              setSelectedFromMap={setSelectedFromMap}
              hasFences={hasFences}
              deselectFence={deselectFence}
              setDeselectFence={setDeselectFence}
              displayGeomobyOverrides={displayGeomobyOverrides}
              setDisplayGeomobyOverrides={setDisplayGeomobyOverrides}
              displayGeomobyProperties={displayGeomobyProperties}
              setDisplayGeomobyProperties={setDisplayGeomobyProperties}
              createNewLayer={createNewLayer}
              deleteLayer={deleteLayer}
              deleteFence={uiDeleteFence}
              changeVisibility={changeVisibility}
              editing={editing}
              unsetEditing={uiUnsetEditing}
              deselectAllFences={() => deselectAllFences(mapRef.current)}
              moveUnknownFenceToExistingLayer={moveUnknownFenceToExistingLayer}
              resetLayerChanges={uiResetLayerChanges}
              saveLayerChanges={saveLayerChanges}
              updateFenceIdentifiers={uiUpdateFenceIdentifiers}
              updateGeomobyProperties={updateGeomobyProperties}
              updateGeomobyOverrides={updateGeomobyOverrides}
              setAsBufferZone={setAsBufferZone}
              removeBufferZone={removeBufferZone}
              setAsBreachZone={setAsBreachZone}
              unsetAsBreachZone={unsetAsBreachZone}
              setZone={setZone}
              unsetZone={unsetZone}
            />

            <FilterComponent
              searchType={searchType}
              geofenceFilter={geofenceFilter}
              setGeofenceFilter={setGeofenceFilter}
              microfenceFilter={microfenceFilter}
              setMicrofenceFilter={setMicrofenceFilter}
              selectedMicrofence={selectedMicrofence}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setRefreshSearch={setRefreshSearch}
            />
          </>
        }
        map={
          <MapContainer id={MapType.OUTDOOR_EDIT_MAP}>
            <ToolPanel
              mapType={'OUTDOOR'}
              selectedLayer={
                !selectedLayer && searchType?.id === SearchTypeIDs.Geofences
                  ? ALL_LAYERS
                  : createEditLayer
                  ? undefined
                  : selectedLayer?.id
              }
              geofenceType={selectedGeofence ? geometryTypeOfEntity(selectedGeofence) : undefined}
              geofenceTooBig={
                selectedGeofence && selectedFenceRef.current
                  ? selectedFenceRef.current.get('points')?.coordinates?.length >
                    MAX_NUMBER_OF_POLYGONS
                  : false
              }
              editing={editing}
              isLoading={mapIsLoading || paginating}
              setEditing={uiSetEditing}
              unsetEditing={uiUnsetEditing}
              drawType={drawType}
              setDrawType={setDrawType}
              editType={editType}
              setEditType={setEditType}
              measurementType={measurementType}
              setMeasurementType={setMeasurementType}
            />

            <MapToolbar>
              {fencesLoading && <LoadIndicator what="geofences" />}
              <ChangeMapSourceType
                current={mapSourceType}
                setType={value => {
                  setMapSourceType(value);
                  setSourceRef.current && setSourceRef.current(value);
                }}
              />

              <ZoomIn
                onClick={() => {
                  if (mapRef.current) {
                    mapRef.current.getView().adjustZoom(0.5);
                  }
                }}
              />
              <ZoomOut
                onClick={() => {
                  if (mapRef.current) {
                    mapRef.current.getView().adjustZoom(-0.5);
                  }
                }}
              />
            </MapToolbar>
          </MapContainer>
        }
      />

      <Dialog open={!!errorCode} onClose={() => serErrorCode(undefined)}>
        <DialogTitle>{`The geofence you are attempting to modify is too large, please contact GeoMoby Support.`}</DialogTitle>
        <DialogActions>
          <Button onClick={() => serErrorCode(undefined)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MapRenderer;
