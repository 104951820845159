import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Build, Download } from '@mui/icons-material';
import { Header } from '../../Common/Sidebar';
import { FileInput } from '../../util/FileInput/FileInput';
import ToolsList from './ToolsList';
import { PortableAssetTool, ToolType } from '../Map/types';
import InputContainer from '../Global/InputContainer';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { METADATA_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { useMobile } from '../../util/useMobile';
import ToolTypeList from './ToolTypeList';
import { SaveNotification, SaveResult, SAVE_NOTIFICATION } from '../../store/notifications';
import { useGeomobyLiveStream } from '../../hooks/geomoby/useGeomobyLiveStream';
import { SensedAssetsReport } from '../Map/Messages';
import { KNOWN_TOOLS } from '../../store/tools';
import { groupBy } from 'lodash';
import { SearchTypeIDs } from '../../util/enums';
import { BACKGROUND } from '../../Style/GeoMobyBaseTheme';

export type BulkTool = { label?: string; tool?: string };
type Tool = {
  tool: string;
  id: string;
  label: string | null;
  type: 'tool';
};
type TabType = 'TOOLS' | 'TOOL_TYPES';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function getDuplicateLines(duplicates: (Tool & { index: number })[]): string {
  if (!duplicates.length) return '';
  const linesNumbers = duplicates.map(dup => dup.index + 2).sort((a, b) => a - b);

  if (linesNumbers.length === 1) return linesNumbers[0].toString();

  return linesNumbers.slice(0, -1).join(', ') + ' and ' + linesNumbers[linesNumbers.length - 1];
}

const Tools = () => {
  const COMMA_REPLACEMENT_TOKEN = '###COMMA###';
  const metadataUrl = useAtomValue(METADATA_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const knownTools = useAtomValue(KNOWN_TOOLS);
  const setSaveNotification = useSetAtom(SAVE_NOTIFICATION);
  const isMobile = useMobile();
  const liveStreamData = useGeomobyLiveStream({ useFor: 'livemap' });
  const liveStreamState = liveStreamData.state;

  const [bulkList, setBulkList] = useState<PortableAssetTool[]>([]);
  const [bulkUpdatedTool, setBulkUpdatedTool] = useState<BulkTool | undefined>();
  const [csvFile, setCsvFile] = useState<File>();
  const [editingType, setEditingType] = useState<'SINGLE' | 'BULK' | undefined>();
  const [editingTooltype, setEditingTooltype] = useState<ToolType | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newTooltype, setNewTooltype] = useState<string | undefined>();
  const [page, setPage] = useState<number>(1);
  const [openToolsFilterDialog, setOpenToolsFilterDialog] = useState<boolean>(false);
  const [openToolTypeFilterDialog, setOpenToolTypeFilterDialog] = useState<boolean>(false);
  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [duplicateTools, setDuplicateTools] = useState<(Tool & { index: number })[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [refreshToolTypes, setRefreshToolTypes] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<TabType>(SearchTypeIDs.Tools);
  const [toolsCount, setToolsCount] = useState<number>(0);
  const [toolTypes, setToolTypes] = useState<ToolType[]>([]);
  const [tools, setTools] = useState<PortableAssetTool[]>([]);
  const [singleUpdatedTool, setSingleUpdatedTool] = useState<PortableAssetTool | undefined>();

  const pageRef = useRef<number>(1);

  const CSV_HEADER = '[id],[label],[tool_type]';

  const getToolTypes = useCallback(async () => {
    setToolTypes(
      (
        await axios.get<ToolType[]>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/all`,
          authedConfig,
        )
      ).data?.sort((a, b) => a.tool.toLowerCase().localeCompare(b.tool.toLowerCase())),
    );
  }, [cid, pid, metadataUrl, authedConfig, setToolTypes]);

  const getStreamedTools = useCallback(
    () =>
      [
        ...(liveStreamState.assets
          .filter(asset => asset.assetState.lastLocation?.id?.beaconId)
          .map(asset => asset.assetState.lastLocation)
          .flatMap(beacon => knownTools.find(t => t.id === beacon?.id.beaconId)) ?? []),
        ...(
          liveStreamState.assets
            .filter(asset => asset.assetState.lastSensed)
            .map(asset => asset.assetState.lastSensed)
            .flatMap(asset => asset?.assets) ?? []
        ).map(beacon => knownTools.find(t => t.id === beacon?.beaconId)),
      ] as PortableAssetTool[],
    [liveStreamState, knownTools],
  );

  const streamedTools = useMemo(() => getStreamedTools(), [getStreamedTools]);

  const changeTab = (event: React.SyntheticEvent, newTab: TabType) => {
    setSelectedTab(newTab);
  };

  const uploadBulkTools = async () => {
    if (!csvFile) return;
    setTools([]);
    setPage(1);
    const fileReader = new FileReader();
    fileReader.readAsText(csvFile, 'utf-8');
    fileReader.onload = async e => {
      const contents = String((e as ProgressEvent<FileReader>).target?.result);
      const lines = Array.from(
        contents
          .trim()
          .replace(/[\r|\n]+/g, '\n')
          .split('\n')
          .map(line => line.trim()),
      );
      if (lines.length > 0 && lines[0] !== CSV_HEADER) {
        setRefresh(true);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: 'Save',
          message: 'Uploaded file not in the correct format. Refer to CSV template',
        });
        return;
      } else if (lines.length <= 1) {
        setRefresh(true);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: 'Save',
          message: 'Uploaded file is empty',
        });
        return;
      }

      let lineError: string | undefined = undefined;

      const newTools = lines
        .slice(1)
        .map((line, index) => {
          if (lineError) return;
          const lineContent = line
            .replace(/"(.*?)"/g, str => str.replaceAll(',', COMMA_REPLACEMENT_TOKEN))
            .split(',')
            .map(cell => {
              const content = cell.trim().replaceAll(COMMA_REPLACEMENT_TOKEN, ',');
              if (content.startsWith('"') && content.endsWith('"')) {
                return content.slice(1, -1).trim();
              }
              return content.trim();
            });

          const newTool: Tool = {
            id: lineContent[0],
            label: lineContent[1] || null,
            tool: lineContent[2],
            type: 'tool',
          };
          if (!newTool.id || !newTool.tool) {
            lineError = `Uploaded file missing mandatory values on line ${
              index + 2
            }. Refer to CSV template`;
            return;
          }
          return newTool;
        })
        .filter((t): t is Tool => !!t);
      if (lineError) {
        setRefresh(true);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: 'Save',
          message: lineError,
        });
        return;
      }
      const duplicates: (Tool & { index: number })[] = Object.values(
        groupBy(
          newTools.map((tool, index) => ({ ...tool, index })),
          t => t.id,
        ),
      )
        .map(toolsById => toolsById.slice(1))
        .flatMap(t => t);

      if (duplicates.length) {
        setRefresh(true);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: 'Save',
          message: 'Ids must be unique',
        });
        setDuplicateTools(duplicates);
        return;
      }

      if (newTools.length === 0) return;
      try {
        await axios.post<Tool>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/bulk`,
          newTools,
          authedConfig,
        );
        setRefresh(true);
        setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
      } catch (error) {
        const responseData = ((error as AxiosError).response as AxiosResponse).data;
        setRefresh(true);
        setSaveNotification({
          id: SaveResult.FAIL,
          action: 'Save',
          message: responseData.message.message,
        });
      }
    };
  };

  const updateTool = async () => {
    const updatedTool = {
      ...singleUpdatedTool,
      label: !singleUpdatedTool?.label ? null : singleUpdatedTool?.label,
      type: 'tool',
    };
    try {
      setTools([]);
      setPage(1);
      setBulkList([]);
      await axios.patch<Tool>(
        `${metadataUrl}/${cid}/${pid}/portableasset/${singleUpdatedTool?.uuid}`,
        updatedTool,
        authedConfig,
      );
      setRefresh(true);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const updateBulkTools = async () => {
    try {
      setTools([]);
      setPage(1);
      const updatedTools = bulkList.map(tool => {
        return {
          ...tool,
          label: bulkUpdatedTool?.label ?? tool.label,
          tool: bulkUpdatedTool?.tool ?? tool.tool,
          type: 'tool',
        };
      });

      setBulkList([]);
      Promise.all(
        updatedTools.map(async toUpdate => {
          await axios.patch<Tool>(
            `${metadataUrl}/${cid}/${pid}/portableasset/${toUpdate.uuid}`,
            toUpdate,
            authedConfig,
          );
        }),
      ).then(() => {
        setRefresh(true);
      });
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const createToolType = async () => {
    try {
      const createdToolType = (
        await axios.post<ToolType>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool`,
          { tool: newTooltype },
          authedConfig,
        )
      ).data;
      setToolTypes(
        [...toolTypes, createdToolType].sort((a, b) =>
          a.tool.toLowerCase().localeCompare(b.tool.toLowerCase()),
        ),
      );
      setNewTooltype(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const updateToolType = async () => {
    try {
      const updatedToolType = (
        await axios.patch<ToolType>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/${editingTooltype?.id}`,
          { tool: editingTooltype?.tool },
          authedConfig,
        )
      ).data;
      setToolTypes(
        [...toolTypes.filter(t => t.id !== updatedToolType.id), updatedToolType].sort((a, b) =>
          a.tool.toLowerCase().localeCompare(b.tool.toLowerCase()),
        ),
      );
      setEditingTooltype(undefined);
      setSaveNotification({ id: SaveResult.SUCCESS, action: 'Save' });
    } catch (error) {
      setSaveNotification({
        id: SaveResult.FAIL,
        action: 'Save',
        message: ((error as AxiosError).response as AxiosResponse).data.message,
      });
    }
  };

  const toCsvRow = (...cells: string[]) =>
    cells.map(cell => (cell.includes(',') ? `"${cell}"` : cell)).join(',');

  const downloadExistingToolsCSV = async () => {
    const csvContent = (
      await axios.get<PortableAssetTool[]>(
        `${metadataUrl}/${cid}/${pid}/portableasset`,
        authedConfig,
      )
    ).data
      .filter(asset => asset.type === 'tool' && !!asset.tool)
      .sort((a, b) => a.id.localeCompare(b.id))
      .map(t => toCsvRow(t.id, t.label ?? '', t.tool))
      .join('\n');

    const dataUri = window.URL.createObjectURL(
      new Blob([`${CSV_HEADER}\n${csvContent}`], { type: 'text/csv' }),
    );

    window.open(dataUri);
  };

  const downloadTemplateCSV = () => {
    const link = document.createElement('a');
    link.download = `Tool_Example.csv`;
    link.href = './Tool_Example.csv';
    link.click();
  };

  useEffect(() => {
    if (openToolsFilterDialog || editingType || selectedTab === 'TOOL_TYPES' || refreshToolTypes) {
      setRefreshToolTypes(false);
      getToolTypes();
    }
  }, [openToolsFilterDialog, selectedTab, editingType, getToolTypes, refreshToolTypes]);

  return (
    <>
      <Box
        sx={
          isMobile
            ? {
                width: '100%',
              }
            : {
                flexGrow: 1,
                bgcolor: 'background.paper',
                display: 'flex',
                position: 'absolute',
                '& .MuiButtonBase-root': {
                  textAlign: 'left',
                },
              }
        }
      >
        <Tabs
          orientation={isMobile ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={selectedTab}
          onChange={changeTab}
          sx={
            isMobile
              ? {
                  borderBottom: 1,
                  borderColor: 'divider',
                  background: BACKGROUND,
                  width: '20rem',
                }
              : {
                  borderRight: 1,
                  borderColor: 'divider',
                  background: BACKGROUND,
                  height: '19rem',
                }
          }
        >
          <Tab label="Tools" value={SearchTypeIDs.Tools} />
          <Tab label="Tool Types" value={'TOOL_TYPES'} />
        </Tabs>
      </Box>

      {/* Create Tools */}
      {!editingType && selectedTab === SearchTypeIDs.Tools && (
        <Container
          style={{
            paddingBottom: '45px',
          }}
        >
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px',
              paddingBottom: '10px',
            }}
          >
            <Header icon={<Build />}>Upload new tools</Header>
            <Typography>Bulk upload via CSV file</Typography>
            <FileInput id="csv" onFileSet={f => setCsvFile(f)} allowTypes={['.csv']} />
            <Grid
              container
              style={{
                display: 'grid',
                gridTemplateColumns: '195px 155px',
                width: '350px',
              }}
            >
              <Button onClick={downloadExistingToolsCSV} startIcon={<Download />}>
                Existing tools CSV
              </Button>
              <Button onClick={downloadTemplateCSV} startIcon={<Download />}>
                Template CSV
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              width: 'calc(100% - 22px)',
              paddingTop: '15px',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={!csvFile}
              style={{
                width: '25vw',
                maxWidth: '500px',
                minWidth: '255px',
              }}
              size="large"
              onClick={() => {
                uploadBulkTools();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Container>
      )}

      {/* Update Tools */}
      {!!editingType && selectedTab === SearchTypeIDs.Tools && (
        <Container
          style={{
            paddingBottom: '45px',
          }}
        >
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px',
              paddingBottom: '10px',
              marginLeft: '0px',
            }}
          >
            <Header icon={<Build />}>
              {editingType === 'BULK' ? 'Bulk edit tools' : 'Edit tool'}
            </Header>

            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{
                alignContent: 'start',
              }}
            >
              {/* ID */}
              {editingType === 'SINGLE' && singleUpdatedTool && (
                <Paper
                  variant="outlined"
                  style={{
                    width: 'calc(100% - 10vw)',
                    background: BACKGROUND,
                    marginTop: '20px',
                  }}
                >
                  <Box padding={2}>
                    <FormControl fullWidth>
                      <InputContainer
                        id="toolId"
                        label="ID"
                        key={'toolId'}
                        name={'toolId'}
                        value={singleUpdatedTool?.id}
                        onChange={(e: { target: { value: string } }) => {
                          if (singleUpdatedTool && editingType === 'SINGLE') {
                            setSingleUpdatedTool({
                              ...singleUpdatedTool,
                              id: e.target.value,
                            });
                          }
                        }}
                        placeholder="Tool-111"
                      />
                    </FormControl>
                  </Box>
                </Paper>
              )}

              {/* Label/Serial */}
              <Paper
                variant="outlined"
                style={{
                  width: 'calc(100% - 10vw)',
                  background: BACKGROUND,
                  marginTop: '20px',
                  height: 'fit-content',
                }}
              >
                <Box padding={2}>
                  <FormControl fullWidth>
                    <InputContainer
                      id="toolId"
                      label="Label/Serial"
                      key={'toolLabel'}
                      name={'toolLabel'}
                      disableInput={bulkList.length === 0}
                      value={
                        editingType === 'SINGLE'
                          ? singleUpdatedTool?.label ?? ''
                          : editingType === 'BULK'
                          ? bulkUpdatedTool?.label ?? ''
                          : ''
                      }
                      onChange={(e: { target: { value: string } }) => {
                        if (singleUpdatedTool && editingType === 'SINGLE') {
                          setSingleUpdatedTool({
                            ...singleUpdatedTool,
                            label: e.target.value,
                          });
                        }
                        if (editingType === 'BULK') {
                          setBulkUpdatedTool({
                            ...(bulkUpdatedTool ?? {}),
                            label: e.target.value,
                          });
                        }
                      }}
                      placeholder="Tool1"
                    />
                  </FormControl>
                </Box>
              </Paper>

              {/* Tool Type */}
              <Paper
                variant="outlined"
                style={{
                  width: 'calc(100% - 10vw)',
                  marginTop: 20,
                  marginBottom: 20,
                  alignSelf: 'center',
                  background: BACKGROUND,
                }}
              >
                <Box padding={2}>
                  <FormControl fullWidth>
                    <InputLabel id="tool-type-option">Tool Type</InputLabel>
                    <Select
                      fullWidth
                      labelId="tool-type-action"
                      id="tool-type-dropdown"
                      disabled={bulkList.length === 0}
                      value={
                        editingType === 'SINGLE'
                          ? singleUpdatedTool?.tool ?? ''
                          : editingType === 'BULK'
                          ? bulkUpdatedTool?.tool ?? ''
                          : ''
                      }
                      label="Tool Type"
                      onChange={(e: { target: { value: string } }) => {
                        if (singleUpdatedTool && editingType === 'SINGLE') {
                          setSingleUpdatedTool({
                            ...singleUpdatedTool,
                            tool: e.target.value,
                          });
                        }
                        if (editingType === 'BULK') {
                          setBulkUpdatedTool({
                            ...(bulkUpdatedTool ?? {}),
                            tool: e.target.value,
                          });
                        }
                      }}
                    >
                      {toolTypes.map(toolType => (
                        <MenuItem
                          key={toolType.id}
                          value={toolType.tool}
                          disabled={bulkList.length === 0}
                        >
                          <Tooltip title={toolType.tool}>
                            <Typography
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 50px)',
                              }}
                            >
                              {toolType.tool}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Paper>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                width: 'calc(100% - 10vw)',
                marginTop: '20px',
                display: 'grid',
                gap: '2%',
                gridTemplateColumns: '49% 49%',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={bulkList.length === 0}
                onClick={() => {
                  setOpenSaveDialog(true);
                }}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  setEditingType(undefined);
                  setSingleUpdatedTool(undefined);
                  setBulkUpdatedTool(undefined);
                  setBulkList([]);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}

      {/* Create Tool Type */}
      {selectedTab === 'TOOL_TYPES' && (
        <Container
          style={{
            paddingBottom: '45px',
          }}
        >
          <Grid
            spacing={3}
            paddingY={2}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              padding: '60px',
              paddingBottom: '10px',
              marginLeft: '0px',
            }}
          >
            <Header icon={<Build />}>Create new tool type</Header>

            {/*Label*/}
            <Paper
              variant="outlined"
              style={{
                width: 'calc(100% - 10vw)',
                background: BACKGROUND,
                marginTop: '20px',
              }}
            >
              <Box padding={2}>
                <InputContainer
                  id="toolTypeId"
                  label="Label"
                  key={'toolTypeLabel'}
                  name={'toolTypeLabel'}
                  value={editingTooltype ? editingTooltype.tool : newTooltype ?? ''}
                  placeholder="Drill"
                  onChange={(e: { target: { value: string } }) => {
                    if (editingTooltype) {
                      setEditingTooltype({
                        ...editingTooltype,
                        tool: e.target.value,
                      });
                    } else {
                      setNewTooltype(e.target.value);
                    }
                  }}
                />
              </Box>
            </Paper>

            <Grid
              container
              direction="column"
              style={
                editingTooltype
                  ? {
                      width: 'calc(100% - 10vw)',
                      marginTop: '20px',
                      display: 'grid',
                      gap: '2%',
                      gridTemplateColumns: '49% 49%',
                    }
                  : {
                      width: 'calc(100% - 10vw)',
                      marginTop: '20px',
                    }
              }
            >
              <Button
                variant="contained"
                color="secondary"
                style={
                  editingTooltype
                    ? {}
                    : {
                        width: '25vw',
                        maxWidth: '500px',
                        minWidth: '255px',
                        alignSelf: 'center',
                      }
                }
                size="large"
                onClick={() => {
                  if (editingTooltype) {
                    updateToolType();
                  } else {
                    createToolType();
                  }
                }}
              >
                {editingTooltype ? 'Update' : 'Create'}
              </Button>
              {editingTooltype && (
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setEditingTooltype(undefined);
                    setNewTooltype(undefined);
                  }}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {selectedTab === SearchTypeIDs.Tools && (
        <ToolsList
          refresh={refresh}
          setRefresh={setRefresh}
          pageRef={pageRef}
          page={page}
          setPage={setPage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          openToolsFilterDialog={openToolsFilterDialog}
          setOpenToolsFilterDialog={setOpenToolsFilterDialog}
          availableTools={tools}
          setAvailableTools={setTools}
          toolsCount={toolsCount}
          setToolsCount={setToolsCount}
          toolTypes={toolTypes}
          editingType={editingType}
          setEditingType={setEditingType}
          bulkList={bulkList}
          setBulkList={setBulkList}
          singleUpdatedTool={singleUpdatedTool}
          setSingleUpdatedTool={setSingleUpdatedTool}
          setBulkUpdatedTool={setBulkUpdatedTool}
          activeTools={streamedTools}
        />
      )}

      {selectedTab === 'TOOL_TYPES' && (
        <ToolTypeList
          openToolTypeFilterDialog={openToolTypeFilterDialog}
          setOpenToolTypeFilterDialog={setOpenToolTypeFilterDialog}
          toolTypes={toolTypes}
          setToolTypes={setToolTypes}
          editingTooltype={editingTooltype}
          setEditingTooltype={setEditingTooltype}
          setNewTooltype={setNewTooltype}
          setRefreshToolTypes={setRefreshToolTypes}
        />
      )}

      <Dialog open={!!openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
        <DialogTitle>
          {editingType === 'BULK' && bulkList.length === 0
            ? 'No tool selected to update'
            : 'Are you sure you want to make these changes?'}
        </DialogTitle>
        {editingType && (
          <DialogActions>
            <Button
              onClick={async () => {
                setOpenSaveDialog(false);
                (editingType === 'BULK' ? updateBulkTools() : updateTool()).then(() => {
                  setEditingType(undefined);
                  setSingleUpdatedTool(undefined);
                  setBulkUpdatedTool(undefined);
                });
              }}
            >
              Yes
            </Button>
            <Button color="secondary" onClick={() => setOpenSaveDialog(false)}>
              No
            </Button>
          </DialogActions>
        )}

        {editingType === 'BULK' && bulkList.length === 0 && (
          <DialogActions style={{ alignSelf: 'center' }}>
            <Button
              onClick={() => {
                setOpenSaveDialog(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {/* Duplicate Ibeacon values */}
      <Dialog open={!!duplicateTools.length} onClose={() => setDuplicateTools([])}>
        <DialogTitle>Please ensure beacon identifiers are unique.</DialogTitle>
        <DialogContent>
          <p>
            There are duplicate IDs occurring on {duplicateTools.length === 1 ? 'line' : 'lines'}:
          </p>
          <p>{getDuplicateLines(duplicateTools)}</p>
        </DialogContent>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setDuplicateTools([]);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Tools;
