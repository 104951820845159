import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { OVERLAY } from '../../Components/Map/MapOverlay';
import { DeviceLocation } from '../../Components/Map/Messages';
import { KNOWN_TOOLS } from '../../store/tools';
import { SECONDARY, truncationStyle } from '../../Style/GeoMobyBaseTheme';
import { OverlayPortal } from './LiveMapOlFunctions';

type LocalBeacons = DeviceLocation[];

export const useLocalBeacons = () => {
  const [localBeacons, setLocalBeacons] = useState<LocalBeacons | undefined>(undefined);

  const setLocalBeaconsWrapped = useCallback(
    (localBeacons: LocalBeacons | undefined, coordinates: number[] | undefined) => {
      if (localBeacons) {
        if (coordinates) OVERLAY.setPosition(coordinates);
      } else {
        //OVERLAY.setPosition(undefined);
      }

      setLocalBeacons(localBeacons);
    },
    [setLocalBeacons],
  );

  return [localBeacons, setLocalBeaconsWrapped] as const;
};

export const LocalBeaconsPopover = ({ localBeacons }: { localBeacons: LocalBeacons }) => {
  const knownTools = useAtomValue(KNOWN_TOOLS);
  const [openTools, setOpenTools] = useState<boolean>(false);
  const [openToolType, setOpenToolType] = useState<number | undefined>();
  const [openOther, setOpenOther] = useState<boolean>(false);
  const [toolTypes, setToolTypes] = useState<string[]>([]);
  const beaconGroups = localBeacons?.map((asset: DeviceLocation) => {
    const knownTool = knownTools.find(
      tool => tool.id?.toLowerCase() === asset.beaconId?.toLowerCase(),
    );
    return {
      ...asset,
      type: knownTool ? 'TOOL' : undefined,
      toolType: knownTool ? knownTool.tool : undefined,
    };
  });

  return (
    <OverlayPortal>
      <Paper elevation={12}>
        <Box p={2} overflow={'auto'} width={400} maxHeight={350}>
          <Typography
            style={{
              fontSize: 'x-large',
            }}
          >
            Nearby Assets ({beaconGroups.length})
          </Typography>

          {/* When we also have tools */}
          {beaconGroups.filter(asset => asset.type === 'TOOL').length > 0 && (
            <Grid container direction={'row'}>
              {beaconGroups.length > 0 &&
                [{ type: 'TOOL' }, { type: undefined }].map((assetType, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      style={{
                        marginTop: '5px',
                      }}
                    >
                      <IconButton
                        style={{
                          height: '27px',
                          width: '27px',
                        }}
                        onClick={() => {
                          if (assetType.type === 'TOOL') {
                            setOpenTools(!openTools);
                            setOpenOther(false);
                          } else {
                            setOpenTools(false);
                            setOpenOther(!openOther);
                          }
                        }}
                      >
                        {(assetType.type === 'TOOL' ? openTools : openOther) ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </IconButton>
                      <Typography
                        style={{
                          fontSize: 'large',
                        }}
                      >
                        {assetType.type === 'TOOL' ? 'Tools' : 'Other Assets'} (
                        {beaconGroups.filter(asset => asset.type === assetType.type).length})
                      </Typography>

                      {/*Tool Type*/}
                      {assetType.type === 'TOOL' &&
                        openTools &&
                        Array.from(
                          new Set(
                            beaconGroups
                              .filter(asset => asset.type === 'TOOL')
                              .map(tool => tool.toolType),
                          ),
                        ).map((toolType, toolTypeIndex) => {
                          return (
                            <Grid
                              key={toolTypeIndex}
                              container
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'max-content',
                                marginLeft: '10px',
                              }}
                            >
                              <Grid container>
                                <IconButton
                                  style={{
                                    height: '27px',
                                    width: '27px',
                                  }}
                                  onClick={() => {
                                    setOpenToolType(
                                      toolTypeIndex !== openToolType ? toolTypeIndex : undefined,
                                    );
                                  }}
                                >
                                  {openToolType === toolTypeIndex ? <ExpandMore /> : <ExpandLess />}
                                </IconButton>
                                <Typography
                                  style={{
                                    fontSize: 'large',
                                  }}
                                >
                                  {toolType} (
                                  {
                                    Array.from(
                                      new Set(
                                        beaconGroups.filter(tool => tool.toolType === toolType),
                                      ),
                                    ).length
                                  }
                                  )
                                </Typography>
                              </Grid>

                              {/* Tools */}
                              {openToolType === toolTypeIndex &&
                                assetType.type === 'TOOL' &&
                                openTools &&
                                beaconGroups
                                  .filter(
                                    asset => asset.type === 'TOOL' && asset.toolType === toolType,
                                  )
                                  .sort((a, b) =>
                                    (a.label ?? a.beaconId).localeCompare(b.label ?? b.beaconId),
                                  )
                                  .map((tool, toolIndex) => {
                                    return (
                                      <Grid
                                        key={toolIndex}
                                        container
                                        direction="row"
                                        style={{
                                          margin: '0px 0px 3px 10px',
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {tool.label && (
                                            <span style={truncationStyle}>{tool.label}</span>
                                          )}
                                          {!tool.label && (
                                            <span>
                                              <span
                                                style={{
                                                  color: SECONDARY,
                                                }}
                                              >
                                                NO LABEL
                                              </span>
                                              <span
                                                style={truncationStyle}
                                              >{` (${tool.beaconId})`}</span>
                                            </span>
                                          )}
                                        </Typography>
                                      </Grid>
                                    );
                                  })}
                            </Grid>
                          );
                        })}

                      {/* Other Assets */}
                      {assetType.type !== 'TOOL' &&
                        openOther &&
                        beaconGroups
                          .filter(asset => asset.type === undefined)
                          .sort((a, b) =>
                            (a.label ?? a.beaconId).localeCompare(b.label ?? b.beaconId),
                          )
                          .map((asset, i) => (
                            <Grid key={i} container>
                              <Typography
                                style={{
                                  fontSize: 'medium',
                                  margin: '0px 0px 3px 20px',
                                }}
                                key={`${i}_${asset.label}`}
                              >
                                {asset.label}
                              </Typography>
                            </Grid>
                          ))}
                    </Grid>
                  );
                })}
            </Grid>
          )}

          {/* When we have no tools */}
          {beaconGroups.filter(asset => asset.type === 'TOOL').length === 0 && (
            <Grid container direction={'row'}>
              {beaconGroups
                .sort((a, b) => (a.label ?? a.beaconId).localeCompare(b.label ?? b.beaconId))
                .map((asset, i) => (
                  <Grid
                    key={i}
                    container
                    direction="row"
                    style={{
                      margin: '0px 0px 3px 10px',
                    }}
                  >
                    <Typography key={`${i}_${asset.label}`}>{asset.label}</Typography>
                  </Grid>
                ))}
            </Grid>
          )}
        </Box>
      </Paper>
    </OverlayPortal>
  );
};
