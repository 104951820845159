import { GridRowData } from '@material-ui/data-grid';
import { FenceGeometryType } from '../../util/enums';

export const geometryTypeOfEntity = (entity: GridRowData): FenceGeometryType | undefined => {
  if (
    entity?.type?.toLowerCase() === FenceGeometryType.Multipolygon ||
    entity?.points?.type?.toLowerCase() === FenceGeometryType.Multipolygon
  ) {
    return FenceGeometryType.Multipolygon;
  }
  if (
    entity?.type?.toLowerCase()?.includes(FenceGeometryType.Polygon) ||
    entity?.points?.type?.toLowerCase()?.includes(FenceGeometryType.Polygon)
  ) {
    return FenceGeometryType.Polygon;
  }
  if (
    entity?.type?.toLowerCase()?.includes(FenceGeometryType.Line) ||
    entity?.points?.type?.toLowerCase()?.includes(FenceGeometryType.Line)
  ) {
    return FenceGeometryType.Line;
  }
  if (entity?.microfenceType || entity?.properties?.microfenceType) {
    return FenceGeometryType.Microfence;
  }
  return;
};
